/* eslint-disable react/button-has-type */
import React from 'react'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import Loading from '../Loading'
import './styles.scss'

const Button = (props) => (
  <div
    type={props.type}
    onClick={() => {
      if (props?.disabled) return
      if (props?.onClick) {
        props?.onClick()
        EventEmitter.emit(Events.BTN_CLICK, {
          nativeId: props.nativeId
        })
      }
    }}
    disabled={props.disabled || props.isLoading}
    className={`${props.actionButton} ${props.shadow && 'shadow'} flex flex-1 items-center justify-center px-[5px] text-center cursor-pointer`}
    style={{
      backgroundColor: `${props.bgColor}`,
      width: `${props.btnWidth}`,
      minHeight: `${props.btnHeight}`,
      color: `${props.btnColor}`,
      border: `${props.btnBorder}`,
      fontSize: `${props.fontSize}`,
      borderRadius: `${props.borderRadius}`,
      boxShadow: `${props.boxShadow}`,
      opacity: `${props.opacity}`
    }}
    id={`${props.idLabel}_button`}
  >
    {props.isLoading ? <center><Loading white small /></center> : props.children ? props.children : props.label}
  </div>
)

export default Button
