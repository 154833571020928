import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Slider from 'react-slick'
import './Slide.scss'
import { useEffect, useRef } from 'react'

const videoRegex = /^https?:\/\/(.+\/)+.+(\.(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm))$/i
export default function Slide ({ imageList, dotsWrapperClass, dotClass }) {
  const videoRef = useRef()
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div>
        <ul className={dotsWrapperClass || 'dots-wrapper'}> {dots} </ul>
      </div>
    ),
    customPaging: index => (
      <div
        className={dotClass || 'custom-dot'}
      />
    )
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, [videoRef])

  return (
    <div className='slide'>
      <Slider {...settings}>
        {imageList?.map((img) => (
          <div key={img.url} className='single-slide'>
            <div className='img-wrapper'>
              {videoRegex.test(img?.url)
                ? (
                  <video playsInline loop ref={videoRef} autoPlay muted>
                    <source src={img?.url} type='video/mp4' />
                  </video>
                  )
                : (
                  <img src={img.url} alt='images' />
                  )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
