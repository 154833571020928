import './Home.scss'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProductDetail } from './saga/saga'
import { useLocation, useParams } from 'react-router-dom'
import ErrorBoundary from '../../../components/ErrorPage/errorBoundary'
import Loading from '../../../components/Loading'
import '../../Checkout/style.scss'
import Details from '../../products/Details'
import { useFetchProduct } from '../../../utilities/navigation'

const Home = () => {
  const { isLoading, product, isError } = useSelector(state => state.product)
  const { shareId, sku } = useParams()
  const location = useLocation()
  const isSubOrder = location.pathname.includes('sub-order')
  const { product: pdpProduct, user: pdpUser, dsf: pdpDsf } = useFetchProduct()

  useEffect(() => {
    if (isSubOrder) return
    getProductDetail({ shareId, sku })
  }, [shareId, sku])

  return (
    <>
      <div className='home'>
        <div className='home-body'>
          {
            isError
              ? <ErrorBoundary />
              : isSubOrder
                ? <Details user={pdpUser} product={{ ...pdpProduct, ...pdpDsf } || {}} dsf={pdpDsf} isLoading={false} />
                : (!isLoading && product.product)
                    ? <Details user={product?.user} product={{ ...product?.product, ...product.dsf } || {}} dsf={product?.dsf} isLoading={isLoading} />
                    : (
                      <div style={{ height: window.innerHeight }} className='flex items-center justify-center'>
                        <Loading />
                      </div>
                      )
          }
        </div>
      </div>
    </>
  )
}
export default Home
