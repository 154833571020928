import {
  Box,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core'
import './styles.scss'
import { ChatWithSeller } from '../ChatWithSeller/ChatWithSeller'
import paymentFailedIcon from '../../assets/paymentFailedIcon.svg'
import whatsapp from '../../containers/DSFPDP/home/components/topbar/assets/whats.svg'
import {
  commonTranslations
} from '../../translation/commonTranslations'
import { useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import '../../containers/Checkout/style.scss'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

const PaymentFailed = ({ handleClose, onTryAgain }) => {
  const timeRef = useRef()
  const [countDown, setCountDown] = useState(moment().hour(0).minute(0).second(10).format('s'))
  const user = useSelector((state) => state.product.product.user)
  const theme = createMuiTheme({
    typography: {
      subtitle2: {
        color: '#8559FF',
        fontWeight: 600,
        fontSize: '12px'
      }
    }
  })

  const startTimer = () => {
    let seconds = 10
    timeRef.current = setInterval(() => {
      if (seconds === 0) {
        onTryAgain()
        stopTimer()
        return
      }
      setCountDown(moment().hours(0).minutes(0).seconds(seconds).subtract(1, 'second').format('s'))
      seconds -= 1
    }, 1000)
  }

  const stopTimer = () => clearInterval(timeRef.current)

  useEffect(() => {
    startTimer()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box className='form-wrapper order-place bg-white'>
        <div className='w-full pt-4'>
          <Close
            onClick={handleClose}
            fontSize='large'
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className='flex flex-col flex-1 items-center justify-center'>
          <Box>
            <img src={paymentFailedIcon} alt='' />
          </Box>
          <p className='text-[16px] font-[500] font-sans mt-4'>
            {commonTranslations.yourPaymentFailed}
          </p>
          <p className='text-[13px] font-[500] font-sans mt-2'>
            {commonTranslations.tryAgainToCompleteOrder}
          </p>

          <div onClick={onTryAgain} className='h-[40px] w-[150px] bg-[#162E9A] rounded flex items-center justify-center mt-[30px] cursor-pointer'>
            <p className='text-white text-[14px]'>{commonTranslations.tryAgain}</p>
          </div>
          <p className='text-[13px] font-[500] font-sans mt-[10px]'>
            {commonTranslations.youWillBeRedirectedIn} {countDown}
          </p>

          <p className='text-[13px] font-[500] font-sans mt-[30px]'>
            {commonTranslations.needHelpWithOrder}
          </p>
          <Box className='chat-with-seller-wrapper mt-[20px]'>
            <ChatWithSeller
              icon={whatsapp}
              title={commonTranslations.chatWithSeller}
              user={user}
              pageName='Failed Page'
            />
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default PaymentFailed
