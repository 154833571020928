import './SocialShare.scss'
import shareIcon from './assets/share.svg'
import { NOTIFIER_SHOW } from '../../../../../actions'
import store from '../../../../../store'
import { commonTranslations } from '../../../../../translation/commonTranslations'

export default function SocialShare (second) {
  const onCopyOrderId = () => {
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: commonTranslations.copyToClipboard, type: 'success' } })
    navigator.clipboard.writeText(window.location.href)
  }
  return (
    <div onClick={onCopyOrderId} className='social-share'>
      <div className='img-wrapper'>
        <img src={shareIcon} alt='icons' />
      </div>
    </div>
  )
}
