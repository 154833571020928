import { MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import fetchTranslation from '../../translation/sagas'
import UK from '../../assets/uk.png'
import indonesia from '../../assets/indonesia.png'

export default function LanguageBar () {
  const [selectedLanguage, setSelectedLanguage] = useState(window.localStorage.getItem('locale') ? window.localStorage.getItem('locale').toUpperCase() : 'ID')
  const selectLanguage = (val) => {
    fetchTranslation(val.toLowerCase())
    setSelectedLanguage(val)
  }
  return (
    <Select
      variant='outlined'
      value={selectedLanguage}
      onChange={(e) => selectLanguage(e.target.value)}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label', style: { display: 'flex', backgroundColor: 'white' } }}
      SelectDisplayProps={{ style: { display: 'flex', backgroundColor: 'rgba(0,0,0,0)', paddingLeft: 10 } }}
      style={{ height: 35, backgroundColor: 'white' }}
      MenuProps={{ style: { marginTop: 30 } }}
    >
      <MenuItem className='flex items-center' value='ID'><img className='mr-2 w-[24px] h-[24px]' src={indonesia} alt='ID' /> <p className='text-[12px]'>Bahasa</p></MenuItem>
      <MenuItem className='flex items-center' value='EN'><img className='mr-2 w-[24px] h-[24px]' src={UK} alt='UK' /> <p className='text-[12px]'>English</p></MenuItem>
    </Select>
  )
}
