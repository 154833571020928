import produce from 'immer'
import { FETCH_POSTAL_FAIL, FETCH_POSTAL_INIT, FETCH_POSTAL_SUCCESS, FETCH_SELLER_FAIL, FETCH_SELLER_INIT, FETCH_SELLER_LINKS_FAIL, FETCH_SELLER_LINKS_INIT, FETCH_SELLER_LINKS_SUCCESS, FETCH_SELLER_SUCCESS, FETCH_SHARED_PRODUCTS_FAIL, FETCH_SHARED_PRODUCTS_INIT, FETCH_SHARED_PRODUCTS_SUCCESS } from '../../../actions'

const sellerLinksInit = {
  isLoading: false,
  sellerLinks: [],
  isError: false
}

export const sellerLinks = produce((state = sellerLinksInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SELLER_LINKS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SELLER_LINKS_SUCCESS:
      state.sellerLinks = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SELLER_LINKS_FAIL:
      state.sellerLinks = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, sellerLinksInit)

const sellerInit = {
  isLoading: false,
  seller: {},
  isError: false
}

export const seller = produce((state = sellerInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SELLER_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SELLER_SUCCESS:
      state.seller = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SELLER_FAIL:
      state.seller = {}
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, sellerInit)

const sharedProductsInit = {
  isLoading: false,
  products: [],
  isError: false
}

export const sharedProducts = produce((state = sharedProductsInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SHARED_PRODUCTS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SHARED_PRODUCTS_SUCCESS:
      state.products = data.data.products
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SHARED_PRODUCTS_FAIL:
      state.products = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, sharedProductsInit)

const postalInit = {
  isLoading: false,
  postal: [],
  isError: false
}

export const postal = produce((state = postalInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_POSTAL_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_POSTAL_SUCCESS:
      state.postal = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_POSTAL_FAIL:
      state.products = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, postalInit)
