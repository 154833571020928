import './style.scss'
import { Icon } from './components/Icon/Icon'
import backLayer from '../../assets/backLayer.png'
import whats from './assets/whatsapp.svg'
import mail from './assets/mail.svg'
import phone from './assets/phone.svg'
import insta from './assets/insta.png'
import snapchat from './assets/snapchat.png'
import telegram from './assets/telegram.png'
import youtube from './assets/youtube.png'
import fb from './assets/fb.svg'
import music from './assets/music.svg'
// import perfume from './assets/perfume.svg'
// import flash from './assets/flash.svg'
import lifestyle from './assets/lifestyle.svg'
import bel from './assets/bell.svg'
import laz from './assets/laz.svg'
import blibli from './assets/blibli.svg'
import bukalapak from './assets/bukalapak.svg'
import applestore from './assets/applestore.svg'
import playstore from './assets/playstore.svg'
// import MyMap from '../../components/google-map/MyMap'
import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import TopbarStoreView from '../DSFPDP/home/components/topbar/TopBarStoreView'
import { getSellerDetail, getSharedProducts } from '../share-details/saga/saga'
import DisplaySection from '../../components/common/DisplaySection'
import VideoDisplay from './components/VideoDisplay/VideoDisplay'
import BlogDisplay from './components/BlogDisplay/BlogDisplay'
import LanguageBar from '../../components/LanguageBar/LanguageBar'
import { TopSellCard } from '../../components/cards/top-sell-card/TopSellCard'
import { StoreCard } from '../../components/cards/store-card/StoreCard'
import { getSellerLinks, registerClick, registerClickStoreProfiles } from './saga/saga'
import { commonTranslations } from '../../translation/commonTranslations'
import { FeaturedCard } from '../../components/cards/featured-card/FeaturedCard'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { clearFields } from '../OrderDetail/saga/saga'

const storeImages = {
  shopee: bel,
  tokopedia: lifestyle,
  lazada: laz,
  blibli: blibli,
  bukallapak: bukalapak
}

const appImages = {
  playstore: playstore,
  appstore: applestore
}

const StoreHome = () => {
  const history = useHistory()
  const sharedProducts = useSelector((state) => state.sharedProducts)
  const { seller } = useSelector((state) => state)
  const { shareId } = useParams()
  const { sellerLinks } = useSelector((state) => state.sellerLinks)
  const [topSellProducts, updateTopSellProducts] = useState([])

  useEffect(() => {
    getSellerDetail(shareId)
  }, [])

  useEffect(() => {
    if (seller.seller.resellerId) {
      getSellerLinks(seller.seller.resellerId)
    }
    if (seller.seller) {
      EventEmitter.emit(Events.LOAD_STORE_VIEW, {
        seller_id: seller.seller.resellerId
      })
    }
  }, [seller])

  useEffect(() => {
    getSharedProducts({ shareId: shareId, page: 1, limit: 10 })
  }, [shareId])

  useEffect(() => {
    if (sharedProducts.products.length > 0) {
      const products = sharedProducts.products.filter(Boolean)
      const temp = products?.map((it) => { return { ...it.product, ...it.dsf } })
      updateTopSellProducts(temp)
    }
  }, [sharedProducts])

  const onCall = () => {
    window.open(`sms://${seller.seller?.dsfMobile}/`, '_blank')
    if (seller.seller) {
      EventEmitter.emit(Events.STORE_PHONE, {
        seller_id: seller.seller.resellerId
      })
    }
    registerClickStoreProfiles({ resellerId: seller.seller.resellerId, fieldName: 'dsfMobile' })
  }

  const onEmail = () => {
    window.open(`mailto:${seller.seller?.dsfEmail}`, '_blank')
    if (seller.seller) {
      EventEmitter.emit(Events.STORE_EMAIL, {
        seller_id: seller.seller.resellerId
      })
    }
    registerClickStoreProfiles({ resellerId: seller.seller.resellerId, fieldName: 'dsfEmail' })
  }

  const onWhatsapp = () => {
    window.open(`https://wa.me/${seller.seller?.waMobile}`, '_blank')
    if (seller.seller) {
      EventEmitter.emit(Events.STORE_WHATSAPP, {
        seller_id: seller.seller.resellerId
      })
    }
    registerClickStoreProfiles({ resellerId: seller.seller.resellerId, fieldName: 'waMobile' })
  }

  const onClickProducts = (skuId) => {
    history.push(`/${seller.seller?.shareId}/${skuId}`)
    clearFields()
    EventEmitter.emit(Events.VIEW_ITEM)
    if (skuId) {
      registerClickStoreProfiles({ resellerId: seller.seller.resellerId, productSku: skuId })
    }
  }

  const onPressLink = (link, id, type, event) => {
    window.open(link?.startsWith('http') ? link : `https://${link}`, '_blank')
    if (type === 'article' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_ARTICLE, {
        url: link,
        seller_id: seller.seller.resellerId
      })
      if (id) {
        registerClick({ id })
      }
    }
    if (type === 'video' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_VIDEO, {
        url: link,
        seller_id: seller.seller.resellerId
      })
      if (id) {
        registerClick({ id })
      }
    }
    if (type === 'custom_product' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_CUSTOM_PRODUCT, {
        url: link,
        seller_id: seller.seller.resellerId
      })
      if (id) {
        registerClick({ id })
      }
    }
    if (type === 'customLink' && seller.seller && link) {
      EventEmitter.emit(Events.CUSTOM_LINKS, {
        url: link,
        seller_id: seller.seller.resellerId
      })
      if (id) {
        registerClick({ id })
      }
    }
    if (type === 'storeLink' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_PROFILES, {
        url: link,
        seller_id: seller.seller.resellerId,
        event
      })
      if (id) {
        registerClickStoreProfiles({ resellerId: seller.seller.resellerId, storeProfileId: id })
      }
    }
    if (type === 'socialLink' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_PROFILES, {
        url: link,
        seller_id: seller.seller.resellerId,
        event
      })
      if (id) {
        registerClickStoreProfiles({ resellerId: seller.seller.resellerId, storeProfileId: id })
      }
    }
    if (type === 'androidLink' && seller.seller && link) {
      EventEmitter.emit(Events.STORE_PROFILES, {
        url: link,
        seller_id: seller.seller.resellerId,
        event
      })
      if (id) {
        registerClickStoreProfiles({ resellerId: seller.seller.resellerId, storeProfileId: id })
      }
    }
  }

  const handleClickOnViewALL = () => {
    history.push(`/${shareId}/all-products/top-sell`)
    EventEmitter.emit(Events.VIEW_ALL, {
      seller_id: seller.seller?.resellerId || ''
    })
  }

  if (seller.isError) {
    return null
  }

  const storeName = seller.seller?.store || seller.seller?.name
  const instagramObj = seller.seller?.storeProfiles?.find(item => item.type === 'instagram') || ''
  const facebookObj = seller.seller?.storeProfiles?.find(item => item.type === 'facebook') || ''
  const tiktokObj = seller.seller?.storeProfiles?.find(item => item.type === 'tiktok') || ''
  const snapchatObj = seller.seller?.storeProfiles?.find(item => item.type === 'snapchat') || ''
  const youtubeObj = seller.seller?.storeProfiles?.find(item => item.type === 'youtube') || ''
  const telegramObj = seller.seller?.storeProfiles?.find(item => item.type === 'telegram') || ''

  return (
    <div className='store-home pb-0 store-home-wrapper'>
      <div>
        <Helmet>
          <title>{storeName}</title>
        </Helmet>
        <TopbarStoreView title=''>
          <LanguageBar />
        </TopbarStoreView>
      </div>
      <div className='store-head'>
        <img className='back-layer' src={backLayer} alt='' />
        <div className='mb-10 logo-box'>
          <center>
            {seller.seller?.imageUrl
              ? (
                <div className='logo-wrapper'><img src={seller.seller?.imageUrl} alt='logo' className='user-logo' /></div>
                )
              : (
                <div className='logo-wrapper flex items-center justify-center'>
                  <div className='w-[100px] h-[100px] rounded-full bg-white flex items-center justify-center'>
                    <p className='text-[50px] font-sans font-[600] text-gray-400 m-0 text-uppercase'>
                      {storeName?.split(' ').length > 1 ? `${storeName?.split(' ')[0]?.slice(0, 1)} ${storeName?.split(' ')[1]?.slice(0, 1)}` : storeName?.split('')[0]}
                    </p>
                  </div>
                </div>
                )}
          </center>
          <h2 className='mx-2 text-center text-[#ffffff]'>{storeName}</h2>
          <h6 className='mx-2 mt-6 text-center text-[#ffffff]/75'>{seller.seller?.storeDescription}</h6>
          <div className='social-wrapper'>
            {seller.seller?.dsfMobile &&
              <Icon onClick={onCall} style={{ cursor: 'pointer' }}>
                <img src={phone} alt='icon' />
              </Icon>}
            {seller.seller?.dsfEmail &&
              <Icon onClick={onEmail} style={{ marginTop: 10, cursor: 'pointer' }}>
                <img src={mail} alt='icon' />
              </Icon>}
            {seller.seller?.waMobile &&
              <Icon onClick={onWhatsapp} style={{ marginTop: 10, cursor: 'pointer' }}>
                <img src={whats} alt='icon' />
              </Icon>}
          </div>
          <div className='social-wrapper-2'>
            {instagramObj && instagramObj.url &&
              <img
                onClick={() => onPressLink(instagramObj.url, instagramObj.id, 'socialLink', Events.SOCIAL_ICONS_INSTAGRAM)}
                style={{
                  width: 30,
                  height: 30,
                  cursor: 'pointer'
                }} src={insta} alt='icon'
              />}
            {facebookObj && facebookObj.url &&
              <Icon
                onClick={() => onPressLink(facebookObj.url, facebookObj.id, 'socialLink', Events.SOCIAL_ICONS_FACEBOOK)} style={{
                  background: '#4565DC',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1509)',
                  cursor: 'pointer'
                }}
              >
                <img src={fb} alt='icon' />
              </Icon>}
            {tiktokObj && tiktokObj.url &&
              <Icon
                onClick={() => onPressLink(tiktokObj.url, tiktokObj.id, 'socialLink', Events.SOCIAL_ICONS_TIKTOK)} style={{
                  background: '#010101',
                  boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.1509)',
                  cursor: 'pointer'
                }}
              >
                <img src={music} alt='icon' />
              </Icon>}
            {snapchatObj && snapchatObj.url &&
              <img
                onClick={() => onPressLink(snapchatObj.url, snapchatObj.id, 'socialLink', Events.SOCIAL_ICONS_SNAPCHAT)}
                style={{
                  width: 30,
                  height: 30,
                  cursor: 'pointer'
                }} src={snapchat} alt='icon'
              />}
            {youtubeObj && youtubeObj.url &&
              <img
                onClick={() => onPressLink(youtubeObj.url, youtubeObj.id, 'socialLink', Events.SOCIAL_ICONS_YOUTUBE)}
                style={{
                  width: 30,
                  height: 30,
                  cursor: 'pointer'
                }} src={youtube} alt='icon'
              />}
            {telegramObj && telegramObj.url &&
              <img
                onClick={() => onPressLink(telegramObj.url, telegramObj.id, 'socialLink', Events.SOCIAL_ICONS_TELEGRAM)}
                style={{
                  width: 30,
                  height: 30,
                  cursor: 'pointer'
                }} src={telegram} alt='icon'
              />}
          </div>
        </div>
      </div>
      <div className='store-body px-4'>
        {topSellProducts.length > 0 && <DisplaySection horizontal CartView={TopSellCard} itemList={topSellProducts} headerName={commonTranslations.topSellingProdcuts} onClickProducts={onClickProducts} onViewAllClick={handleClickOnViewALL} />}
        {
          sellerLinks?.length > 0 && sellerLinks?.map((item, i) => {
            if (item.sectionType === 'CUSTOM_LINK') {
              return (
                <div key={item.id} onClick={() => onPressLink(item.url, item?.id, 'customLink')} className='offer-wrapper'>
                  {item.imageUrl && (
                    <div className='img-wrapper'>
                      <img src={item.imageUrl} alt='linkIcon' />
                    </div>
                  )}
                  <div className='w-100 h-100 flex items-center justify-center'>
                    <h4 className=' text-[#162E9A] max-h-full overflow-hidden'>{item.title}</h4>
                  </div>
                </div>
              )
            }

            if (item.sectionType === 'CUSTOM_PRODUCT' && item?.links?.length > 0) {
              return <DisplaySection key={i} horizontal CartView={FeaturedCard} itemList={item.links} headerName={commonTranslations.featuredProducts} onClickProducts={(link, id) => onPressLink(link, id, 'custom_product')} />
            }

            if (item.sectionType === 'VIDEO' && item?.links?.length > 0) {
              return (
                <div key={i} className='mt-10'>
                  <h3 className='text-white'>{commonTranslations.videos}</h3>
                  <VideoDisplay onClick={(link, id) => onPressLink(link, id, 'video')} videos={item.links} />
                </div>
              )
            }

            if (item.sectionType === 'ARTICLE' && item?.links?.length > 0) {
              return (
                <div key={i} className='mt-10'>
                  <h3 className='text-white'>Blogs</h3>
                  <BlogDisplay onClick={(val, linkId) => onPressLink(val, linkId, 'article')} blogs={item.links} />
                </div>
              )
            }
            return null
          })
        }

        {
          seller.seller?.storeProfiles?.filter(item => item.meta === 'Store')?.filter(i => i.url).length > 0 &&
            <section className='mt-10'>
              <div className='pb-3 flex justify-between text-[#FFFFFF]'>
                <h3>{commonTranslations.visitOurStoresOnline}</h3>
              </div>
              <div className=''>
                <Box className='display-wrapper-store mt-1 gap-[15px]'>
                  {
                    seller.seller?.storeProfiles?.filter(item => item.meta === 'Store')?.filter(i => i.url).map(item => (
                      <StoreCard key={item.id || item} onClick={() => onPressLink(item?.url, item.id, 'storeLink', Events[`STORE_${item.type?.toUpperCase()}`])} className='store-wrapper h-[60px] mt-[-5px]'>
                        <img className='wrapped-img' src={storeImages[item?.type] || bel} alt='icon' />
                        <p className='capitalize text-[14px] m-0 ml-[10px]'>{item?.type}</p>
                      </StoreCard>
                    ))
                  }
                </Box>
              </div>
            </section>
        }

        {/* <section className='mb-10'>
          <h4 className='pb-3 flex justify-between text-[#FFFFFF]'>Store Location</h4>
          <MyMap location={{ lat: 23.811056, lng: 90.407608 }} width='100%' height='400px' />
        </section> */}

        {
          seller?.seller?.storeProfiles?.filter(item => item.meta === 'App')?.filter(i => i.url).length > 0 &&
            <section className='mb-0 w-full flex flex-col mt-[3rem]'>
              <h3 className='text-white'>{commonTranslations.downloadTheApp}</h3>
              <div className='flex items-center mt-3 mb-[30px] w-full gap-[10px] items-center'>
                {seller.seller?.storeProfiles?.filter(item => item.meta === 'App')?.filter(i => i.url).reverse().map(item => (
                  <img key={item.url} onClick={() => onPressLink(item?.url, item?.id, 'androidLink', Events[`STORE_${item.type?.toUpperCase()}`])} className='cursor-pointer' src={appImages[item.type]} alt='icon' />
                ))}
              </div>
            </section>
        }
      </div>
    </div>
  )
}

export default StoreHome
