import { logEvent } from 'firebase/analytics'
import EventEmitter, { Events } from './eventEmitter'
import { analytics } from './firebase'

function onClickCustomProduct ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_CUSTOM_PRODUCT, { ...data })
    window.clevertap.event.push(Events.STORE_CUSTOM_PRODUCT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickArticle ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_ARTICLE, { ...data })
    window.clevertap.event.push(Events.STORE_ARTICLE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickVideo ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_VIDEO, { ...data })
    window.clevertap.event.push(Events.STORE_VIDEO, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickStoreProfiles ({ ...data }) {
  try {
    logEvent(analytics, data.event, { ...data })
    window.clevertap.event.push(data.event, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickCustomLinks ({ ...data }) {
  try {
    logEvent(analytics, Events.CUSTOM_LINKS, { ...data })
    window.clevertap.event.push(Events.CUSTOM_LINKS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onLoadStoreView ({ ...data }) {
  try {
    logEvent(analytics, Events.LOAD_STORE_VIEW, { ...data })
    window.clevertap.event.push(Events.LOAD_STORE_VIEW, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickContinueCart () {
  try {
    logEvent(analytics, Events.CONTINUE_CART)
    window.clevertap.event.push(Events.CONTINUE_CART)
  } catch (err) {
    console.log(err)
  }
}

function onClickViewItem () {
  try {
    logEvent(analytics, Events.VIEW_ALL)
    window.clevertap.event.push(Events.VIEW_ALL)
  } catch (err) {
    console.log(err)
  }
}

function onClickViewAll ({ ...data }) {
  try {
    logEvent(analytics, Events.VIEW_ALL, { ...data })
    window.clevertap.event.push(Events.VIEW_ALL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickSearch ({ ...data }) {
  try {
    logEvent(analytics, Events.CLICK_SEARCH, { ...data })
    window.clevertap.event.push(Events.CLICK_SEARCH, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSearch ({ ...data }) {
  try {
    logEvent(analytics, Events.ON_SEARCHING, { ...data })
    window.clevertap.event.push(Events.ON_SEARCHING, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSearchFilter ({ ...data }) {
  try {
    logEvent(analytics, Events.ON_SEARCH_FILTER, { ...data })
    window.clevertap.event.push(Events.ON_SEARCH_FILTER, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onFilter ({ ...data }) {
  try {
    logEvent(analytics, Events.ON_FILTER, { ...data })
    window.clevertap.event.push(Events.ON_FILTER, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onViewCart ({ ...data }) {
  try {
    logEvent(analytics, Events.VIEW_CART, { ...data })
    window.clevertap.event.push(Events.VIEW_CART, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onLoadCheckoutShipment ({ ...data }) {
  try {
    logEvent(analytics, Events.LOAD_CHECKOUT_SHIPMENT, { ...data })
    window.clevertap.event.push(Events.LOAD_CHECKOUT_SHIPMENT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onLoadOutOfStock ({ ...data }) {
  try {
    logEvent(analytics, Events.OUT_OF_STOCK, { ...data })
    window.clevertap.event.push(Events.OUT_OF_STOCK, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onLoadPersonalDetails ({ ...data }) {
  try {
    logEvent(analytics, Events.LOAD_PERSONAL_DETAILS, { ...data })
    window.clevertap.event.push(Events.LOAD_PERSONAL_DETAILS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSubmitPersonalDetails () {
  try {
    logEvent(analytics, Events.SUBMIT_PERSONAL_DETAILS)
    window.clevertap.event.push(Events.SUBMIT_PERSONAL_DETAILS)
  } catch (err) {
    console.log(err)
  }
}

function onLoadAddress () {
  try {
    logEvent(analytics, Events.LOAD_ADDRESS)
    window.clevertap.event.push(Events.LOAD_ADDRESS)
  } catch (err) {
    console.log(err)
  }
}

function onLoadCart () {
  try {
    logEvent(analytics, Events.LOAD_CART)
    window.clevertap.event.push(Events.LOAD_CART)
  } catch (err) {
    console.log(err)
  }
}

function onSelectProvince ({ ...data }) {
  try {
    logEvent(analytics, Events.SELECT_PROVINCE, { ...data })
    window.clevertap.event.push(Events.SELECT_PROVINCE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSelectCity ({ ...data }) {
  try {
    logEvent(analytics, Events.SELECT_CITY, { ...data })
    window.clevertap.event.push(Events.SELECT_CITY, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSelectSubDistrict ({ ...data }) {
  try {
    logEvent(analytics, Events.SELECT_SUB_DISTRICT, { ...data })
    window.clevertap.event.push(Events.SELECT_SUB_DISTRICT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSelectDistrict ({ ...data }) {
  try {
    logEvent(analytics, Events.SELECT_DISTRICT, { ...data })
    window.clevertap.event.push(Events.SELECT_DISTRICT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onLoadFinishCheckout () {
  try {
    logEvent(analytics, Events.LOAD_FINISH_CHECKOUT)
    window.clevertap.event.push(Events.LOAD_FINISH_CHECKOUT)
  } catch (err) {
    console.log(err)
  }
}

function onFinishCheckout () {
  try {
    logEvent(analytics, Events.FINISH_CHECKOUT)
    window.clevertap.event.push(Events.FINISH_CHECKOUT)
  } catch (err) {
    console.log(err)
  }
}

function onLoadPayment () {
  try {
    logEvent(analytics, Events.LOAD_PAYMENT)
    window.clevertap.event.push(Events.LOAD_PAYMENT)
  } catch (err) {
    console.log(err)
  }
}

function onPaymentPage ({ ...data }) {
  try {
    logEvent(analytics, Events.PAYMENT_PAGE, { ...data })
    window.clevertap.event.push(Events.PAYMENT_PAGE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onAddToCart ({ ...data }) {
  try {
    logEvent(analytics, Events.ADD_TO_CART, { ...data })
    window.clevertap.event.push(Events.ADD_TO_CART, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSubmitAddress ({ ...data }) {
  try {
    logEvent(analytics, Events.SUBMIT_ADDRESS, { ...data })
    window.clevertap.event.push(Events.SUBMIT_ADDRESS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onChatWithSeller ({ ...data }) {
  try {
    logEvent(analytics, Events.CHAT_WITH_SELLER, { ...data })
    window.clevertap.event.push(Events.CHAT_WITH_SELLER, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onUserPay ({ type, ...data }) {
  try {
    logEvent(analytics, Events[type], { ...data })
    window.clevertap.event.push(Events[type], { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onIncreaseQuantity ({ ...data }) {
  try {
    logEvent(analytics, Events.INCREASE_QUANTITY, { ...data })
    window.clevertap.event.push(Events.INCREASE_QUANTITY, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onFetchProduct ({ ...data }) {
  try {
    logEvent(analytics, Events.PRODUCT_DETAILS, { ...data })
    window.clevertap.event.push(Events.PRODUCT_DETAILS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onCheckoutUserDetails ({ ...data }) {
  try {
    logEvent(analytics, Events.USER_CHECKOUT_DETAILS, { ...data })
    window.clevertap.event.push(Events.USER_CHECKOUT_DETAILS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSubOrderGenerate ({ ...data }) {
  try {
    logEvent(analytics, Events.SUB_ORDERS, { ...data })
    window.clevertap.event.push(Events.SUB_ORDERS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onSelectPaymentMethod ({ ...data }) {
  try {
    logEvent(analytics, Events.SELECTED_PAYMENT_METHOD, { ...data })
    window.clevertap.event.push(Events.SELECTED_PAYMENT_METHOD, { ...data })
  } catch (err) {
    console.log(err)
  }
}

// function onButtonAction ({ nativeId }) {
//   try {
//     logEvent(analytics, Events[nativeId])
//     window.clevertap.event.push(Events[nativeId])
//   } catch (err) {
//     console.log(err)
//   }
// }

function onPaymentSuccess ({ ...data }) {
  try {
    logEvent(analytics, Events.USER_PAY_SUCCESSFULL, { ...data })
    window.clevertap.event.push(Events.USER_PAY_SUCCESSFULL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onPaymentFailed ({ data }) {
  try {
    logEvent(analytics, Events.USER_PAY_FAIL, { ...data })
    window.clevertap.event.push(Events.USER_PAY_FAIL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onBuyNowClick ({ ...data }) {
  try {
    logEvent(analytics, Events.BUY_NOW, { ...data })
    window.clevertap.event.push(Events.BUY_NOW, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickStorePhone ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_PHONE, { ...data })
    window.clevertap.event.push(Events.STORE_PHONE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickStoreEmail ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_EMAIL, { ...data })
    window.clevertap.event.push(Events.STORE_EMAIL, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickStoreWhatsapp ({ ...data }) {
  try {
    logEvent(analytics, Events.STORE_WHATSAPP, { ...data })
    window.clevertap.event.push(Events.STORE_WHATSAPP, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickRedirectStore ({ ...data }) {
  try {
    logEvent(analytics, Events.REDIRECT_TO_STORE, { ...data })
    window.clevertap.event.push(Events.REDIRECT_TO_STORE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickMoreFromStore ({ ...data }) {
  try {
    logEvent(analytics, Events.MORE_FROM_SELLER, { ...data })
    window.clevertap.event.push(Events.MORE_FROM_SELLER, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onAddressPageLoads () {
  try {
    logEvent(analytics, Events.ADDRESS_PAGE_LOADS)
    window.clevertap.event.push(Events.ADDRESS_PAGE_LOADS)
  } catch (err) {
    console.log(err)
  }
}

function onDownloadDetails ({ ...data }) {
  try {
    logEvent(analytics, Events.DOWNLOAD_DETAILS, { ...data })
    window.clevertap.event.push(Events.DOWNLOAD_DETAILS, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onCopyVa ({ ...data }) {
  try {
    logEvent(analytics, Events.COPY_VA, { ...data })
    window.clevertap.event.push(Events.COPY_VA, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onCopyAmount ({ ...data }) {
  try {
    logEvent(analytics, Events.COPY_AMOUNT, { ...data })
    window.clevertap.event.push(Events.COPY_AMOUNT, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClickPaymentGuide ({ ...data }) {
  try {
    logEvent(analytics, Events.PAYMENT_GUIDE, { ...data })
    window.clevertap.event.push(Events.PAYMENT_GUIDE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

function onClosePaymentGuide ({ ...data }) {
  try {
    logEvent(analytics, Events.CLOSE_PAYMENT_GUIDE, { ...data })
    window.clevertap.event.push(Events.CLOSE_PAYMENT_GUIDE, { ...data })
  } catch (err) {
    console.log(err)
  }
}

EventEmitter.on(Events.CLOSE_PAYMENT_GUIDE, onClosePaymentGuide)
EventEmitter.on(Events.PAYMENT_GUIDE, onClickPaymentGuide)
EventEmitter.on(Events.COPY_AMOUNT, onCopyAmount)
EventEmitter.on(Events.COPY_VA, onCopyVa)
EventEmitter.on(Events.DOWNLOAD_DETAILS, onDownloadDetails)
EventEmitter.on(Events.ADDRESS_PAGE_LOADS, onAddressPageLoads)
EventEmitter.on(Events.MORE_FROM_SELLER, onClickMoreFromStore)
EventEmitter.on(Events.MORE_FROM_SELLER, onClickMoreFromStore)
EventEmitter.on(Events.REDIRECT_TO_STORE, onClickRedirectStore)
EventEmitter.on(Events.STORE_WHATSAPP, onClickStoreWhatsapp)
EventEmitter.on(Events.STORE_EMAIL, onClickStoreEmail)
EventEmitter.on(Events.STORE_PHONE, onClickStorePhone)
EventEmitter.on(Events.STORE_CUSTOM_PRODUCT, onClickCustomProduct)
EventEmitter.on(Events.STORE_ARTICLE, onClickArticle)
EventEmitter.on(Events.STORE_VIDEO, onClickVideo)

EventEmitter.on(Events.STORE_PROFILES, onClickStoreProfiles)
EventEmitter.on(Events.CUSTOM_LINKS, onClickCustomLinks)
EventEmitter.on(Events.LOAD_STORE_VIEW, onLoadStoreView)
EventEmitter.on(Events.CONTINUE_CART, onClickContinueCart)
EventEmitter.on(Events.VIEW_ITEM, onClickViewItem)
EventEmitter.on(Events.VIEW_ALL, onClickViewAll)
EventEmitter.on(Events.CLICK_SEARCH, onClickSearch)
EventEmitter.on(Events.ON_SEARCHING, onSearch)
EventEmitter.on(Events.ON_SEARCH_FILTER, onSearchFilter)
EventEmitter.on(Events.ON_FILTER, onFilter)

EventEmitter.on(Events.VIEW_CART, onViewCart)
EventEmitter.on(Events.LOAD_CHECKOUT_SHIPMENT, onLoadCheckoutShipment)
EventEmitter.on(Events.OUT_OF_STOCK, onLoadOutOfStock)

EventEmitter.on(Events.BUY_NOW, onBuyNowClick)
EventEmitter.on(Events.LOAD_PERSONAL_DETAILS, onLoadPersonalDetails)
EventEmitter.on(Events.SUBMIT_PERSONAL_DETAILS, onSubmitPersonalDetails)
EventEmitter.on(Events.LOAD_ADDRESS, onLoadAddress)
EventEmitter.on(Events.LOAD_CART, onLoadCart)
EventEmitter.on(Events.SELECT_PROVINCE, onSelectProvince)
EventEmitter.on(Events.SELECT_CITY, onSelectCity)
EventEmitter.on(Events.SELECT_SUB_DISTRICT, onSelectSubDistrict)
EventEmitter.on(Events.SELECT_DISTRICT, onSelectDistrict)
EventEmitter.on(Events.SUBMIT_PERSONAL_DETAILS, onSubmitPersonalDetails)
EventEmitter.on(Events.LOAD_FINISH_CHECKOUT, onLoadFinishCheckout)
EventEmitter.on(Events.FINISH_CHECKOUT, onFinishCheckout)
EventEmitter.on(Events.LOAD_PAYMENT, onLoadPayment)
EventEmitter.on(Events.PAYMENT_PAGE, onPaymentPage)
EventEmitter.on(Events.ADD_TO_CART, onAddToCart)

EventEmitter.on(Events.USER_PAY_FAIL, onPaymentFailed)
EventEmitter.on(Events.USER_PAY_SUCCESSFULL, onPaymentSuccess)
EventEmitter.on(Events.PRODUCT_DETAILS, onFetchProduct)
// EventEmitter.on(Events.BTN_CLICK, onButtonAction)
EventEmitter.on(Events.USER_CHECKOUT_DETAILS, onCheckoutUserDetails)
EventEmitter.on(Events.SUB_ORDERS, onSubOrderGenerate)
EventEmitter.on(Events.SELECTED_PAYMENT_METHOD, onSelectPaymentMethod)
EventEmitter.on(Events.INCREASE_QUANTITY, onIncreaseQuantity)
EventEmitter.on(Events.USER_PAY, onUserPay)
EventEmitter.on(Events.SUBMIT_ADDRESS, onSubmitAddress)
EventEmitter.on(Events.CHAT_WITH_SELLER, onChatWithSeller)
