import { Helmet } from 'react-helmet'

const MetaCreator = ({ name, description, imageUrl }) => {
  return (
    <Helmet>
      <meta property='og:type' content='website' />
      <meta property='og:title' content={name} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageUrl} />

    </Helmet>
  )
}

export default MetaCreator
