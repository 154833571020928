import './BlogCard.scss'

export const BlogCard = ({ children, imgUrl }) => {
  return (
    <div className='blog-card '>
      <div className='img-wrapper'>
        <img src={imgUrl} alt='img' />
      </div>
      {children}
    </div>
  )
}
