import './Topbar.scss'
import { ArrowBackIos } from '@material-ui/icons'

export default function TopbarStoreView ({ goBack, title, children }) {
  return (
    <div className='topbar px-2 py-2 flex items-center bg-[#162E9A] text-white min-h-[50px]'>
      <div className='title-wrapper max-w-[max-content]'>
        {goBack && (
          <div onClick={goBack} className='ml-2 cursor-pointer'>
            <ArrowBackIos style={{ width: 20, height: 20, cursor: 'pointer', color: 'white' }} />
          </div>
        )}
        {title && <h4>{title}</h4>}
      </div>

      {children &&
        <div className='right-content'>
          {children}
        </div>}
    </div>
  )
}
