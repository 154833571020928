import './Feature.scss'
import bponIcon from './assets/bpom.svg'
import easyIcon from './assets/easy.svg'
import TnadC from './assets/TandC.svg'

export default function Feature (second) {
  return (
    <div className='feature'>
      <div className='feature-wrapper'>
        <img src={bponIcon} alt='icons' />
        <h5 className='op-80 feature-text'>100% Original & BPOM </h5>
      </div>
      <div className='feature-wrapper'>
        <img src={easyIcon} alt='icons' />
        <h5 className='op-80 feature-text'>Easy Returns</h5>
      </div>
      <div className='feature-wrapper'>
        <img src={TnadC} alt='icons' />
        <h5 className='op-80 feature-text'>Free Shipping T&C applied
        </h5>
      </div>
    </div>
  )
}
