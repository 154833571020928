import { errorTranslations } from '../../translation/commonTranslations'
import isEmail from 'validator/lib/isEmail'
import { isValidIndonesianNumber } from '../../utilities/suborderUtils'

const validate = (values) => {
  const {
    fullName,
    contactNumber,
    house,
    province,
    city,
    district,
    email,
    street,
    pincode,
    subDistrict
  } = values
  const errors = {}
  if (!fullName || fullName.trim() === '') {
    errors.fullName = errorTranslations.required
  }
  if (fullName) {
    const regExp = /[^A-Za-z 0-9]/g
    if (regExp.test(fullName)) {
      errors.fullName = errorTranslations.invalidName
    }
  }
  if (!subDistrict || subDistrict.trim() === '') {
    errors.subDistrict = errorTranslations.required
  }
  if (!contactNumber || contactNumber.trim() === '') {
    errors.contactNumber = errorTranslations.required
  }
  if (contactNumber) {
    const phoneRegex = /^(^\+62|^08)(\d{3,4}-?){2}\d{3,4}$/g
    const phoneRegex2 = /^(\+62[\s]?)?[0]?[78]\d{7,11}$/g
    const phoneNumber = isValidIndonesianNumber(contactNumber)
    if (!phoneRegex.test(phoneNumber) || !phoneRegex2.test(phoneNumber)) {
      errors.contactNumber = errorTranslations.invalidPhone
    }
  }
  if (!province || province.trim() === '') {
    errors.province = errorTranslations.required
  }
  if (!house || house.trim() === '') {
    errors.house = errorTranslations.required
  }
  if (!pincode || pincode.trim() === '') {
    errors.pincode = errorTranslations.required
  }
  if (!city || city.trim() === '') {
    errors.city = errorTranslations.required
  }
  if (!district || district.trim() === '') {
    errors.district = errorTranslations.required
  }

  if (!street || street.trim() === '') {
    errors.street = errorTranslations.required
  }

  if (email && !isEmail(email)) {
    errors.email = errorTranslations.enterValidEmail
  }
  return { ...errors }
}

export default validate
