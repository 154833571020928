import { commonTranslations } from '../../../../../translation/commonTranslations'
import RenderTab from '../../../components/render-tab/RenderTab'
import './ProductDetails.scss'

export default function ProductDetails ({ product }) {
  return (
    <div className='product-detail'>
      <h3 className='pb-2'>{commonTranslations.productDetails}</h3>
      <RenderTab product={product} />
    </div>
  )
}
