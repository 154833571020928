import * as React from 'react'
import Modal from '@material-ui/core/Modal'
import './modalDailog.scss'
import { noop } from 'lodash'

export default function ModalDailog ({ open, handleClose, closeByCross = () => null, ...props }) {
  return (
    <Modal
      open={open}
      onClose={noop}
      closeAfterTransition
    >
      <div className='box'>
        {props.children}
      </div>
    </Modal>
  )
}
