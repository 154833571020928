import './VideoCard.scss'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useRef } from 'react'
import { registerClick } from '../../../containers/dsf-store-view/saga/saga'

export const VideoCard = ({ videoUrl, id, styleSheet, thumbnail }) => {
  const videoRef = useRef()
  const pauseIconRef = useRef()

  const handleClickOnPlayer = () => {
    window.open(videoUrl?.startsWith('http') ? videoUrl : `https://${videoUrl}`, '_blank')
    registerClick({ id })
  }

  return (
    <>
      <div onClick={handleClickOnPlayer} className='video-card' style={styleSheet || {}}>
        <div className='playIcon-wrapper'>
          <PlayArrowIcon ref={pauseIconRef} className='pauseIcon' />
        </div>
        <video poster={thumbnail} src={`${videoUrl}#t=1`} ref={videoRef} className=' video-frame ' />
      </div>
    </>
  )
}
