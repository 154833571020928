class EventEmitter {
  static subscribers = {}

  static isAlreadySubscribed (event, callback) {
    const subscribers = this.subscribers[event] || []
    return subscribers.some((s) => s.name === callback.name)
  }

  static emit(event, data) {
    const subscribers = this.subscribers[event]
    if (!subscribers) return
    subscribers.forEach((subscriber) => subscriber(data))
  }

  static on(event, callback) {
    if (this.isAlreadySubscribed (event, callback)) return
    this.subscribers[event] = this.subscribers[event] || []
    this.subscribers[event].push(callback)
  }
}

export const Events = {
  SOCIAL_ICONS_INSTAGRAM:'dsfstore_instagram',
  SOCIAL_ICONS_FACEBOOK:'dsfstore_facebook',
  SOCIAL_ICONS_TIKTOK:'dsfstore_tiktok',
  SOCIAL_ICONS_YOUTUBE:'dsfstore_youtube',
  SOCIAL_ICONS_SNAPCHAT:'dsfstore_snapchat',
  SOCIAL_ICONS_TELEGRAM:'dsfstore_telegram',
  STORE_PROFILES:'dsfstore_profiles',
  STORE_PLAYSTORE:'dsfstore_playstore',
  STORE_APPSTORE:'dsfstore_appstore',
  STORE_SHOPEE:'dsfstore_shopee',
  STORE_TOKOPEDIA:'dsfstore_tokopedia',
  STORE_LAZADA:'dsfstore_lazada',
  STORE_BLIBLI:'dsfstore_blibli',
  STORE_BUKALLAPAK:'dsfstore_bukallapak',
  CUSTOM_LINKS:'dsfstore_linkid',
  STORE_ARTICLE:'dsfstore_article',
  STORE_VIDEO:'dsfstore_video',

  STORE_CUSTOM_PRODUCT:'dsfstore_custom_product',
  STORE_PHONE:'dsfstore_contact_wa_phone',
  STORE_EMAIL:'dsfstore_contact_wa_email',
  STORE_WHATSAPP:'dsfstore_contact_wa_whatsapp',
  LOAD_STORE_VIEW:'dsf_store_view',

  CONTINUE_CART:'dsfpage_continue_cart',
  VIEW_ITEM:'dsfstore_viewitem',
  VIEW_ALL:'dsfstore_viewall',
  CLICK_SEARCH:'dsf_search',
  ON_SEARCHING:'dsf_search_term',
  ON_SEARCH_FILTER:'dsf_search_filter',
  ON_FILTER:"dsf_set_filter",

  REDIRECT_TO_STORE:'dsfpage_redirecttostore',
  VIEW_CART:'dsf_view_cart',
  LOAD_CHECKOUT_SHIPMENT:'dsf_checkout_shipment_load',
  OUT_OF_STOCK:'dsf_out_of_stock',
  STORE_VIEW_ALL_FILTER:'dsfstore_viewall_filter',

  LOAD_PERSONAL_DETAILS:'dsf_load_personal_page',
  LOAD_ADDRESS:'dsf_load_address',
  LOAD_CART:"dsfpage_load_cart",
  SELECT_PROVINCE:'dsf_select_province',
  SELECT_CITY:"dsf_select_city",
  SELECT_SUB_DISTRICT:"dsf_select_sub_district",
  SELECT_DISTRICT:'dsf_select_district',
  SUBMIT_PERSONAL_DETAILS:'dsf_submit',
  LOAD_FINISH_CHECKOUT:'dsfpage_load_finish_checkout',
  FINISH_CHECKOUT:'dsfpage_finishcheckout',
  LOAD_PAYMENT:'dsf_load_payment',
  PAYMENT_PAGE:'dsf_payment',
  ADD_TO_CART:'dsfpage_addtocart',

  MORE_FROM_SELLER: 'dsfpage_morefromseller',
  PRODUCT_DETAILS: 'dsfpage_pdp_view',
  BTN_CLICK: 'dsfpage_action',
  BUY_NOW: 'dsfpage_buynow',
  USER_CHECKOUT_DETAILS: 'dsf_user_checkout_details',
  SUB_ORDERS: 'dsf_sub_orders',
  SELECTED_PAYMENT_METHOD: 'dsf_selected_payment_method',
  INCREASE_QUANTITY: 'dsfpage_qtyadd',
  SUBMIT_ADDRESS: 'dsfpage_submit',
  USER_PAY: 'dsfpage_pay',
  USER_PAY_SUCCESSFULL: 'dsfpage_success',
  USER_PAY_FAIL: 'dsfpage_failed',
  CHAT_WITH_SELLER: 'dsfpage_chatwithseller',
  ADDRESS_PAGE_LOADS: 'dsfpage_load_address',

  DOWNLOAD_DETAILS: 'dsf_download_details',
  COPY_VA: 'dsf_copy_va',
  COPY_AMOUNT: 'dsf_copy_amount',
  PAYMENT_GUIDE: 'dsf_payment_guide',
  CLOSE_PAYMENT_GUIDE: 'dsf_close_payment_guide',
}

export default EventEmitter