import './Rating.scss'
import ratingIcon from './assets/star.svg'

export default function Rating ({ rating, reviewer }) {
  return (
    <div className='rating'>
      <img src={ratingIcon} alt='rating' />
      <p>{rating}</p>
      {reviewer > 0 && <p>({reviewer})</p>}

    </div>
  )
}
