import { commonTranslations } from '../../../../../translation/commonTranslations'
import { MoreCard } from '../cards/more-card/MoreCard'
import './MoreProducts.scss'

export default function MoreProducts ({ products, onClick, store }) {
  return (
    <div className='more-products'>
      <h3 className='pb-2'>{commonTranslations.moreFrom} {store}</h3>
      <div className='card-wrapper scroll-bar-style'>
        {products?.map((pro) => (
          <div key={pro.product?.id} className='single-card'>
            {
                pro.product !== null &&
                  <MoreCard onClick={onClick} product={{ product: pro.product, dsf: pro.dsf }} />
            }
          </div>
        ))}

      </div>
    </div>
  )
}
