import { Grid } from '@material-ui/core'
import Label from '../../../../components/Label/Label'
import './GroupSearchField.css'
import Error from '../../../../components/error/error'
import SelectedInput from '../../../../components/SelectedInput/SelectedInput'

export default function GroupSearchField (props) {
  const { label, required } = props
  const { error, submitFailed } = props.meta

  return (
    <>
      <Grid container spacing={1} item direction='column'>
        {label && (
          <Grid item>
            <Label title={label} required={required || false} />
          </Grid>
        )}

        <Grid item>
          <SelectedInput {...props} />
        </Grid>
      </Grid>
      {submitFailed && error && <Error error={error} />}
    </>
  )
}
