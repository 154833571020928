import { ArrowBackIos } from '@material-ui/icons'
import React from 'react'
import './styles.scss'

export default function MobileHeader ({ onClick, heading, component }) {
  return (
    <div className='w-full flex items-center h-[50px] bg-white box-shadow'>
      <ArrowBackIos className='ml-3 cursor-pointer' onClick={onClick} style={{ width: 20, height: 20 }} />
      {component || <p className='text-[16px] ml-2'>{heading}</p>}
    </div>
  )
}
