import SearchBar from '../../components/search-bar/SearchBar'
import './TopSellingProducts.scss'
import filterIcon from './../../assets/filterIcon.svg'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import DisplaySection from '../../components/common/DisplaySection'
import { TopSellCard } from '../../components/cards/top-sell-card/TopSellCard'
import { getBrandsList, getSellerDetail, getSharedProducts } from './saga/saga'
import TopbarStoreView from '../DSFPDP/home/components/topbar/TopBarStoreView'
import FilterModal from '../filter-modal/FilterModal'
import CheckBox from '../../components/check-box/CheckBox'
import Button from '../../components/Button'
import { commonTranslations } from '../../translation/commonTranslations'
import { injectIntl } from 'react-intl'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { CircularProgress } from '@material-ui/core'
import { clearFields } from '../OrderDetail/saga/saga'

function TopSellingProducts ({ intl }) {
  const history = useHistory()
  const { formatMessage } = intl
  const sharedProducts = useSelector((state) => state.sharedProducts)
  const { seller } = useSelector((state) => state)
  const { brands } = useSelector(state => state)
  const { shareId } = useParams()
  const [page, setPage] = useState(1)
  const [tempProducts, updateTempProducts] = useState([])
  const limit = 10
  const [openModal, setOpenModal] = useState(false)
  const [checkList, updateCheckList] = useState([])
  const [checkPreviousList, setCheckPreviousList] = useState([])
  const [filteredBrands, updateFilteredBrands] = useState()
  const [searchInput, updateSearchInput] = useState('')
  const [brandSearchInput, setbrandSearchInput] = useState('')
  const [brandsApplied, setBrandsApplied] = useState(false)

  const onCheckBoxClick = (id) => {
    updateCheckList([...checkList?.map((ch) => ch.id === id ? { ...ch, checked: !ch.checked } : ch)])
    setCheckPreviousList([...checkList?.map((ch) => ch.id === id ? { ...ch, checked: !ch.checked } : ch)])
  }
  const resetCheckBox = () => {
    updateCheckList([...checkList?.map((ch) => { return { ...ch, checked: false } })])
  }

  const onClickProducts = (productId) => {
    clearFields()
    history.push(`/${seller.seller?.shareId}/${productId}`)
    EventEmitter.emit(Events.VIEW_ITEM)
  }

  const handleOpen = () => {
    setOpenModal(true)
    updateCheckList(checkPreviousList)
    updateSearchInput('')
  }

  const handleClose = () => {
    setOpenModal(false)
    if (!brandsApplied) {
      updateCheckList([...checkList?.map((ch) => { return { ...ch, checked: false } })])
      setCheckPreviousList([...checkList?.map((ch) => { return { ...ch, checked: false } })])
    }
  }
  const onClickApply = () => {
    if (checkList.some(item => item.checked)) {
      setBrandsApplied(true)
    }
    setOpenModal(false)
    setCheckPreviousList(checkList)
    // update query parameter according to checklist
    const checkedBrands = checkList?.filter(ch => ch.checked === true)?.map(br => br.id)
    setPage(1)
    updateTempProducts([])
    updateFilteredBrands(checkedBrands)
    EventEmitter.emit(Events.ON_FILTER, {
      seller_id: seller.seller?.resellerId || '',
      brands: checkedBrands?.toString() || ''
    })
  }

  const handleSearchOnfilter = (value) => {
    setbrandSearchInput(value)
    getBrandsList({ shareId: shareId, q: value })
    EventEmitter.emit(Events.ON_SEARCH_FILTER, {
      seller_id: seller.seller?.resellerId || '',
      keyword: value
    })
  }

  const scrollEvent = () => {
    const { scrollTop, clientHeight, offsetHeight } = document.documentElement
    if (window.innerWidth < 768 && (scrollTop + clientHeight + 200) >= offsetHeight) {
      setPage(page + 1)
    } else if ((scrollTop + clientHeight + 100) >= offsetHeight) {
      setPage(page + 1)
    }
  }

  function debounce (func, timeout = 800) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => { func.apply(this, args) }, timeout)
    }
  }

  const handleSearchOnTopSellPage = debounce((value) => {
    updateTempProducts([])
    updateSearchInput(value)
    setPage(1)
    EventEmitter.emit(Events.ON_SEARCHING, {
      seller_id: seller.seller?.resellerId || '',
      keyword: value
    })
  })

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent)
    if (sharedProducts.products && (sharedProducts.products.length === 0)) {
      window.removeEventListener('scroll', scrollEvent)
    } else if (sharedProducts.products && (sharedProducts.products.length < limit)) {
      const incomingData = [...tempProducts, ...sharedProducts?.products?.map((it) => { return { ...it.product, ...it.dsf } })]
      const filteredData = incomingData.filter((value, index, self) => {
        const _value = JSON.stringify(value)
        return index === incomingData.findIndex(obj => {
          return JSON.stringify(obj) === _value
        })
      })
      updateTempProducts(filteredData)
      window.removeEventListener('scroll', scrollEvent)
    } else if (sharedProducts.products && sharedProducts.products.length > 0) {
      const incomingData = [...tempProducts, ...sharedProducts?.products?.map((it) => { return { ...it.product, ...it.dsf } })]
      const filteredData = incomingData.filter((value, index, self) => {
        const _value = JSON.stringify(value)
        return index === incomingData.findIndex(obj => {
          return JSON.stringify(obj) === _value
        })
      })
      updateTempProducts(filteredData)
    }
  }, [sharedProducts?.products])

  useEffect(() => {
    getSellerDetail(shareId)
    getBrandsList({ shareId: shareId })
  }, [])

  useEffect(() => {
    const modifyBrands = brands.brands?.map((br) => { return { ...br, checked: false } })
    updateCheckList(modifyBrands)
    setCheckPreviousList(modifyBrands)
  }, [brands])

  useEffect(() => {
    if (filteredBrands?.length > 0) {
      getSharedProducts({ shareId: shareId, queryObj: { name: encodeURIComponent(searchInput.trim()) || '', page: page, limit: limit || 4, brandIds: [filteredBrands] } })
    } else {
      getSharedProducts({ shareId: shareId, queryObj: { name: encodeURIComponent(searchInput.trim()) || '', page: page, limit: limit || 4 } })
    }
  }, [page, filteredBrands, searchInput.length])

  useEffect(() => {
    updateTempProducts([])
  }, [window.location.pathname])

  return (
    <div style={{ backgroundColor: openModal ? 'white' : 'rgba(0, 0, 255, 0.76)' }} className='top-selling-products pb-16 filter-modal-wrapper'>
      <FilterModal open={openModal} onClose={handleClose}>
        <div className='modal-body bg-[#FFFFFF] min-h-screen filter-modal-wrapper'>
          <TopbarStoreView goBack={handleClose}>
            <SearchBar clearIcon={brandSearchInput.length > 0} onSearch={handleSearchOnfilter} styleSheet={{ background: 'white', color: 'black' }} iconColor='#B2B2B2' placeholder={formatMessage(commonTranslations.searchBrandsHere.props)} />
          </TopbarStoreView>
          <div className='brands-list max-h-[80vh] min-h-[80vh] overflow-y-auto px-[1rem]'>
            <div className='my-4 flex items-center justify-between'>
              <h3 className='text-base font-medium'>{commonTranslations.brands}</h3>
              <h5 onClick={resetCheckBox} className='underline text-xs decoration-[#162E9A] text-[#162E9A] cursor-pointer'>{commonTranslations.clearAll}</h5>
            </div>
            {
              checkList?.map((chk) => (
                <CheckBox key={chk.id} name={chk.name} checked={chk.checked} onChange={() => onCheckBoxClick(chk.id)} />
              ))
            }

          </div>
          <div className='grid grid-flow-row grid-cols-2 gap-2 sticky bottom-2 px-[1rem] mt-2'>
            <Button onClick={handleClose} borderRadius='6px' btnHeight='48px' btnBorder='1px solid #162E9A' bgColor='#FFFFFF' btnColor='#162E9A'>{commonTranslations.close}</Button>
            <Button onClick={onClickApply} borderRadius='6px' btnHeight='48px' btnBorder='1px solid #162E9A' bgColor='#162E9A' btnColor='#FFFFFF'>{commonTranslations.apply}</Button>
          </div>
        </div>
      </FilterModal>
      {!openModal && (
        <>
          <TopbarStoreView goBack={() => window.history.back()} title={commonTranslations.topSellingProdcuts} />
          <div className='top-selling-body px-2 xs:px-4'>
            <div className='mt-6 mb-6 flex'>
              <div className='flex-1'>
                <SearchBar clearIcon={searchInput.length > 0} onSearch={handleSearchOnTopSellPage} placeholder={formatMessage(commonTranslations.searchProductsBrands.props)} />
              </div>
              <div onClick={handleOpen} className='filterIcon ml-4 cursor-pointer'>
                <img src={filterIcon} alt='filterIcon' />
              </div>
            </div>
            <div className='display-products' />
            {
              tempProducts.length > 0 ? <DisplaySection topSell CartView={TopSellCard} itemList={tempProducts} onClickProducts={onClickProducts} /> : sharedProducts?.isLoading ? <center><CircularProgress style={{ color: 'white' }} /></center> : <div className='text-center text-white'>{commonTranslations.noProductsFound}</div>
            }
          </div>
        </>
      )}
    </div>
  )
}

export default injectIntl(TopSellingProducts)
