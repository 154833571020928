import EventEmitter, { Events } from '../../utilities/eventEmitter'
import './styles.scss'

export const ChatWithSeller = ({ icon, title, user, pageName = 'Success Page' }) => {
  const openSms = () => window.open(`sms:${user?.dsfMobile || user?.mobile}`, '_blank')
  const openWhatsapp = () => window.open(`https://wa.me/${user?.waMobile}`, '_blank')

  return (
    <div
      role='button'
      onClick={() => {
        EventEmitter.emit(Events.CHAT_WITH_SELLER, {
          page_name: pageName
        })

        if (user?.waMobile) {
          openWhatsapp()
          return
        }
        openSms()
      }}
      className='box-wrapper'
    >
      <div role='button' className='icon-wrapper'>
        <img src={icon} alt='' />
      </div>
      <label role='button'>{title}</label>
    </div>
  )
}
