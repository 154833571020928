import { BlogCard } from '../../../../components/cards/blog-card/BlogCard'
import './BlogDisplay.scss'

export default function BlogDisplay ({ blogs, onClick }) {
  return (

    <div className='blog-display mb-4 mt-3'>
      <div className='flex gap-[10px] flex-row nowrap overflow-x-auto overflow-y-hidden items-start scroll-bar-style'>
        {
          blogs?.map((bl) => (
            <div onClick={() => onClick(bl.url, bl?.id)} className='cursor-pointer w-[150px]' key={bl.id}>
              <BlogCard imgUrl={bl.imageUrl} />
              <h5 className='mt-[10px] text-white break-all w-[150px]'>{bl.title && bl.title}</h5>
            </div>
          ))
        }
      </div>
    </div>
  )
}
