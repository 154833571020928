import { numberWithCommas } from '../../../utilities/currency'
import './FeaturedCard.scss'

export const FeaturedCard = (props) => {
  const { styleClass, icon, imgBg, product = {}, onClick = () => null } = props

  return (
    <div onClick={() => onClick(product?.url || null, product?.id)} className={'featured-card h-full' + (styleClass && styleClass)}>
      <div className={'img-wrapper ' + (imgBg ? 'imgBg' : '')}>
        {
          product?.productImages !== null &&
            <img src={product?.imageUrl && product?.imageUrl} alt='img' className='image-logo' />
        }
        {icon && <img className='icon' src={icon} alt='icon' />}
      </div>
      <p>{product?.name || product?.title}</p>
      <ul className='price-wrapper'>
        <li>Rp {numberWithCommas(product?.finalPrice?.toFixed(0) || product?.price)}</li>
        {product?.retailPrice > product.finalPrice && <li>{numberWithCommas(Math.abs((((product.finalPrice / product?.retailPrice) * 100) - 100)))?.toFixed(0)}% Off</li>}
      </ul>
    </div>
  )
}
