import produce from 'immer'
import { FETCH_BRANDS_FAIL, FETCH_BRANDS_INIT, FETCH_BRANDS_SUCCESS, FETCH_SELLER_FAIL, FETCH_SELLER_INIT, FETCH_SELLER_SUCCESS, FETCH_SHARED_PRODUCTS_FAIL, FETCH_SHARED_PRODUCTS_INIT, FETCH_SHARED_PRODUCTS_SUCCESS } from '../../../actions'

const sellerInit = {
  isLoading: false,
  seller: {},
  isError: false
}

export const seller = produce((state = sellerInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SELLER_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SELLER_SUCCESS:
      state.seller = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SELLER_FAIL:
      state.seller = {}
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, sellerInit)

const sharedProductsInit = {
  isLoading: false,
  products: [],
  isError: false
}

export const sharedProducts = produce((state = sharedProductsInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SHARED_PRODUCTS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SHARED_PRODUCTS_SUCCESS:
      state.products = data.data.products
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SHARED_PRODUCTS_FAIL:
      state.products = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, sharedProductsInit)

const brandsInit = {
  isLoading: false,
  brands: [],
  isError: false
}

export const brands = produce((state = brandsInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_BRANDS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_BRANDS_SUCCESS:
      state.brands = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_BRANDS_FAIL:
      state.seller = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, brandsInit)
