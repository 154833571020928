// fetch brands
const FETCH_BRANDS_INIT = 'BRANDS:FETCH_BRANDS_INIT'
const FETCH_BRANDS_SUCCESS = 'BRANDS:FETCH_BRANDS_SUCCESS'
const FETCH_BRANDS_FAIL = 'BRANDS:FETCH_BRANDS_FAIL'

// fetch Seller Linls
const FETCH_SELLER_LINKS_INIT = 'SELLER_LINKS:FETCH_SELLER_LINKS_INIT'
const FETCH_SELLER_LINKS_SUCCESS = 'SELLER_LINKS:FETCH_SELLER_LINKS_SUCCESS'
const FETCH_SELLER_LINKS_FAIL = 'SELLER_LINKS:FETCH_SELLER_LINKS_FAIL'

// fetch Related Videos

const FETCH_RELATED_VIDEOS_INIT = 'RELATED_VIDEOS:FETCH_RELATED_VIDEOS_INIT'
const FETCH_RELATED_VIDEOS_SUCCESS = 'RELATED_VIDEOS:FETCH_RELATED_VIDEOS_SUCCESS'
const FETCH_RELATED_VIDEOS_FAIL = 'RELATED_VIDEOS:FETCH_RELATED_VIDEOS_FAIL'

// fetch Similar Products

const FETCH_SIMILAR_PRODUCTS_INIT = 'SIMILAR_PRODUCTS:FETCH_SIMILAR_PRODUCTS_INIT'
const FETCH_SIMILAR_PRODUCTS_SUCCESS = 'SIMILAR_PRODUCTS:FETCH_SIMILAR_PRODUCTS_SUCCESS'
const FETCH_SIMILAR_PRODUCTS_FAIL = 'SIMILAR_PRODUCTS:FETCH_SIMILAR_PRODUCTS_FAIL'

// fetch Postal Data
const FETCH_POSTAL_INIT = 'POSTAL:FETCH_POSTAL__INIT'
const FETCH_POSTAL_SUCCESS = 'POSTAL:FETCH_POSTAL__SUCCESS'
const FETCH_POSTAL_FAIL = 'POSTAL:FETCH_POSTAL__FAIL'

// fetch Shared Products
const FETCH_SHARED_PRODUCTS_INIT = 'SHARED_PRODUCTS:FETCH_SHARED_PRODUCTS__INIT'
const FETCH_SHARED_PRODUCTS_SUCCESS = 'SHARED_PRODUCTS:FETCH_SHARED_PRODUCTS__SUCCESS'
const FETCH_SHARED_PRODUCTS_FAIL = 'SHARED_PRODUCTS:FETCH_SHARED_PRODUCTS__FAIL'

// fetch Seller
const FETCH_SELLER_INIT = 'SELLER:FETCH_SELLER__INIT'
const FETCH_SELLER_SUCCESS = 'SELLER:FETCH_SELLER__SUCCESS'
const FETCH_SELLER_FAIL = 'SELLER:FETCH_SELLER__FAIL'

// fetch Product
const FETCH_PRODUCT_INIT = 'PRODUCT:FETCH_PRODUCT_INIT'
const FETCH_PRODUCT_SUCCESS = 'PRODUCT:FETCH_PRODUCT_SUCCESS'
const FETCH_PRODUCT_FAIL = 'PRODUCT:FETCH_PRODUCT_FAIL'

// suborders
const FETCH_SUBORDER_INIT = 'SUBORDER:FETCH_SUBORDER_INIT'
const FETCH_SUBORDER_SUCCESS = 'SUBORDER:FETCH_SUBORDER_SUCCESS'
const FETCH_SUBORDER_FAIL = 'SUBORDER:FETCH_SUBORDER_FAIL'
const UPDATE_SHIPPING_PARTNER = 'SUBORDER:UPDATE_SHIPPING_PARTNER'

// Get Province List

// Get Locatin Data
const FETCH_PROVINCES_INIT = 'PROVINCE:FETCH_PROVINCES_INIT'
const FETCH_PROVINCES_SUCCESS = 'PROVINCE:FETCH_PROVINCES_SUCCESS'
const FETCH_PROVINCES_FAIL = 'PROVINCE:FETCH_PROVINCES_FAIL'

// Get Locatin Data
const GET_LOCATION_DATA_INIT = 'LOCATION:GET_LOCATION_DATA_INIT'
const GET_LOCATION_DATA_SUCCESS = 'LOCATION:GET_LOCATION_DATA_SUCCESS'
const GET_LOCATION_DATA_FAIL = 'LOCATION:GET_LOCATION_DATA_FAIL'

// Add Order
const ADD_ORDER_INIT = 'ADD_ORDER:ADD_ORDER_INIT'
const ADD_ORDER_SUCCESS = 'ADD_ORDER:ADD_ORDER_SUCCESS'
const CUSTOM_ORDER_SUCCESS = 'ADD_ORDER:CUSTOM_ORDER_SUCCESS'
const ADD_ORDER_FAIL = 'ADD_ORDER:ADD_ORDER_FAIL'

// clearFields
const CLEAR_FIELDS = 'CLEAR_FIELDS'

// notifier
const NOTIFIER_SHOW = 'NOTIFIER:SHOW'
const NOTIFIER_HIDE = 'NOTIFIER:HIDE'

// create order
const CREATE_ORDER_INIT = 'CREATE_ORDER:CREATE_ORDER_INIT'
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER:CREATE_ORDER_SUCCESS'
const CREATE_ORDER_PAYMENT_SUCCESS = 'CREATE_ORDER_PAYMENT_SUCCESS'
const CREATE_ORDER_FAIL = 'CREATE_ORDER:CREATE_ORDER_FAIL'

// cart
const SET_CART = 'SET_CART'
const ADD_CART = 'ADD_CART'
const UPDATE_CART = 'UPDATE_CART'
const DELETE_CART = 'DELETE_CART'
const SELECT_SPECIFIC_CART = 'SELECT_SPECIFIC_CART'
const SELECT_ALL_CART = 'SELECT_ALL_CART'

// Payments
const FETCH_PAYMENTS_LIST_INIT = 'FETCH_PAYMENTS_LIST_INIT'
const FETCH_PAYMENTS_LIST_SUCCESS = 'FETCH_PAYMENTS_LIST_SUCCESS'
const FETCH_PAYMENTS_LIST_FAIL = 'FETCH_PAYMENTS_LIST_FAIL'

// Ewallet Payments
const EWALLET_PAYMENT_INIT = 'EWALLET_PAYMENT_INIT'
const EWALLET_PAYMENT_SUCCESS = 'EWALLET_PAYMENT_SUCCESS'
const EWALLET_PAYMENT_FAIL = 'EWALLET_PAYMENT_FAIL'
const PAYMENT_FAILED_BY_USER = 'PAYMENT_FAILED_BY_USER'
const CLEAR_PAYMENT_FAILED_BY_USER = 'CLEAR_PAYMENT_FAILED_BY_USER'
const INITIATE_PAYMENTS = 'INITIATE_PAYMENTS'
const CLEAR_INITIATE_PAYMENTS = 'CLEAR_INITIATE_PAYMENTS'

// Virtual Payments
const VIRTUAL_ACCOUNT_PAYMENT_INIT = 'VIRTUAL_ACCOUNT_PAYMENT_INIT'
const VIRTUAL_ACCOUNT_PAYMENT_SUCCESS = 'VIRTUAL_ACCOUNT_PAYMENT_SUCCESS'
const VIRTUAL_ACCOUNT_PAYMENT_FAIL = 'VIRTUAL_ACCOUNT_PAYMENT_FAIL'

export {
  FETCH_BRANDS_INIT,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAIL,
  FETCH_SELLER_LINKS_INIT,
  FETCH_SELLER_LINKS_SUCCESS,
  FETCH_SELLER_LINKS_FAIL,
  FETCH_RELATED_VIDEOS_INIT,
  FETCH_RELATED_VIDEOS_SUCCESS,
  FETCH_RELATED_VIDEOS_FAIL,
  FETCH_SIMILAR_PRODUCTS_INIT,
  FETCH_SIMILAR_PRODUCTS_SUCCESS,
  FETCH_SIMILAR_PRODUCTS_FAIL,
  FETCH_POSTAL_INIT,
  FETCH_POSTAL_SUCCESS,
  FETCH_POSTAL_FAIL,
  FETCH_SHARED_PRODUCTS_INIT,
  FETCH_SHARED_PRODUCTS_SUCCESS,
  FETCH_SHARED_PRODUCTS_FAIL,
  FETCH_SELLER_INIT,
  FETCH_SELLER_SUCCESS,
  FETCH_SELLER_FAIL,
  FETCH_PRODUCT_INIT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAIL,
  FETCH_SUBORDER_INIT,
  FETCH_SUBORDER_SUCCESS,
  FETCH_SUBORDER_FAIL,
  UPDATE_SHIPPING_PARTNER,
  FETCH_PROVINCES_INIT,
  FETCH_PROVINCES_SUCCESS,
  FETCH_PROVINCES_FAIL,
  GET_LOCATION_DATA_INIT,
  GET_LOCATION_DATA_SUCCESS,
  GET_LOCATION_DATA_FAIL,
  ADD_ORDER_INIT,
  ADD_ORDER_SUCCESS,
  CUSTOM_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  CLEAR_FIELDS,
  NOTIFIER_SHOW,
  NOTIFIER_HIDE,
  CREATE_ORDER_INIT,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  ADD_CART,
  UPDATE_CART,
  DELETE_CART,
  SET_CART,
  SELECT_SPECIFIC_CART,
  SELECT_ALL_CART,
  FETCH_PAYMENTS_LIST_INIT,
  FETCH_PAYMENTS_LIST_SUCCESS,
  FETCH_PAYMENTS_LIST_FAIL,
  CREATE_ORDER_PAYMENT_SUCCESS,
  EWALLET_PAYMENT_INIT,
  EWALLET_PAYMENT_SUCCESS,
  EWALLET_PAYMENT_FAIL,
  PAYMENT_FAILED_BY_USER,
  CLEAR_PAYMENT_FAILED_BY_USER,
  VIRTUAL_ACCOUNT_PAYMENT_INIT,
  VIRTUAL_ACCOUNT_PAYMENT_SUCCESS,
  VIRTUAL_ACCOUNT_PAYMENT_FAIL,
  INITIATE_PAYMENTS,
  CLEAR_INITIATE_PAYMENTS
}
