import { Checkbox } from '@material-ui/core'
import './CheckBox.scss'

const CheckBox = ({ name, value, label, checked, onChange = () => null }) => {
  return (
    <div className='check-box'>
      <Checkbox
        checked={checked}
        onChange={onChange}
        value={value || name}
        name={name}
        color='primary'
      />
      <label>{label || name}</label>
    </div>
  )
}

export default CheckBox
