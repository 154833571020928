import React from 'react'
import {
  Grid
} from '@material-ui/core'
import './styles.scss'

const QuantitySelector = (props) => {
  const { onChangeQuantity, disabledMinusButton, disabledPlusButton, disableSelector, name, id, value, onChangeQuantityValue } = props
  const onBlur = () => {
    if (value === '' || isNaN(value)) {
      onChangeQuantityValue(1)
    }
  }
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      className={`wrapper flexRow ${disableSelector && 'disabled'}`}
      spacing={0}
    >
      <Grid item>
        <button
          type='button'
          disabled={disabledMinusButton}
          className={`button ${(disabledMinusButton || disableSelector) && 'disabled'} leftRadius`}
          onClick={() => onChangeQuantity('decrement')}
        >
          -
        </button>
      </Grid>
      <input
        name={name}
        type='number'
        id={id}
        width='40px'
        className='input'
        value={value}
        onChange={e => onChangeQuantityValue(e.target.value)}
        onBlur={onBlur}
      />
      <Grid item xs={1} sm={1} md={1}>
        <button
          type='button'
          className={`button ${(disabledPlusButton || disableSelector) && 'disabled'} rightRadius`}
          onClick={() => onChangeQuantity({}, 'increment')}
          disabled={disabledPlusButton}
        >
          +
        </button>
      </Grid>
    </Grid>
  )
}

export default QuantitySelector
