
/* eslint-disable no-case-declarations */
import produce from 'immer'
import { FETCH_SUBORDER_INIT, FETCH_SUBORDER_SUCCESS, FETCH_SUBORDER_FAIL, CLEAR_FIELDS, CREATE_ORDER_INIT, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAIL, CREATE_ORDER_PAYMENT_SUCCESS, PAYMENT_FAILED_BY_USER, CLEAR_PAYMENT_FAILED_BY_USER, INITIATE_PAYMENTS, CLEAR_INITIATE_PAYMENTS } from '../../../actions'
import { get, isEmpty } from 'lodash'

const defaultState = {
  isLoading: false,
  shipments: [],
  discountedShippingSuggestion: {},
  isError: false,
  createOrder: {
    isLoading: false,
    data: {}
  },
  paymentFailed: false,
  paymentInitiated: false
}

export default produce((state = defaultState, action) => {
  const { type, data } = action
  switch (type) {
    case INITIATE_PAYMENTS: {
      state.paymentInitiated = true
      break
    }
    case CLEAR_INITIATE_PAYMENTS: {
      state.paymentInitiated = false
      break
    }
    case PAYMENT_FAILED_BY_USER: {
      state.paymentFailed = true
      break
    }
    case CLEAR_PAYMENT_FAILED_BY_USER: {
      state.paymentFailed = false
      break
    }
    case FETCH_SUBORDER_INIT: {
      state.isLoading = true
      state.shipments = []
      state.isError = false
      break
    }
    case FETCH_SUBORDER_SUCCESS: {
      state.isLoading = false
      state.shipments = data?.data.shipments ? updateDefaultSelectedShippingPartner(data?.data.shipments) : []
      state.discountedShippingSuggestion = data?.data?.discountedShippingSuggestion
      state.isError = false
      break
    }
    case FETCH_SUBORDER_FAIL: {
      state.isLoading = false
      state.shipments = []
      state.discountedShippingSuggestion = {}
      state.isError = true
      break
    }
    case CREATE_ORDER_INIT: {
      state.createOrder.isLoading = true
      break
    }
    case CREATE_ORDER_SUCCESS: {
      state.createOrder.isLoading = false
      state.createOrder.data = data?.data
      break
    }
    case CREATE_ORDER_PAYMENT_SUCCESS: {
      state.createOrder.isLoading = false
      break
    }
    case CREATE_ORDER_FAIL: {
      state.createOrder.isLoading = false
      state.createOrder.data = {}
      break
    }
    case CLEAR_FIELDS: {
      state.isLoading = false
      state.shipments = []
      state.discountedShippingSuggestion = {}
      state.isError = false
      state.createOrder = {
        isLoading: false,
        data: {}
      }
      state.paymentFailed = false
      state.paymentInitiated = false
      break
    }
    default:
      return state
  }
}, defaultState)

const updateDefaultSelectedShippingPartner = (shipments) => {
  const updatedShipments = shipments.map((shipment) => {
    const selectedShippingPartner = get(shipment, 'shippingFeeInfo.shippingPartners[0]', {})

    const selectedShippingPartnerObject = isEmpty(selectedShippingPartner)
      ? {}
      : { selectedShippingPartner }

    const shippingFeeInfo = {
      ...shipment.shippingFeeInfo,
      ...selectedShippingPartnerObject
    }

    return ({
      ...shipment,
      shippingFeeInfo
    })
  })
  return updatedShipments
}
