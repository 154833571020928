import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import Snackbar from './components/snackbar'
import { setCart } from './containers/CartPage/saga/saga'
import routes from './routes'
import fetchTranslation from './translation/sagas'
import './utilities/analytics'

function App () {
  const { messages, locale } = useSelector((state) => state.intl)
  const fetchCart = () => {
    const cart = window.localStorage.getItem('cart')

    if (cart) {
      setCart(JSON.parse(cart))
    }
  }
  useEffect(() => {
    fetchTranslation()
    fetchCart()
  }, [])

  return (
    <IntlProvider messages={messages} locale={locale}>
      {routes}
      <Snackbar />
    </IntlProvider>
  )
}

export default App
