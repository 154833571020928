import {
  FETCH_PAYMENTS_LIST_INIT,
  FETCH_PAYMENTS_LIST_SUCCESS,
  FETCH_PAYMENTS_LIST_FAIL,
  EWALLET_PAYMENT_INIT,
  EWALLET_PAYMENT_SUCCESS,
  EWALLET_PAYMENT_FAIL,
  VIRTUAL_ACCOUNT_PAYMENT_INIT,
  VIRTUAL_ACCOUNT_PAYMENT_SUCCESS,
  VIRTUAL_ACCOUNT_PAYMENT_FAIL
} from '../../../actions'
import apiCall from '../../../saga/api'
import { isMobilePhone } from '../../../utilities/suborderUtils'

export async function getPaymentsList () {
  const url = '/dsf/payments/methods?client=DSF'

  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_PAYMENTS_LIST_INIT,
      successType: FETCH_PAYMENTS_LIST_SUCCESS,
      failureType: FETCH_PAYMENTS_LIST_FAIL
    }
  }

  await apiCall(apiArgs)
}

export async function eWalletPayment ({ orderId, paymentChannel, mobile }) {
  const url = '/dsf/payments/process'

  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      client: 'DSF',
      orderId,
      paymentCategory: 'EWALLET',
      eWalletDetail: {
        isTokenizedPayment: false,
        currency: 'IDR',
        paymentChannel,
        ewalletMobileNumber: mobile
      }
    },
    url,
    TYPES: {
      requestType: EWALLET_PAYMENT_INIT,
      successType: EWALLET_PAYMENT_SUCCESS,
      failureType: EWALLET_PAYMENT_FAIL
    }
  }
  try {
    const { data } = await apiCall(apiArgs)
    const link = data?.data?.paymentDetails?.actions?.desktop_web_checkout_url

    if (paymentChannel === 'OVO_DSF') return
    if (paymentChannel === 'SHOPEEPAY_DSF' && !isMobilePhone()) return
    if (window.windowReference && link) {
      window.windowReference.location = link
    }
  } catch (e) {
    if (window.windowReference) {
      window.windowReference.close()
    }
  }
}

export async function virtualAccountPayment ({ orderId, paymentChannel }) {
  const url = '/dsf/payments/process'

  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      client: 'DSF',
      orderId,
      paymentCategory: 'VIRTUAL_ACCOUNT',
      bankPayDetail: {
        paymentChannel,
        payerName: 'LinkStore'
      }
    },
    url,
    TYPES: {
      requestType: VIRTUAL_ACCOUNT_PAYMENT_INIT,
      successType: VIRTUAL_ACCOUNT_PAYMENT_SUCCESS,
      failureType: VIRTUAL_ACCOUNT_PAYMENT_FAIL
    }
  }

  await apiCall(apiArgs)
}

export async function cancelPayment ({ payId, paymentCancelled }) {
  const url = `/dsf/payments/${payId}/cancel`

  const apiArgs = {
    API_CALL: {
      method: 'PATCH'
    },
    url,
    TYPES: {
      requestType: EWALLET_PAYMENT_INIT,
      successType: EWALLET_PAYMENT_FAIL,
      failureType: EWALLET_PAYMENT_FAIL
    }
  }

  const { status } = await apiCall(apiArgs)

  if (status === 200) {
    if (window.windowReference) {
      window.windowReference.close()
    }
    paymentCancelled()
  }
}
