import { commonTranslations } from '../translation/commonTranslations'

export const errorWrapper = (code, message) => {
  switch (code) {
    case 'ORDER_QUANTITY_UNSATISFIED':
      return commonTranslations.stockQuantity

    default:
      return message
  }
}
