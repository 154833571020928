import './CustomerRating.scss'
import ratingIcon from './assets/star.svg'

export default function CustomerRating ({ rating, reviewer }) {
  return (
    <div className='customer-rating'>
      <h3 className='pb-2'>Customer Ratings</h3>

      <div className='flex rating-wrapper'>
        <img src={ratingIcon} alt='rating' />
        <p className='ml-2'>{rating}/5</p>
        {reviewer && reviewer !== null && <h5 className='ml-2'>({reviewer} Ratings)</h5>}

      </div>
    </div>
  )
}
