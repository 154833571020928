import React from 'react'
import { Radio } from '@material-ui/core'
import './Switch.css'

export const Switch = ({ active, onClick, label2, name, date }) => {
  return (
    <div className='flex items-center justify-between'>
      <div onClick={onClick} className='flex items-center'>
        <Radio checked={active} size='small' color='primary' />
        <p
          className={`ml-0 font-bold text-[14px] text-[${
            active ? '#212121' : '#020202'
          }] font-[${active ? '700' : '400'}]`}
        >
          {name}
          <span
            className={`bg-[#F3EBEE] p-[2px] rounded text-[12px] ml-2 text-[${
              active ? '#212121' : '#020202'
            }] font-[${active ? '700' : '400'}]`}
          >
            {date}
          </span>
        </p>
      </div>
      {label2 && <div className='label-2 font-[600]'>{label2}</div>}
    </div>
  )
}
