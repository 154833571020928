import { FETCH_PRODUCT_FAIL, FETCH_PRODUCT_INIT, FETCH_PRODUCT_SUCCESS } from '../../../../actions/types'
import apiCall from '../../../../saga/api'

export function getProductDetail (data) {
  const { shareId = 'Foq7lo', sku = 'TBS001' } = data
  const url = `/dsf/products/${shareId}/${sku}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_PRODUCT_INIT,
      successType: FETCH_PRODUCT_SUCCESS,
      failureType: FETCH_PRODUCT_FAIL
    }
  }
  // Accept response if necessary with await
  apiCall(apiArgs)
}
