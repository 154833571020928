import './PaymentMethods.scss'
import bca from './assets/bca.svg'
import ris from './assets/ris.svg'
import bni from './assets/bni.svg'
import visa from './assets/visa.svg'
import { commonTranslations } from '../../../../../translation/commonTranslations'

export default function PaymentMethods () {
  const methods = [bca, ris, bni, visa]
  return (
    <div className='payment-methods'>
      <h3 className='pb-3'>{commonTranslations.paymentMethods}</h3>

      <div className='methods-wrapper'>
        {
            methods.map((img) => (
              <img key={img} src={img} alt='images' />
            ))
        }
      </div>
      <h5 className='op-60 pt-1'>+ 10 other payment methods</h5>

    </div>
  )
}
