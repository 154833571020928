import { ADD_CART, DELETE_CART, UPDATE_CART, SET_CART, SELECT_ALL_CART, SELECT_SPECIFIC_CART } from '../../../actions'
import store from '../../../store'
import EventEmitter, { Events } from '../../../utilities/eventEmitter'

export const addToCart = (product, dsf, quantity) => {
  const data = {
    ...product,
    quantity,
    price: dsf.finalPrice,
    slashedPrice: dsf?.retailPrice > dsf.finalPrice ? dsf?.retailPrice : 0,
    discount: (Math.abs((((dsf.finalPrice / dsf?.retailPrice) * 100) - 100)))?.toFixed(0),
    selected: true,
    ...dsf
  }
  store.dispatch({ type: ADD_CART, data })
  EventEmitter.emit(Events.ADD_TO_CART, {
    SKU_code: product.sku
  })
}

export const selectAllCart = (value) => {
  store.dispatch({ type: SELECT_ALL_CART, data: value })
}

export const selectSpecificProductCart = (data) => {
  store.dispatch({ type: SELECT_SPECIFIC_CART, data })
}

export const updateCart = (data) => {
  store.dispatch({ type: UPDATE_CART, data })
}

export const setCart = (data) => {
  store.dispatch({ type: SET_CART, data })
}

export const deleteCart = (data) => {
  store.dispatch({ type: DELETE_CART, data })
}
