import React from 'react'
import { Grid } from '@material-ui/core'

const Error = ({ error }) => {
  return (
    <Grid style={{ color: '#ff000099' }} item xs={12}>
      {error}
    </Grid>
  )
}

export default Error
