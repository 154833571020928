import './Topbar.scss'
import icon1 from '../../../../share-details/assets/logo.jpeg'
import cartIcon from './assets/cart.svg'
import { useSelector } from 'react-redux'
import LanguageBar from '../../../../../components/LanguageBar/LanguageBar'
import { useNavigateTo } from '../../../../../utilities/navigation'

export default function Topbar ({ user, openCartModal, image, onPressLogo }) {
  const { navigateTo } = useNavigateTo()
  const goToCart = () => navigateTo(`cart?resellerId=${user.id}`)
  const list = useSelector(state => state.cart.list)
  const storeName = user?.store || user?.name
  return (
    <div className='topbar px-2 py-2 flex justify-between'>
      <div onClick={onPressLogo} className='title-wrapper flex-1 cursor-pointer max-w-[max-content]'>
        <div className='icons-wrapper min-w-[30px]'>
          {
            image
              ? (
                <img src={image || icon1} alt='icons' className='rounded-full border-2' />
                )
              : (
                <div className='w-[30px] h-[30px] rounded-full bg-white flex items-center justify-center border-2'>
                  <p className='text-[10px] font-sans font-[600] text-gray-400 m-0 text-uppercase'>
                    {storeName?.split(' ').length > 1 ? `${storeName?.split(' ')[0]?.slice(0, 1)} ${storeName?.split(' ')[1]?.slice(0, 1)}` : storeName?.split('')[0]}
                  </p>
                </div>
                )
          }
          {/* <img src={icon2} alt='icons' /> */}
        </div>
        <h4>{storeName}</h4>
      </div>
      <div className='right-content ml-1'>
        <LanguageBar />
        <div onClick={goToCart} className='cart-wrapper cursor-pointer'>
          <img src={cartIcon} alt='cart' />
          {list.length > 0 && <p>{list.length}</p>}
        </div>
      </div>
    </div>
  )
}
