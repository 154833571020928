import { Box, Grid, Typography } from '@material-ui/core'
import {
  orderDetailTranslations,
  orderTranslations
} from '../../../translation/commonTranslations'
import { numberWithCommas } from '../../../utilities/currency'

export const Amount = ({ item }) => {
  return (
    <Box className='bg-white mb-[10px]'>
      <Box className='px-[10px]'>
        <Grid container direction='row' justify='space-between'>
          <Grid item>
            <Box className='text-normal'>
              {orderDetailTranslations.itemCost}
            </Box>
          </Grid>
          <Grid item>
            <Box className='text-normal'>{numberWithCommas(item.reselerPrice)}</Box>
          </Grid>
        </Grid>
        <Grid
          style={{ margin: '10px 0px' }}
          container
          direction='row'
          justify='space-between'
        >
          <Grid item>
            <Box className='text-normal'>{orderTranslations.shippingFee}</Box>
          </Grid>
          <Grid item>
            <Box className='text-normal'><p className='text-[#0EC690] text-bold'>{item.shippingFee === 0 ? 'Free' : numberWithCommas(item.shippingFee)}</p></Box>
          </Grid>
        </Grid>
      </Box>
      <Box className='px-[10px] mt-2'>
        <Grid container direction='row' justify='space-between'>
          <Grid>
            <Typography variant='subtitle1'>
              {orderTranslations.total}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='subtitle1'>
              Rp {numberWithCommas(item.totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
