import React from 'react'

import './QuantitySelector.scss'

const QuantitySelector = (props) => {
  const { onChangeQuantity, disabledMinusButton, disabledPlusButton, disableSelector, name, id, value, onChangeQuantityValue } = props
  const onBlur = () => {
    if (value === '' || isNaN(value)) {
      onChangeQuantityValue(1)
    }
  }
  return (
    <div className='quantity-selector'>
      <span>Qty:</span>
      <button
        type='button'
        disabled={disabledMinusButton}
        className={`button ${(disabledMinusButton || disableSelector) && 'disabled'}`}
        onClick={() => onChangeQuantity('decrement')}
      >
        -
      </button>
      <input
        name={name}
        type='number'
        id={id}
        width='40px'
        className='input'
        value={value}
        onChange={e => onChangeQuantityValue(e.target.value)}
        onBlur={onBlur}
      />
      <button
        type='button'
        className={`button ${(disabledPlusButton || disableSelector) && 'disabled'}`}
        onClick={() => onChangeQuantity({}, 'increment')}
        disabled={disabledPlusButton}
      >
        +
      </button>
    </div>
  )
}

export default QuantitySelector
