import {
  FETCH_SUBORDER_INIT, FETCH_SUBORDER_SUCCESS, FETCH_SUBORDER_FAIL, CLEAR_FIELDS,
  CREATE_ORDER_INIT,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  SET_CART,
  CREATE_ORDER_PAYMENT_SUCCESS,
  PAYMENT_FAILED_BY_USER,
  INITIATE_PAYMENTS
} from '../../../actions'
import apiCall from '../../../saga/api'
import store from '../../../store'
import EventEmitter, { Events } from '../../../utilities/eventEmitter'

export function clearFields () {
  store.dispatch({ type: CLEAR_FIELDS })
}

export async function getSubOrders (items, payload) {
  const url = '/dsf/orders/generate-sub-orders'
  const {
    areaId,
    lat,
    long,
    province,
    resellerId,
    shippingTo,
    stockType,
    suburbId,
    tier,
    orderAmount
  } = payload

  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      destination: {
        province,
        area_id: areaId,
        suburb_id: suburbId,
        lat,
        long
      },
      items,
      resellerId,
      tier,
      orderType: 'REGULAR',
      stockType,
      shippingTo,
      orderAmount,
      province
    },
    url,
    TYPES: {
      requestType: FETCH_SUBORDER_INIT,
      successType: FETCH_SUBORDER_SUCCESS,
      failureType: FETCH_SUBORDER_FAIL
    }
  }

  await apiCall(apiArgs)
}

export async function createOrder (payload, paymentCallback, openSuccessModal, history, location, onCancelPayment) {
  const url = '/dsf/orders/create'
  const {
    orderType = 'reseller_dsf',
    currency = 'IDR',
    resellerId,
    shippingTo = 'Customer',
    stockType,
    shippingCity,
    shippingProvince,
    shippingPincode,
    couponCode = null,
    marketPlaceDocKey = null,
    items,
    salesOrderType = 'REGULAR',
    shipments,
    isBankTransfer = false,
    paymentDoc = null,
    appliedShippingAmount,
    shippingRateId,
    shippingPartnerType,
    minDay,
    maxDay,
    city,
    name,
    phone,
    pincode,
    district,
    province,
    subDistrict,
    addressLine1,
    addressLine2,
    email
  } = payload

  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      orderType,
      currency,
      resellerId,
      shippingTo,
      customerInfo: {
        city,
        name,
        phone,
        pincode,
        district,
        province,
        subDistrict,
        addressLine1,
        addressLine2,
        email
      },
      stockType,
      shippingAddressLine1: addressLine1,
      shippingAddressLine2: addressLine2,
      shippingCity,
      shippingProvince,
      shippingPincode,
      couponCode,
      marketPlaceDocKey,
      items,
      salesOrderType,
      shipments,
      isBankTransfer,
      paymentDoc,
      appliedShippingAmount,
      shippingRateId,
      shippingPartnerType,
      minDay,
      maxDay
    },
    url,
    TYPES: {
      requestType: CREATE_ORDER_INIT,
      successType: CREATE_ORDER_SUCCESS,
      failureType: CREATE_ORDER_FAIL
    }
  }

  try {
    const { data, status } = await apiCall(apiArgs)
    if (status === 200) {
      EventEmitter.emit(Events.FINISH_CHECKOUT)
      paymentStatusFetch(data?.data?.id, openSuccessModal, history, location, onCancelPayment)
      paymentCallback(data?.data?.id, data?.data?.resellerId)
    }
  } catch (e) {
    setTimeout(() => {
      if (window.windowReference) {
        window.windowReference.close()
      }
    }, 800)
  }
}

export async function paymentInit (payload, closeOrderModal, history, location) {
  const url = '/dsf/orders/payment-init'
  const {
    orderId,
    resellerId
  } = payload

  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      orderId,
      resellerId
    },
    url,
    TYPES: {
      requestType: CREATE_ORDER_INIT,
      successType: CREATE_ORDER_PAYMENT_SUCCESS,
      failureType: CREATE_ORDER_FAIL
    }
  }

  EventEmitter.emit(Events.USER_PAY, { orderId })

  const { data, status } = await apiCall(apiArgs)

  if (status === 200) {
    closeOrderModal(data?.data?.paymentUrl)
  }
}

export async function paymentStatusFetch (orderId, openSuccessModal, history, location, onCancelPayment) {
  const url = `/dsf/orders/${orderId}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: '',
      successType: '',
      failureType: ''
    }
  }

  const timeOut = setInterval(async () => {
    const { data, status } = await apiCall(apiArgs)
    if (status === 200) {
      if (!store.getState()?.subOrder.createOrder.data?.id) {
        clearInterval(timeOut)
      }
      if (data.data.paymentStatus === 'Paid') {
        if (window.windowReference) {
          window.windowReference.close()
        }
        store.dispatch({ type: SET_CART, data: [] })
        clearInterval(timeOut)
        openSuccessModal(orderId)
        EventEmitter.emit(Events.USER_PAY_SUCCESSFULL, { orderId })
        history.push(`${location.pathname}`)
        return
      }
      if (data.data.paymentStatus === 'Cancelled') {
        if (window.windowReference) {
          window.windowReference.close()
        }
        clearInterval(timeOut)
        onCancelPayment()
        return
      }
      if (data.data.paymentStatus === 'Failed' || data.data.paymentStatus === 'Expired') {
        if (window.windowReference) {
          window.windowReference.close()
        }
        clearInterval(timeOut)
        store.dispatch({ type: PAYMENT_FAILED_BY_USER })
        EventEmitter.emit(Events.USER_PAY_FAIL, { orderId })
      }
      if (data.data.paymentStatus === 'Initiated') {
        store.dispatch({ type: INITIATE_PAYMENTS })
      }
    }
  }, 3000)
}
