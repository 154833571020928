import React from 'react'
import './Input.css'

const Input = (props) => {
  const { bgColor, border, fontSize, placeholder, brRadius, height } = props
  const styleSheet = {
    backgroundColor: bgColor || 'white',
    border: border || '1px solid gray',
    fontSize: fontSize || '',
    borderRadius: brRadius || '',
    height: height || 'initial'
  }
  return (
    <div style={styleSheet} className='input-wrapper'>
      <input {...props.input} placeholder={placeholder || ''} />
    </div>
  )
}
export default Input
