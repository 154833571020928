import { VideoCard } from '../../../../../components/cards/video-card/VideoCard'
import './ProductVideos.scss'
// import { VideoCard } from '../cards/video-card/VideoCard'

export default function ProductVideos ({ videos }) {
  return (

    <div className='product-videos'>
      <h3 className='pb-2'>Product Videos</h3>
      <div className='flex items-center flex-row overflow-auto'>
        {
          videos?.map((vi) => (
            <div key={vi.id} className='mr-4 w-32 '>
              <VideoCard videoUrl={vi.url} styleSheet={{ aspectRatio: '1/1.4' }} />
            </div>
          ))
        }
      </div>
      {/* <div className='videos-wrapper'>
        {
          videos?.map((vi) => (
            <div
              key={vi.id}
              className='single-video'
            >
              <VideoCard  videoUrl={vi.url} />
            </div>
          ))
        }
      </div> */}
    </div>
  )
}
