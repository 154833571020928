import React from 'react'
import ModalDailog from '../ModalDailog/ModalDailog'
import OrderPlace from '../OrderPlace/OrderPlace'

export default function SuccessModal ({ open, handleClose, orderId }) {
  return (
    <ModalDailog open={open} handleClose={handleClose}>
      <OrderPlace handleClose={handleClose} orderId={orderId} />
    </ModalDailog>
  )
}
