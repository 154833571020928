/* eslint-disable no-undef */
import { CheckoutForm as Checkout } from './CheckoutForm'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import validate from './validate'

const mapStateToProps = ({ form }) => ({
  form: form
})

const CheckoutWrapper = reduxForm({
  form: 'checkoutForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  validate
})(connect(mapStateToProps)(Checkout))

export default CheckoutWrapper
