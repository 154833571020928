import { useState } from 'react'
import { commonTranslations } from '../../../../translation/commonTranslations'
import './RenderTab.scss'

export default function RenderTab ({ product }) {
  const tabLabels = ['description', 'ingredients', 'howToUse']
  const tabList = []
  tabLabels.forEach((tb) => {
    if (product[tb]) {
      tabList.push({ label: tb, body: product[tb] })
    }
  })
  const [activeTab, setActiveTab] = useState(tabList[0])

  return (
    <div className='render-tab'>
      <div className='labels-wrapper'>
        {tabList?.map((tb) => (
          <h4 onClick={() => setActiveTab(tb)} className={'label capitalize ' + (tb.label === activeTab.label && 'active-class')} key={tb.label}>{tb.label === 'howToUse' ? commonTranslations.howToUse : tb.label}</h4>
        ))}
      </div>
      <div className='tab-body'>
        <h4 className='op-60 p-2'>{activeTab?.body}</h4>
      </div>
    </div>
  )
}
