import axios from 'axios'
import { NOTIFIER_SHOW } from '../actions'
import { store } from '../store'
import { errorWrapper } from '../utilities/errorWrapper'

export default async function apiCall (payload) {
  const {
    API_CALL,
    data,
    TYPES,
    url,
    params,
    external = false,
    apiVersion = null
  } = payload

  const API_VERSION = 'v1'
  const apiEndpoint = process.env.REACT_APP_API_HOST

  const currentApiVersion = apiVersion || API_VERSION
  const API_URL = external ? url : `${apiEndpoint}/${currentApiVersion}${url}`

  // Setting API parameters
  const apiParams = {
    ...API_CALL,
    data,
    params,
    url: API_URL,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
    }
  }

  try {
    //  Setting initial state
    if (TYPES.requestType) {
      store.dispatch({ type: TYPES.requestType })
    }
    // Make API call
    const apiResponse = await axios(apiParams)
    if ((apiResponse.status === 200 || apiResponse.status === 201) && apiResponse.data) {
      if (apiResponse && apiResponse.data && apiResponse.data.errors && apiResponse.data.errors.length > 0 && apiResponse.data.errors[0].status === 403) {
        // alert('failing')
        if (TYPES.failureType) {
          store.dispatch({ type: TYPES.failureType })
        }
      } else {
        // alert('passing')
        // API call success
        if (TYPES.successType) store.dispatch({ type: TYPES.successType, data: apiResponse.data })
        return apiResponse
      }
    }
  } catch (err) {
    if (TYPES.failureType) {
      store.dispatch({ type: TYPES.failureType })
    }
    // API call failure
    let errMessage = err.message
    if (err.response && err.response.data) {
      errMessage = err.response.data.message || (err.response.data.error && err.response.data.error.message)
    }
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: errorWrapper(err?.response?.data?.error?.errorCode || '', errMessage), type: 'fail' } })
  }
  return null
}
