import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ErrorPage from './components/ErrorPage/errorBoundary'
import PaymentLoading from './components/PaymentLoading/PaymentLoading'
import Cart from './containers/CartPage/components/Cart'
import Checkout from './containers/Checkout'
import StoreHome from './containers/dsf-store-view/StoreHome'
import Home from './containers/DSFPDP/home/Home'
import OrderDetail from './containers/OrderDetail/OrderDetail'
import { PaymentPage } from './containers/PaymentPage/PaymentPage'
import TopSellingProducts from './containers/top-selling-products/TopSellingProducts'

export default (
  <Router>
    <Route>
      <Switch>
        <Route exact path='/:shareId/all-products/top-sell' component={TopSellingProducts} />
        <Route exact path='/:shareId/:sku' component={Home} />
        <Route exact path='/:shareId/:sku/cart' component={Cart} />
        <Route exact path='/:shareId/:sku/checkout/:productId' component={Checkout} />
        <Route exact path='/:shareId/:sku/checkout' component={Checkout} />
        <Route exact path='/:shareId/:sku/sub-order' component={OrderDetail} />
        <Route exact path='/:shareId/:sku/payments' component={PaymentPage} />
        <Route exact path='/:shareId/' component={StoreHome} />
        <Route exact path='/payment/process/loading' component={PaymentLoading} />
        <Route exact component={ErrorPage} />
      </Switch>
    </Route>
  </Router>
)
