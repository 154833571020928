import { Box, Grid, Modal, Typography } from '@material-ui/core'
import { Switch } from '../../../components/Switch/Switch'
import placeHolderImage from '../../../assets/drawer_backdrop.png'
import { getShippingDateRange } from '../../../utilities/suborderUtils'
import './styles.scss'
import { commonTranslations, orderTranslations } from '../../../translation/commonTranslations'
import { themeColor } from '../../../utilities/colors'
import { useState, useEffect } from 'react'
import QuantitySelector from '../../../components/quantity-selector/quantitySelector'
import Button from '../../../components/Button'
import EventEmitter, { Events } from '../../../utilities/eventEmitter'
import { numberWithCommas } from '../../../utilities/currency'

const videoRegex = /^https?:\/\/(.+\/)+.+(\.(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm))$/i
export const Shipment = ({ item, eta, setEta, index, fetchSubOrders }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [quantity, setQuantity] = useState(0)
  const {
    items: shipmentItems = [],
    shippingFeeInfo: {
      shippingPartners = []
    } = {}
  } = item

  const shippingRadios = shippingPartners.map((shippingPartner, id) => {
    const startDate = new Date() // Update for Pre Orders
    const {
      etaMax: maximumDays,
      etaMin: minimumDays,
      rateType: key,
      slashedShippingAmount: shippingFee // Todo: Check when to show 'Free'
    } = shippingPartner

    const text = getShippingDateRange({
      startDate,
      minimumDays,
      maximumDays
    })

    return ({
      date: text,
      key,
      shippingFee,
      id
    })
  })

  const changeEta = (id) => {
    const selected = [...eta]
    selected[index] = id
    setEta(selected)
  }

  const closeModal = () => {
    setOpenModal(false)
    setSelectedItem({})
  }

  const onChangeQuantityValue = (inputedValue) => {
    const intValue = parseInt(inputedValue)
    if (intValue <= 0) {
      setQuantity(1)
      return
    }
    if (intValue >= selectedItem.stockLimit) {
      setQuantity(selectedItem.stockLimit === 0 ? 1 : selectedItem.stockLimit)
      return
    }
    setQuantity(intValue)
  }

  const onChangeQuantity = (action) => {
    if (quantity === 1 && action === 'decrement') return
    if (quantity >= selectedItem.stockLimit && action !== 'decrement') return

    setQuantity(action === 'decrement' ? quantity - 1 : quantity + 1)
  }

  useEffect(() => {
    EventEmitter.emit(Events.SUMMERY_PAGE)
  }, [])
  return (
    <Box className='relative mt-2 bg-white p-[10px] '>
      <Modal
        open={openModal}
        onClose={closeModal}
        onBackdropClick={closeModal}
        className='flex flex-1 items-center justify-center'
        style={{ height: window.innerHeight }}
      >
        <center>
          <div className='mx-[10px] p-[15px] bg-white rounded py-[20px] flex flex-col flex-1'>
            <Grid spacing={1} container direction='row'>
              <img src={selectedItem?.productImage || placeHolderImage} className='orderImage mr-2 ml-1 rounded' alt='images' />
              <div className='flex flex-1 flex-col h-[70px] justify-between pl-2'>
                <Typography className='m-0 ellipsed-text mt-0 text-[14px] text-[#00000080] text-left' style={{ lineHeight: 1.2 }} paragraph>
                  {selectedItem?.productTitle}
                </Typography>
                <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                  <Grid item>
                    <p className='font-[700] text-[14px]'>
                      Rp {numberWithCommas(selectedItem?.effectiveWholesalePrice)}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <div className='flex items-center justify-center mt-4 w-full'>
              <center><QuantitySelector onChangeQuantityValue={onChangeQuantityValue} onChangeQuantity={onChangeQuantity} name='quantity' id='quantity' value={quantity} /></center>
            </div>
            <div className='w-full mt-4 bg-red'>
              <Button
                label={commonTranslations.confirm}
                btnColor='white'
                bgColor={themeColor}
                btnWidth='100%'
                btnHeight='40px'
                type='submit'
                fontSize='16px'
                borderRadius='6px'
                onClick={() => fetchSubOrders(quantity, selectedItem.productId)}
              />
            </div>
          </div>
        </center>
      </Modal>
      <Typography variant='subtitle1'>{orderTranslations.shipment} {index + 1}</Typography>
      {shipmentItems.length > 0 && shipmentItems.map((shipmentItem, i) => {
        const {
          productImage,
          productTitle,
          quantity = 0,
          effectiveWholesalePrice
        } = shipmentItem
        return (
          <Grid key={i} spacing={1} container direction='row' className={i === 0 ? 'mt-2' : 'mt-4'}>
            {videoRegex.test(productImage)
              ? (
                <video playsInline loop className='orderImage mr-2 ml-1 rounded' autoPlay muted>
                  <source src={productImage} type='video/mp4' />
                </video>
                )
              : <img src={productImage || placeHolderImage} className='orderImage mr-2 ml-1 rounded' alt='images' />}
            <div className='flex flex-1 flex-col h-[70px] justify-between pl-2'>
              <Typography className='m-0 ellipsed-text mt-0 text-[14px] text-[#00000080]' style={{ lineHeight: 1.2 }} paragraph>
                {productTitle}
              </Typography>
              <Grid container direction='row' justify='space-between' alignItems='flex-end'>
                <Grid item>
                  <p className='font-[700] text-[16px]'>
                    Rp {numberWithCommas(effectiveWholesalePrice)}
                  </p>
                </Grid>
                <div className='flex flex-row items-center justify-center'>
                  <p className='text-[#212121] text-[14px] mr-2'>QTY: {quantity}</p>
                  <p
                    onClick={() => {
                      setOpenModal(true)
                      setSelectedItem(shipmentItem)
                      setQuantity(quantity)
                    }} className={`text-[${themeColor}] text-[14px] ml-4 mr-4 font-bold cursor-pointer hidden`}
                  >Edit
                  </p>
                </div>
              </Grid>
            </div>
          </Grid>
        )
      })}
      <div className='bg-white mt-3'>
        {shippingRadios?.length > 1 &&
          <Typography variant='subtitle1'>
            {commonTranslations.shippingOptions}
          </Typography>}
        {shippingRadios?.length > 1
          ? (
            <div className='w-full border border-[#DCE1EF] rounded mt-2'>
              {shippingRadios?.map((sw) => (
                <div onClick={() => changeEta(sw.id)} key={sw.id} className='p-[5px] border-bottom border-[#DCE1EF]'>
                  <Switch
                    onClick={() => changeEta(sw.id)}
                    active={eta.length === 0 && sw.id === 0 ? true : eta[index] ? eta[index] === sw.id : sw.id === 0 ? true : false || false}
                    date={sw.date}
                    name={sw.key === 'Trucking' ? 'Economical' : sw.key}
                    label2={sw.shippingFee > -1 ? sw.shippingFee > 0 ? `Rp ${numberWithCommas(sw.shippingFee)}` : 'FREE' : false}
                  />
                </div>
              ))}
            </div>
            )
          : <p className='text-[14px] font-[600] text-[#020202]'>{commonTranslations.delivery}: <span className='text-[12px] text-[#4A4A4A]'>{shippingRadios?.[0].date}</span></p>}
      </div>
    </Box>
  )
}
