/* eslint-disable import/prefer-default-export */
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/id'

import idMessages from './locales/id.json'
import enMessages from './locales/en.json'

const localeMessages = { id: idMessages, en: enMessages }

export function getLocaleMessages (locale) {
  return localeMessages[locale]
}
