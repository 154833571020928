import { noop } from 'lodash'
import React from 'react'
import { commonTranslations } from '../../translation/commonTranslations'
import { themeColor } from '../../utilities/colors'
import { numberWithCommas } from '../../utilities/currency'
import Button from '../Button'
import './styles.scss'

export const PaymentsStickyBottombar = ({
  isLoading,
  label,
  disabled,
  onClick,
  paymentAmount
}) => {
  return (
    <div className='w-full bg-white px-[16px] py-[10px] flex items-center gap-[10px] paymentBottomStickyBar'>
      <div className='flex flex-1 flex-col'>
        <p className='text-[12px] m-0'>{commonTranslations.amountToPay}</p>
        <p className={`text-[16px] text-[${themeColor}] m-0 font-[600]`}>IDR {numberWithCommas(paymentAmount)}</p>
      </div>
      <Button
        isLoading={isLoading}
        label={label}
        btnColor='white'
        bgColor={themeColor}
        btnWidth='48%'
        btnHeight='40px'
        btnBorder={`1px solid ${themeColor}`}
        fontSize='16px'
        borderRadius='6px'
        onClick={disabled ? noop : onClick}
        disabled={disabled}
        opacity={disabled ? 0.5 : 1}
      />
    </div>
  )
}
