import { numberWithCommas } from '../../../../../../utilities/currency'
import { makeCamel } from '../../../../../../utilities/makeCamelCase'
import './MoreCard.scss'

export const MoreCard = (props) => {
  const { className, product, onClick = () => null } = props

  return (
    <div onClick={() => onClick(product.product?.sku)} className={'more-card ' + (className || '')}>
      <div className='img-wrapper'>
        {
          product.product?.thumbnailImages !== null &&
            <img src={product.product?.thumbnailImages?.[0]?.url} alt='img' />
        }
      </div>
      <p className='op-50 capitalize'>{makeCamel(product.product?.name)}</p>
      <ul className='price-wrapper'>
        <h4>Rp {numberWithCommas(product.dsf?.finalPrice?.toFixed(0))}</h4>
        {product.product?.retailPrice > product.dsf?.finalPrice && <h4>{(Math.abs((((product.dsf.finalPrice / product.product?.retailPrice) * 100) - 100)))?.toFixed(0)}% Off</h4>}
      </ul>
      {product.product?.retailPrice > product.dsf.finalPrice && <h5 className='op-50 py-2'> Rp {numberWithCommas(product.product?.retailPrice)}</h5>}
    </div>
  )
}
