import { Grid, InputAdornment, TextField } from '@material-ui/core'
import Label from '../../../../components/Label/Label'
import Input from '../../../../components/Input/Input'
import './GroupField.css'
import Error from '../../../../components/error/error'
import Search from '@material-ui/icons/Search'
import Arrow from '@material-ui/icons/ArrowForwardIos'

export default function GroupField (props) {
  const { label, required, isLocation, bgColor, fontSize, placeholder, brRadius, height, onClick } = props
  const { error, submitFailed } = props.meta

  const styleSheet = {
    backgroundColor: bgColor || 'white',
    fontSize: fontSize || '',
    borderRadius: brRadius || '',
    height: height || 'initial'
  }

  return (
    <>
      <Grid container spacing={1} item direction='column'>
        {label && (
          <Grid item>
            <Label title={label} required={!!required || false} />
          </Grid>
        )}

        <Grid item>
          {isLocation
            ? <TextField
                variant='outlined'
                InputProps={{
                  startAdornment: <InputAdornment position='start'><Search style={{ width: 20, height: 20, margin: 0, padding: 0, color: 'gray' }} /></InputAdornment>,
                  endAdornment: <InputAdornment position='end'><Arrow style={{ width: 20, height: 20, margin: 0, color: 'gray' }} /></InputAdornment>,
                  style: { height: 45, color: 'gray' }
                }}
                fullWidth
                placeholder={placeholder}
                style={styleSheet}
                disabled
                onClick={onClick}
                value=''
              />
            : <Input {...props} />}
        </Grid>
      </Grid>
      {submitFailed && error && <Error error={error} />}
    </>
  )
}
