import {
  Box,
  createMuiTheme,
  ThemeProvider,
  Typography
} from '@material-ui/core'
import './styles.scss'
import { ChatWithSeller } from '../ChatWithSeller/ChatWithSeller'
import markBox from './icons/markIBox.svg'
import copyIcon from './icons/copyIcon.svg'
import whatsapp from '../../containers/DSFPDP/home/components/topbar/assets/whats.svg'
import {
  commonTranslations,
  orderDetailTranslations
} from '../../translation/commonTranslations'
import { useSelector } from 'react-redux'
import { store } from '../../store'
import { NOTIFIER_SHOW } from '../../actions'
import { Close } from '@material-ui/icons'
import '../../containers/Checkout/style.scss'

const OrderPlace = ({ orderId, handleClose }) => {
  const user = useSelector(state => state.product.product.user)
  const theme = createMuiTheme({
    typography: {
      subtitle2: {
        color: '#8559FF',
        fontWeight: 600,
        fontSize: '12px'
      }
    }
  })

  const onCopyOrderId = () => {
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: commonTranslations.copyToClipboard, type: 'success' } })
    navigator.clipboard.writeText(orderId)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className='form-wrapper order-place'>
        <div className='w-full pt-4'>
          <Close onClick={handleClose} fontSize='large' style={{ cursor: 'pointer' }} />
        </div>
        <div className='flex flex-col flex-1 items-center justify-center'>
          <Box>
            <img src={markBox} alt='' />
          </Box>
          <Typography align='center' style={{ margin: '10px 0px' }}>
            {orderDetailTranslations.success}
          </Typography>
          <Box className='orderId-wrapper'>
            <Typography align='center' variant='subtitle2'>
              {commonTranslations.id}:
            </Typography>
            <Typography noWrap variant='subtitle2' className='flex'>
              <span style={{ margin: '0px 6px' }}>{orderId}</span>
              <span onClick={onCopyOrderId} className='order-id-copy'>
                <img src={copyIcon} alt='' />
              </span>
            </Typography>
          </Box>
          <Typography
            paragraph
            align='center'
            style={{ fontSize: '13px', maxWidth: '216px' }}
          >
            {orderDetailTranslations.query}
          </Typography>

          <Box className='chat-with-seller-wrapper'>
            <ChatWithSeller
              icon={whatsapp}
              title={commonTranslations.chatWithSeller}
              user={user}
            />
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default OrderPlace
