import { intlReducer } from 'react-intl-redux'
import { languages } from './languages'

const defaultState = {
  locale: 'en',
  messages: {},
  languages: [...languages]
}

const reducer = (state = defaultState, action) => intlReducer(state, action)
export default reducer
