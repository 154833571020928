import './SearchBar.scss'
import SearchIcon from '@material-ui/icons/Search'
import { Clear } from '@material-ui/icons'
import { useRef } from 'react'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { useSelector } from 'react-redux'

function SearchBar ({ styleSheet, placeholder, onSearch, iconColor, clearIcon }) {
  const inputRef = useRef(null)
  const { seller } = useSelector((state) => state)

  const clearSearchInput = () => {
    if (inputRef.current.value.length > 0) {
      inputRef.current.value = ''
      onSearch('')
    }
  }

  const handleFocus = () => {
    EventEmitter.emit(Events.CLICK_SEARCH, {
      seller_id: seller.seller?.resellerId || ''
    })
  }
  return (
    <div className='search-bar' style={styleSheet && styleSheet}>
      <SearchIcon fontSize='small' style={{ marginTop: '3px', color: iconColor || 'inherit' }} />
      <input onFocus={handleFocus} ref={inputRef} onChange={(e) => onSearch(e.target.value)} type='text' placeholder={placeholder && placeholder} />
      {
        clearIcon &&
          <div>
            <Clear className='cursor-pointer' onClick={clearSearchInput} />
          </div>
      }
    </div>
  )
}

export default SearchBar
