import React from 'react'
import { Grid, Paper, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { commonTranslations } from '../../translation/commonTranslations'
import './styles.scss'
import image from '../../assets/internalError.svg'

const customStyles = () => ({
  wrapper: {
    boxSizing: 'border-box',
    margin: '60px',
    '@media (max-width: 800px)': {
      margin: '60px 0'
    }
  },
  contentWrapper: {
    padding: '100px',
    '@media (max-width: 600px)': {
      padding: 0
    }
  }
})

class ErrorBoundary extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <Grid container>
        <Grid item xs={12} classes={{ root: classes.wrapper }}>
          <Paper className='listingPageWrapper'>
            <Grid container justify='center' direction='column' classes={{ root: classes.contentWrapper }}>
              <img src={image} alt='internal_error' className='image' />
              <Grid item className='textWrapper'>
                {commonTranslations.invalidLinkMessage}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(withStyles(customStyles, { withTheme: true })(ErrorBoundary))
