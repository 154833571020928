import React from 'react'
import Loading from '../Loading'
import { commonTranslations } from '../../translation/commonTranslations'

export default function PaymentLoading () {
  return (
    <div style={{ height: window.innerHeight }} className='flex items-center justify-center flex-col'>
      <Loading />
      <p className='mt-[1rem] text-[16px]'>{commonTranslations.pleaseWait}</p>
    </div>
  )
}
