/* eslint-disable */
import { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"

export const useNavigateTo = () => {
    const history =  useHistory()
    const location =  useLocation()
    const { shareId, sku } = useParams()
    const navigateTo = (path) => history.push(`/${shareId}/${sku}/${path}`)
    return { navigateTo }
}

export const useFetchProduct = () => {
    const [user, setUser] = useState({})
    const [product, setProduct] = useState({})
    const [dsf, setDsf] = useState({})
    const [quantity, setQunatity] = useState(0)
    const [fromCart, setfromCart] = useState(false)
    const pdp = window.localStorage.getItem('pdpProduct')
    const isCartItem = window.localStorage.getItem('from_cart') || false

    useEffect(() => {
      setfromCart(isCartItem === 'false' ? false : true)
      if (!pdp) return
      const product = JSON.parse(pdp)

      setUser(product?.user)
      setProduct(product?.product)
      setDsf(product?.dsf)
      setQunatity(parseInt(product?.quantity))
    }, [pdp, isCartItem])

    return {
        user,
        product,
        dsf,
        quantity,
        fromCart
    }
}

export const useFetchShipments = () => {
    const [shipments, setShipments] = useState([])
    const [eta, setEta] = useState([])
    const pdp = window.localStorage.getItem('pdp_shipments')

    useEffect(() => {
      if (!pdp) return
      const product = JSON.parse(pdp)

      setShipments(product?.shipments)
      setEta(product?.eta)
    }, [pdp])

    return {
        shipments,
        eta
    }
}

export const useFetchCustomer = () => {
    const [customer, setUser] = useState({})
    const pdp = window.localStorage.getItem('pdp_user_details')
    const user_details_obj = window.localStorage.getItem('user_details')

    useEffect(() => {
      if (!pdp) return
      if (!user_details_obj) return
      const user = JSON.parse(pdp)
      const user_details = JSON.parse(user_details_obj)

      setUser({ ...user, fullName: user_details?.fullName, contactNumber: user_details?.contactNumber, email: user_details?.email, street: user_details?.street, house: user_details?.house, pincode: user_details?.pincode })
    }, [pdp])

    return {
        customer
    }
}