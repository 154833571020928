import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Loading from '../../components/Loading'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { useSelector } from 'react-redux'
import { clearFields } from '../OrderDetail/saga/saga'
import MetaCreator from '../../components/MetaCreator/MetaCreator'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { themeColor } from '../../utilities/colors'
import { addToCart } from '../CartPage/saga/saga'
import ProductDetails from '../DSFPDP/home/components/details/Details'
import { commonTranslations } from '../../translation/commonTranslations'
import { BottomStickyButton } from '../../components/BottomStickyButton/BottomStickyButton'
import Topbar from '../DSFPDP/home/components/topbar/Topbar'
import store from '../../store'
import { NOTIFIER_SHOW } from '../../actions'
import { useHistory } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { Chatbar } from '../DSFPDP/components/chatbar/Chatbar'
import { useNavigateTo } from '../../utilities/navigation'

const Details = ({ isLoading, product, user, dsf, intl }) => {
  const { navigateTo } = useNavigateTo()
  const history = useHistory()
  const { list: cartList } = useSelector((state) => state.cart)
  const [quantity, setQuantity] = useState(1)
  const { formatMessage } = intl

  const onChangeQuantity = (action) => {
    if (quantity === 1 && action === 'decrement') return
    if (quantity >= (product.stockLimit - product.reserveStock) && action !== 'decrement') return

    EventEmitter.emit(Events.INCREASE_QUANTITY, {
      product_id: product.id,
      quantity: action === 'decrement' ? quantity - 1 : quantity + 1
    })

    setQuantity(action === 'decrement' ? quantity - 1 : quantity + 1)
  }

  const setInputValue = (inputedValue) => {
    const intValue = parseInt(inputedValue)
    if (intValue <= 0) {
      setQuantity(1)
      return
    }
    if (intValue >= (product.stockLimit - product.reserveStock)) {
      setQuantity((product.stockLimit - product.reserveStock) === 0 ? 1 : (product.stockLimit - product.reserveStock))
      return
    }
    setQuantity(intValue)
  }

  useEffect(() => {
    if (!product || !user) return
    EventEmitter.emit(Events.PRODUCT_DETAILS, {
      sku_id: product.sku,
      seller_id: user.id
    })
    window.localStorage.setItem('pdpProduct', JSON.stringify({
      product,
      dsf,
      user,
      quantity
    }))
  }, [product])

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: themeColor
      }
    }
  })

  const onAddToCart = () => {
    if (cartList?.find(item => item.id === product?.id)) {
      store.dispatch({ type: NOTIFIER_SHOW, notification: { message: formatMessage(commonTranslations.alreadyInCart.props), type: 'fail' } })
      return
    }
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: formatMessage(commonTranslations.addedInCart.props), type: 'success' } })
    addToCart(product, dsf, quantity)
  }

  const onBuyNow = () => {
    navigateTo('checkout')
    window.localStorage.setItem('pdpProduct', JSON.stringify({
      product,
      dsf,
      user,
      quantity
    }))
    window.localStorage.setItem('from_cart', false)
    EventEmitter.emit(Events.BUY_NOW, {
      SKU_code: product.sku,
      type: 'buy_now'
    })
    EventEmitter.emit(Events.LOAD_PERSONAL_DETAILS, {
      type: 'buy_now'
    })
  }

  const onGoToStoreView = () => {
    clearFields()
    history.push(`/${user?.shareId}`)
    EventEmitter.emit(Events.REDIRECT_TO_STORE)
  }

  const isProductOutOfStock = (product.stockLimit - product.reserveStock) <= 0
  const isProductDelisted = product?.isDelisted
  const hideCheckout = isProductDelisted ? true : isProductOutOfStock || false
  return (
    <ThemeProvider theme={theme}>
      <div className='topbar-container form-wrapper'>
        <Topbar onPressLogo={onGoToStoreView} image={user?.imageUrl} user={user} />
      </div>
      <div className='form-wrapper'>
        <MetaCreator name={product?.name} description={product?.name} imageUrl={product.productImages?.[0]?.url} />
        {isLoading
          ? (
            <div style={{ height: window.innerHeight }} className='flex items-center justify-center bg-red-700'>
              <Loading />
            </div>
            )
          : (
            <ProductDetails
              quantity={quantity}
              onChangeQuantity={onChangeQuantity}
              product={product}
              user={user}
              onChangeQuantityValue={setInputValue}
            />
            )}
        <Helmet>
          <title>{user?.store}</title>
        </Helmet>
        <Chatbar title={commonTranslations.chatSeller} user={user} />
        <BottomStickyButton
          isHome={product?.stockType === 'READY_SKU'}
          isLoadingFirst={false}
          labelFirst={commonTranslations.addToCart}
          onClickFirst={onAddToCart}
          isLoadingSecond={false}
          labelSecond={commonTranslations.buyNow}
          onClickSecond={onBuyNow}
          disabled={hideCheckout}
          label={commonTranslations.buyNow}
          isLoading={false}
          onClick={onBuyNow}
        />
      </div>
    </ThemeProvider>
  )
}

export default injectIntl(Details)
