import { VideoCard } from '../../../../components/cards/video-card/VideoCard'
import './style.scss'

export default function VideoDisplay ({ videos, onClick }) {
  return (
    <div className='video-display mb-4 mt-3'>
      <div className='flex gap-[10px] overflow-x-auto overflow-y-hidden items-start scroll-bar-style'>
        {videos?.map((vi) => (
          <div onClick={() => onClick(vi.url, vi?.id)} className='w-[150px]' key={vi.id}>
            <VideoCard thumbnail={vi.imageUrl} videoUrl={vi.url} id={vi.id} styleSheet={{ height: '150px', aspectRatio: '1/1' }} />
            <h5 className='mt-[10px] text-white break-all w-[150px]'>{vi.title && vi.title}</h5>
          </div>
        ))}
      </div>
    </div>
  )
}
