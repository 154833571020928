/* eslint-disable */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { commonTranslations } from '../../translation/commonTranslations'
import { injectIntl } from 'react-intl'
import './Selected.scss'

function SelectedInput (props) {
  const { input, placeholder } =
    props
    const { formatMessage } = props.intl
  const { name, value } = input
  const [options, updateOptions] = useState([])
  const [isFocused, setIsFocused] = useState(false)
  const locationData = useSelector((state) => state.checkout.locationData)
  const provinces = useSelector((state) => state.provinces.provinces)


  const handleItemClick = (key, value) => {
    props.handleInputChange(key, value)
  }

  useEffect(() => {
    if(name==='province'){
      updateOptions(provinces)
    }
    else{
      updateOptions(locationData[name])
    }
  }, [locationData, name])

  const selectName = {
    city: formatMessage(commonTranslations.selectProvinceFirst.props),
    district: formatMessage(commonTranslations.selectCityFirst.props),
    subDistrict: formatMessage(commonTranslations.selectDistrictFirst.props),
  }

  return (
    <FormControl fullWidth>
      {value.trim().length === 0 && !isFocused && <InputLabel className='drop-box-label' id="demo-simple-select-label">{placeholder}</InputLabel>}
      <Select
        variant='outlined'
        onChange={options?.length > 0 ? (e) => handleItemClick(props.input.name, e.target.value) : noop}
        className='drop-box-wrapper'
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => setIsFocused(false)}
      >
        {options?.length > 0 ? options?.map((op, index) => (
          <MenuItem key={index} value={op}>{op}</MenuItem>
        )) : <MenuItem value={''}>{selectName[props.input.name]}</MenuItem> }
      </Select>
    </FormControl>
  )
}

export default injectIntl(SelectedInput)