/* eslint-disable */
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import React, { useState } from 'react'
import { themeColor } from '../../utilities/colors'
import Button from '../Button'
import '../../containers/OrderDetail/styles.scss'
import { noop } from 'lodash'

export const BottomStickyButton = ({
  isLoading,
  label,
  onClick,
  uperComponent,
  isHome,
  isLoadingFirst,
  labelFirst,
  onClickFirst,
  isLoadingSecond,
  labelSecond,
  onClickSecond,
  disabled
}) => {
  const [showUpperComponent, setShowUpperComponent] = useState(true)
  const closePriceBox = () => setShowUpperComponent(false)
  const openPriceBox = () => setShowUpperComponent(true)

  return (
    <div className={`${isHome ? 'w-full' : 'order-details'} sticky bg-white bottom-[0px] z-10`}>
      {uperComponent ? showUpperComponent ? (
        <div className='w-full bg-white p-[5px]'>
          <center>
            <KeyboardArrowDown onClick={closePriceBox} className='cursor-pointer' style={{ width: 25, height: 25 }} />
          </center>
          {uperComponent}
        </div>
      ) : (
          <div className='w-full bg-white p-[5px]'>
            <center>
              <KeyboardArrowUp onClick={openPriceBox} className='cursor-pointer' style={{ width: 25, height: 25 }} />
            </center>
          </div>
      ) : null}
      {isHome
        ? <div className='w-full bg-white p-[5px] py-[10px] flex gap-[10px]'>
          <Button
            isLoading={isLoadingFirst}
            label={labelFirst}
            btnColor={themeColor}
            bgColor='white'
            btnWidth='48%'
            btnHeight='40px'
            btnBorder={`1px solid ${themeColor}`}
            fontSize='16px'
            borderRadius='6px'
            onClick={disabled ? noop : onClickFirst}
            disabled={disabled}
            opacity={disabled ? 0.5 : 1}
          />
          <Button
            isLoading={isLoadingSecond}
            label={labelSecond}
            btnColor='white'
            bgColor={themeColor}
            btnWidth='48%'
            btnHeight='40px'
            fontSize='16px'
            borderRadius='6px'
            onClick={disabled ? noop : onClickSecond}
            opacity={disabled ? 0.5 : 1}
          />
        </div>
        : <div className='w-full bg-white p-[5px]'>
          <Button
            isLoading={isLoading}
            label={label}
            btnColor='white'
            bgColor={themeColor}
            btnWidth='100%'
            btnHeight='40px'
            fontSize='16px'
            borderRadius='6px'
            onClick={disabled ? noop : onClick}
            opacity={disabled ? 0.5 : 1}
          />
        </div>
      }
    </div >
  )
}
