/* eslint-disable no-case-declarations */
import produce from 'immer'
import { FETCH_PRODUCT_FAIL, FETCH_PRODUCT_INIT, FETCH_PRODUCT_SUCCESS } from '../../../actions'

const defaultState = {
  isLoading: false,
  product: {},
  isError: false
}

export default produce((state = defaultState, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_PRODUCT_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_PRODUCT_SUCCESS:
      state.product = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_PRODUCT_FAIL:
      state.product = {}
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, defaultState)
