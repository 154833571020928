import intl from '../translation/reducers'
import product from '../containers/home-page/saga/reducer'
import subOrder from '../containers/OrderDetail/saga/reducer'
import notifierReducer from '../components/snackbar/reducer'
import cartReducer from '../containers/CartPage/saga/reducer'
import paymentReducer from '../containers/PaymentPage/saga/reducer'
import { reducer as formReducer } from 'redux-form'
import { locationReducer as checkout, addOrderReducer, provinces } from '../containers/Checkout/saga/reducer'
import { seller, sharedProducts, postal } from '../containers/share-details/saga/reducer'
import { relatedVideos, similarProducts } from '../containers/DSFPDP/home/components/details/saga/reducer'
import { sellerLinks } from '../containers/dsf-store-view/saga/reducer'
import { brands } from '../containers/top-selling-products/saga/reducer'

const reducers = {
  brands,
  sellerLinks,
  relatedVideos,
  similarProducts,
  provinces,
  postal,
  sharedProducts,
  seller,
  form: formReducer,
  intl,
  product,
  subOrder,
  checkout,
  customer: addOrderReducer,
  notifierReducer,
  cart: cartReducer,
  payment: paymentReducer
}

export default reducers
