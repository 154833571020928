import { numberWithCommas } from '../../../utilities/currency'
import { makeCamel } from '../../../utilities/makeCamelCase'
import './TopSellCard.scss'

export const TopSellCard = (props) => {
  const { styleClass, icon, imgBg, product = {}, onClick = () => null } = props
  const imageUrl = product?.thumbnailImages?.[0].url || product?.productImages?.[0].url
  return (
    <div onClick={() => onClick(product?.sku)} className={'top-sell-card ' + (styleClass || '')}>
      <div className={'img-wrapper ' + (imgBg ? 'imgBg' : '')}>
        {
          product?.productImages !== null &&
            <img src={imageUrl} alt='img' className='image-logo' />
        }
        {icon && <img className='icon' src={icon} alt='icon' />}
      </div>
      <p className='capitalize'>{makeCamel(product?.name)}</p>
      <ul className='price-wrapper'>
        <li>Rp {numberWithCommas(product?.finalPrice?.toFixed(0))}</li>
        {product?.retailPrice > product.finalPrice && <li>{(Math.abs((((product.finalPrice / product?.retailPrice) * 100) - 100)))?.toFixed(0)}% Off</li>}
      </ul>
      {product?.retailPrice > product.finalPrice ? <div className='total-wrapper'> Rp {numberWithCommas(product?.retailPrice)}</div> : <div className='empty-total-wrapper' />}
    </div>
  )
}
