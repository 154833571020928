/* eslint-disable no-case-declarations */
import { NOTIFIER_SHOW, NOTIFIER_HIDE } from '../../actions'
import produce from 'immer'

const defaultState = {
  showNotification: false,
  notification: {}
}

export default produce((state = defaultState, action) => {
  switch (action.type) {
    case NOTIFIER_SHOW:
      state.showNotification = true
      state.notification = action.notification
      break

    case NOTIFIER_HIDE:
      state.showNotification = false
      state.notification = {}
      break

    default:
      return state
  }
})
