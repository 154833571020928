import React from 'react'
import { FormattedMessage } from 'react-intl'

export const languages = [
  {
    name: (
      <FormattedMessage
        id='Language.bahasa'
        description='Bahasa'
        defaultMessage='Bahasa'
      />
    ),
    locale: 'id'
  },
  {
    name: (
      <FormattedMessage
        id='Language.english'
        description='English'
        defaultMessage='English'
      />
    ),
    locale: 'en'
  }
]
export default languages
