import { commonTranslations } from '../../translation/commonTranslations'

const DisplaySection = ({ itemList, horizontal, CartView, headerName, onViewAllClick, onClickProducts, topSell }) => {
  return (
    <section className='mt-10'>
      <div className='pb-3 flex justify-between text-[#FFFFFF]'>
        {headerName &&
          <h3>{headerName}</h3>}
        {
          onViewAllClick && <h3 className='cursor-pointer' onClick={onViewAllClick}>{commonTranslations.viewAll}</h3>
        }
      </div>
      <div className={`flex flex-1 ${topSell && 'items-center flex-col'}`}>
        <div className={'items-center ' + (horizontal ? 'flex gap-2 flex-nowrap justify-start overflow-auto scroll-bar-style' : 'grid  grid-cols-2 gap-2 xs:gap-2 sm:gap-2 sm:grid-cols-3 ')}>
          {
                itemList?.map((item) => (
                  <div className='' key={item?.id}>
                    {CartView && <CartView onClick={onClickProducts} product={item} />}
                  </div>
                ))
            }
        </div>
      </div>
    </section>
  )
}

export default DisplaySection
