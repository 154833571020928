import './FilterModal.scss'
import React from 'react'
import Modal from '@material-ui/core/Modal'

const FilterModal = ({ open, onClose, children }) => {
  return (
    <div className='filter-modal'>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {children}
      </Modal>
    </div>

  )
}

export default FilterModal
