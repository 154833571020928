/* eslint-disable */
import {
  Box, createMuiTheme, ThemeProvider
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import './styles.scss'
import pic from './../../assets/Bitmap.png'
import grayLocation from './../../assets/grayLocation.svg'
import { Amount } from './components/Amount'
import { useEffect, useState } from 'react'
import { Shipment } from './components/Shipment'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import { getSubOrdersFromWH } from '../../utilities/suborderUtils'
import { createOrder, getSubOrders } from './saga/saga'
import { get } from 'lodash'
import { commonTranslations } from '../../translation/commonTranslations'
import MobileHeader from '../../components/MobileHeader/MobileHeader'
import { Create } from '@material-ui/icons'
import { themeColor } from '../../utilities/colors'
import { BottomStickyButton } from '../../components/BottomStickyButton/BottomStickyButton'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { numberWithCommas } from '../../utilities/currency'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useFetchCustomer, useFetchProduct, useFetchShipments, useNavigateTo } from '../../utilities/navigation'

const OrderDetail = () => {
  const { navigateTo } = useNavigateTo()
  const history = useHistory()
  const { isLoading, shipments, createOrder: orderPayload, discountedShippingSuggestion, isError: subOrderError } = useSelector(state => state.subOrder)
  const { product, user, dsf, quantity, fromCart } = useFetchProduct()
  const { customer: orderData } = useFetchCustomer()
  const cartList = useSelector(state => state.cart.list)
  const [eta, setEta] = useState([])
  const goBack = () => history.goBack()
  const onGoToPayment = () => {
    window.localStorage.setItem('pdp_shipments', JSON.stringify({ shipments, eta }))
    navigateTo('payments')
  }

  const fetchSubOrders = (customQuantity, prodId) => {
    const data = {
      stockType: fromCart ? 'READY_SKU' : product?.stockType,
      marketPlaceDocKey: {},
      tier: user?.tierId,
      orderAmount: dsf?.finalPrice,
      shippingTo: 'Customer',
      area_id: orderData?.areaId,
      suburb_id: orderData?.suburbId,
      lat: orderData?.lat,
      long: orderData?.long,
      province: orderData?.province,
      resellerId: user?.id,
      items: fromCart ? cartList.filter(item => item.selected).map(item => ({
          productId: item?.id,
          sku: item?.sku,
          stockType: item?.stockType,
          quantity: customQuantity ? item?.id === prodId ? customQuantity : item?.quantity : item?.quantity,
          retailPrice: item?.retailPrice,
          wholesalePrice: item?.wholesalePrice,
          effectiveWholesalePrice: item?.effectiveWholesalePrice,
          isSlashPriceEnabled: item?.isSlashPrice,
          slashedWholesalePrice: item?.slashedTierPrice?.[user?.tierId] || null,
          stockArrivalDate: item?.estimatedArrivalDate,
          stockLimit: item?.stockLimit,
          class: item?.class,
          totalEarnings: item.margin,
      })) : [
        {
          productId: product?.id,
          sku: product?.sku,
          stockType: product?.stockType,
          quantity: customQuantity ?? quantity,
          retailPrice: product?.retailPrice,
          wholesalePrice: dsf?.wholesalePrice,
          effectiveWholesalePrice: dsf?.effectiveWholesalePrice,
          isSlashPriceEnabled: product?.isSlashPrice,
          slashedWholesalePrice: product?.slashedTierPrice?.[user?.tierId] || null,
          stockArrivalDate: product?.estimatedArrivalDate,
          stockLimit: product?.stockLimit,
          class: product?.class,
          totalEarnings: dsf.margin,
        }
      ]
    }
    getSubOrdersFromWH(data, getSubOrders)
  }

  useEffect(() => {
    if (fromCart) {
      fetchSubOrders()
      return
    }
    if (!product?.id || !orderData?.id) return
    fetchSubOrders()
  }, [product, orderData, fromCart])

  const processShipmentRow = (data) => {
    const items = data.items?.map((item) => {
      const currentProduct = fromCart ? cartList.find(data => data.id === item.productId) : product
      const productTitle = get(currentProduct, 'name', '')
      const productImage = get(currentProduct, 'productImages.0.url', '')
      return {
        ...item,
        productImage,
        productTitle,
        stockLimit: currentProduct?.stockLimit
      }
    })
    const processedShipmentData = {
      ...data,
      items
    }
    return processedShipmentData
  }

  let itemCost = 0
  let shippingAmount = 0

  shipments.forEach(({ items = [], shippingFeeInfo = {} }, i) => {
    items.forEach(({ effectiveWholesalePrice = 0, quantity = 1 }) => {
      itemCost += parseFloat(effectiveWholesalePrice) * parseInt(quantity, 10)
    })
    shippingAmount += shippingFeeInfo.shippingPartners?.[eta[i]]?.slashedShippingAmount || 0
  })

  useEffect(() => {
    const initEta = []
    shipments.map((item) => { 
      if (item?.shippingFeeInfo?.shippingPartners?.length > 1) {
        item?.shippingFeeInfo?.shippingPartners.map((data, i) => {
          if (data?.isDefault) {
            initEta.push(i)
          setEta(initEta)
          }
        })
        return
      }
      initEta.push(0)
      setEta(initEta)
    })
  }, [shipments])

  const totalAmount = itemCost + shippingAmount
  const shipmentLength = shipments?.length
  const item = {
    id: 1,
    reselerPrice: itemCost,
    quantity: 20,
    shippingFee: shippingAmount,
    image: pic,
    totalAmount
  }
  
  let theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#55AB8A',
      },
    },
  })

  useEffect(() => {
    EventEmitter.emit(Events.LOAD_CHECKOUT_SHIPMENT, {
      netAmount: totalAmount || 0,
      shippingFee: item.shippingFee ? item.shippingFee : 0,
      numberOfShipments: shipmentLength || 0
    })
  }, [totalAmount, item, shipmentLength])

  useEffect(() => {
    EventEmitter.emit(Events.LOAD_FINISH_CHECKOUT)
  }, [])

  const listSuggestionComponent = () => {
    const mrpAmount = item?.reselerPrice
    const totalAmountForDiscount = mrpAmount + discountedShippingSuggestion.movDifference
    const percentage = Math.round((((mrpAmount / totalAmountForDiscount) || 0)) * 100)
    return (
      <div className='w-full py-[10px] px-[1rem] bg-[#C9E1D733] mt-[1rem]'>
        <p className='text-[14px] font-[700]'>{commonTranslations.addItemsWorth} Rp {numberWithCommas(discountedShippingSuggestion.movDifference)} {discountedShippingSuggestion?.isFreeShipping ? commonTranslations.getFreeShipping : commonTranslations.getDiscount}</p>
        <LinearProgress color='secondary' style={{ height: 8, borderRadius: 8, backgroundColor: 'rgba(85, 171, 138, 0.15)', marginTop: 10 }} variant="determinate" value={percentage} />
      </div>
    )
  }

  const footerComponent = () => {
    const slashedhippingAmount = shippingAmount
    const originalShippingAmount = shipments.map(({ shippingFeeInfo = {} }, i) => shippingFeeInfo.shippingPartners?.[eta[0] || 0]?.shippingAmount)[0]
    const savedAmount = (originalShippingAmount - slashedhippingAmount);
    if (savedAmount < 1) return null
    return (
      <div className='flex items-center justify-center mt-[20px]'>
        <div className='w-[90%] h-[36px] flex items-center justify-center bg-[#DFEFE9] rounded '>
          <p className='text-[#55AB8A] font-bold text-[13px]'>You Saved Rp.{numberWithCommas(savedAmount)} on this bill</p>
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='flex items-center justify-center flex-1 w-full flex-col'>
        {isLoading
          ? <div style={{ height: window.innerHeight }} className='flex items-center justify-center'>
              <Loading />
            </div>
          : (
            <Box style={{ minHeight: window.innerHeight }} className='order-details pb-[250px]'>
              <MobileHeader onClick={goBack} heading={commonTranslations.orderSummary} />
              <div className='w-full px-[12px] py-[8px] bg-[#9999991A] flex justify-between items-center'>
                <div className='flex'>
                  <img src={grayLocation} className='w-[10] h-[16]' />
                  <p className='text-[#00000099] text-[14px] ml-2'>
                    {orderData.fullName + ' | ' + orderData.province + ', ' + orderData.city + ', ' + orderData.district}
                  </p>
                </div>

                <Create onClick={goBack} style={{ width: 20, height: 20 }} className={`cursor-pointer text-[${themeColor}]`} />
              </div>
              {shipments.length > 0 && discountedShippingSuggestion?.movTarget && listSuggestionComponent()}
              <Box className='mt-2 shipment-box flex flex-col flex-1'>
                {shipments.map((data, i) => <Shipment fetchSubOrders={(val, id) => fetchSubOrders(val, id)} index={i} eta={eta} setEta={(val) => {
                  setEta(val)
                  }} key={i} item={processShipmentRow(data)} />)}
              </Box>
              {shipments.length > 0 && footerComponent()}
            </Box>)}
        {!isLoading && <BottomStickyButton uperComponent={<Amount item={item} />} isLoading={orderPayload?.isLoading}
          label={commonTranslations.buyNow} onClick={onGoToPayment} />}
      </div>
    </ThemeProvider>
  )
}

export default OrderDetail
