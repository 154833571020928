/* eslint-disable no-case-declarations */
import produce from 'immer'
import {
  FETCH_PROVINCES_INIT,
  FETCH_PROVINCES_SUCCESS,
  FETCH_PROVINCES_FAIL,
  GET_LOCATION_DATA_FAIL,
  GET_LOCATION_DATA_INIT,
  GET_LOCATION_DATA_SUCCESS,
  ADD_ORDER_INIT,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  CLEAR_FIELDS,
  CUSTOM_ORDER_SUCCESS
} from '../../../actions/index'

const porovinceDefault = {
  isLoading: false,
  provinces: [],
  isError: false
}

export const provinces = produce((state = porovinceDefault, action) => {
  const { type, data } = action

  switch (type) {
    case FETCH_PROVINCES_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_PROVINCES_SUCCESS:
      const temp = []
      data.data?.map((prov) => temp.push(prov.name))
      state.provinces = temp
      state.isLoading = false
      state.isError = false
      break

    case FETCH_PROVINCES_FAIL:
      state.provinces = []
      state.isLoading = false
      state.isError = true
      break
    default:
      return state
  }
}, porovinceDefault)

const defaultState = {
  isLoading: false,
  locationData: {
    pincode: [],
    province: [],
    city: [],
    district: [],
    subDistrict: []

  },
  isError: false
}

export const locationReducer = produce((state = defaultState, action) => {
  const { type, data } = action

  const change = (key) => {
    state.locationData[key] = []
  }

  switch (type) {
    case GET_LOCATION_DATA_INIT:
      state.isLoading = true
      state.isError = false
      break

    case GET_LOCATION_DATA_SUCCESS:
      const check = Object.keys(data.data?.[0]) || []
      if (check[0] === 'province') {
        change('city')
        change('district')
        change('subDistrict')
        change('pincode')
      }
      if (check[0] === 'city') {
        state.locationData.subDistrict = []
        change('district')
        change('subDistrict')
        change('pincode')
      }
      if (check[0] === 'district') {
        change('subDistrict')
        change('pincode')
      }
      const arr = []
      data.data.map((da) => arr.push(da[check]))
      state.locationData[check] = arr
      state.isLoading = false
      state.isError = false
      break

    case GET_LOCATION_DATA_FAIL:
      state.isLoading = false
      state.isError = true
      break

    case CLEAR_FIELDS: {
      state.isLoading = true
      state.isError = false
      state.locationData = {
        pincode: [],
        province: [],
        city: [],
        district: []
      }
      break
    }
    default:
      return state
  }
}, defaultState)

const orderedData = {
  isLoading: false,
  orderData: {},
  isError: false
}

export const addOrderReducer = produce((state = orderedData, action) => {
  const { type, data } = action

  switch (type) {
    case ADD_ORDER_INIT:
      state.isLoading = true
      state.isError = false
      break

    case ADD_ORDER_SUCCESS:
      state.orderData = { ...state.orderData, ...data.data }
      state.isLoading = false
      state.isError = false
      break

    case CUSTOM_ORDER_SUCCESS:
      state.orderData = data
      break

    case ADD_ORDER_FAIL:
      state.orderData = {}
      state.isLoading = false
      state.isError = true
      break

    case CLEAR_FIELDS: {
      state.orderData = {}
      state.isLoading = false
      state.isError = false
      break
    }
    default:
      return state
  }
}, orderedData)
