import './Chatbar.scss'
import whatsIcon from '../../home/components/topbar/assets/whats.svg'
import EventEmitter, { Events } from '../../../../utilities/eventEmitter'

export const Chatbar = ({ title, user }) => {
  const openSms = () => window.open(`sms:${user?.dsfMobile || user?.mobile}`, '_blank')
  const openWhatsapp = () => window.open(`https://wa.me/${user?.waMobile}`, '_blank')

  return (
    <div
      role='button'
      onClick={() => {
        EventEmitter.emit(Events.CHAT_WITH_SELLER, {
          page_name: 'PDP'
        })

        if (user?.waMobile) {
          openWhatsapp()
          return
        }
        openSms()
      }} className='chatbar py-[2px] px-[8px] border border-white bottom-[75px]'
    >
      <img className='w-[16px] h-[16px]' src={whatsIcon} alt='' />
      <label className='ml-2' role='button'>{title}</label>
    </div>
  )
}
