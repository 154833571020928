import { FETCH_BRANDS_FAIL, FETCH_BRANDS_INIT, FETCH_BRANDS_SUCCESS, FETCH_SELLER_FAIL, FETCH_SELLER_INIT, FETCH_SELLER_SUCCESS, FETCH_SHARED_PRODUCTS_FAIL, FETCH_SHARED_PRODUCTS_INIT, FETCH_SHARED_PRODUCTS_SUCCESS } from '../../../actions'
import apiCall from '../../../saga/api'

export function getSellerDetail (userId) {
  const url = `/dsf/sellers/storeview/${userId}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SELLER_INIT,
      successType: FETCH_SELLER_SUCCESS,
      failureType: FETCH_SELLER_FAIL
    }
  }
  apiCall(apiArgs)
}

export function getSharedProducts (options) {
  const { shareId, queryObj } = options
  const queryString = Object.keys(queryObj)
    .map((key) => key + '=' + queryObj[key].toString().split(' ').join('+'))
    .join('&')

  const url = `/dsf/sellers/${shareId}/products?${queryString}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SHARED_PRODUCTS_INIT,
      successType: FETCH_SHARED_PRODUCTS_SUCCESS,
      failureType: FETCH_SHARED_PRODUCTS_FAIL
    }
  }
  apiCall(apiArgs)
}

export function getBrandsList (options) {
  const queryString = Object.keys(options)
    .map((key) => key + '=' + options[key].toString().split(' ').join('+'))
    .join('&')

  const url = '/dsf/brands?' + queryString
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_BRANDS_INIT,
      successType: FETCH_BRANDS_SUCCESS,
      failureType: FETCH_BRANDS_FAIL
    }
  }
  apiCall(apiArgs)
}
