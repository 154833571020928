
/* eslint-disable no-case-declarations */
import produce from 'immer'
import {
  FETCH_PAYMENTS_LIST_INIT,
  FETCH_PAYMENTS_LIST_SUCCESS,
  FETCH_PAYMENTS_LIST_FAIL,
  EWALLET_PAYMENT_INIT,
  EWALLET_PAYMENT_SUCCESS,
  EWALLET_PAYMENT_FAIL,
  VIRTUAL_ACCOUNT_PAYMENT_INIT,
  VIRTUAL_ACCOUNT_PAYMENT_SUCCESS,
  VIRTUAL_ACCOUNT_PAYMENT_FAIL
} from '../../../actions'

const defaultState = {
  paymentsList: {
    list: [],
    isFailed: false
  },
  ewallet: {},
  virtual: {},
  isLoading: false,
  paymentInProcess: false
}

export default produce((state = defaultState, action) => {
  const { type, data } = action
  switch (type) {
    case VIRTUAL_ACCOUNT_PAYMENT_INIT: {
      state.isLoading = true
      state.virtual = {}
      break
    }
    case VIRTUAL_ACCOUNT_PAYMENT_SUCCESS: {
      state.isLoading = false
      state.virtual = data?.data
      break
    }
    case VIRTUAL_ACCOUNT_PAYMENT_FAIL: {
      state.isLoading = false
      state.virtual = {}
      break
    }
    case EWALLET_PAYMENT_INIT: {
      state.isLoading = true
      state.ewallet = {}
      state.paymentInProcess = false
      break
    }
    case EWALLET_PAYMENT_SUCCESS: {
      state.paymentInProcess = true
      state.isLoading = false
      state.ewallet = data?.data
      break
    }
    case EWALLET_PAYMENT_FAIL: {
      state.paymentInProcess = false
      state.isLoading = false
      state.ewallet = {}
      break
    }
    case FETCH_PAYMENTS_LIST_INIT: {
      state.paymentsList.isFailed = false
      state.isLoading = true
      state.paymentsList.list = []
      state.paymentInProcess = false
      break
    }
    case FETCH_PAYMENTS_LIST_SUCCESS: {
      state.paymentsList.isFailed = false
      state.isLoading = false
      state.paymentsList.list = data?.data
      break
    }
    case FETCH_PAYMENTS_LIST_FAIL: {
      state.paymentsList.isFailed = true
      state.isLoading = false
      state.paymentsList.list = []
      break
    }
    default:
      return state
  }
}, defaultState)
