import { Checkbox, Typography } from '@material-ui/core'
import React from 'react'
import QuantitySelector from '../../../components/quantity-selector/quantitySelector'
import grayDelete from '../../../assets/grayDelete.svg'
import { numberWithCommas } from '../../../utilities/currency'

const videoRegex = /^https?:\/\/(.+\/)+.+(\.(avi|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|ogg|mp3|oga|aac|mpeg|webm))$/i
export default function CartItem (product) {
  const { image, name, quantity, price, slashedPrice, discount, selected, onChangeQuantityValue, onChangeQuantity, onDeleteCart, onSelectSpecificProduct } = product

  return (
    <div className='w-full px-[10px] bg-white flex mt-3 py-[10px]'>
      {
        videoRegex.test(image)
          ? (
            <video className='w-[90px] h-[90px] rounded' playsInline loop autoPlay muted>
              <source src={image} type='video/mp4' />
            </video>
            )
          : <img
              src={image}
              className='w-[90px] h-[90px] rounded'
              alt='images'
            />
      }
      <div className='absolute ml-[-5px] mt-[-5px]'>
        <Checkbox onClick={() => onSelectSpecificProduct(product)} color='primary' checked={selected} />
      </div>
      <div className='ml-2 mr-1 flex flex-col flex-1'>
        <Typography
          className='m-0 ellipsed-text mt-0 text-[14px] text-[#00000080]'
          style={{ lineHeight: 1.2 }}
          paragraph
        >
          {name}
        </Typography>
        <div className='flex justify-between items-center mt-2'>
          <p className='font-bold text-[14px] m-0'>
            Rp {numberWithCommas(price)}
            {slashedPrice > 0 &&
              <span
                style={{ color: 'rgba(0, 0, 0, 0.4)' }}
                className='text-[12px] line-through ml-1'
              >
                Rp{numberWithCommas(slashedPrice)}
              </span>}
          </p>

          {discount > 0 &&
            <p className='font-bold text-[14px] m-0 mt-2 text-[#22BE95]'>
              {discount}% off
            </p>}
        </div>
        <div className='flex justify-between items-center mt-3'>
          <div className='flex items-center'>
            <p className='text-[11px] text-[#000000] opacity-[0.6] mr-2'>
              Qty:
            </p>
            <QuantitySelector
              onChangeQuantityValue={(val) => onChangeQuantityValue(val, product)}
              onChangeQuantity={(val) => onChangeQuantity(val, product)}
              name='quantity'
              id='quantity'
              value={quantity}
            />
          </div>

          <img onClick={() => onDeleteCart(product)} className='cursor-pointer' src={grayDelete} alt='delete' />
        </div>
      </div>
    </div>
  )
}
