import { v4 } from 'uuid'
import { FETCH_POSTAL_FAIL, FETCH_POSTAL_INIT, FETCH_POSTAL_SUCCESS, FETCH_SELLER_FAIL, FETCH_SELLER_INIT, FETCH_SELLER_LINKS_FAIL, FETCH_SELLER_LINKS_INIT, FETCH_SELLER_LINKS_SUCCESS, FETCH_SELLER_SUCCESS, FETCH_SHARED_PRODUCTS_FAIL, FETCH_SHARED_PRODUCTS_INIT, FETCH_SHARED_PRODUCTS_SUCCESS } from '../../../actions'
import apiCall from '../../../saga/api'

export function registerClickStoreProfiles (options) {
  const url = '/dsf/events/click'
  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      ...options
    },
    url,
    TYPES: {
      requestType: '',
      successType: '',
      failureType: ''
    }
  }
  apiCall(apiArgs)
}

export function registerClick ({ id }) {
  const url = '/dsf/sellers/links/event/click'
  const apiArgs = {
    API_CALL: {
      method: 'POST'
    },
    data: {
      id,
      sessionId: v4()
    },
    url,
    TYPES: {
      requestType: '',
      successType: '',
      failureType: ''
    }
  }
  apiCall(apiArgs)
}

export function getSellerLinks (resellerId) {
  const url = `/dsf/sellers/links/all?resellerId=${resellerId}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SELLER_LINKS_INIT,
      successType: FETCH_SELLER_LINKS_SUCCESS,
      failureType: FETCH_SELLER_LINKS_FAIL
    }
  }
  apiCall(apiArgs)
}

export function getSellerDetail (userId) {
  const url = `/dsf/sellers/storeview/${userId}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SELLER_INIT,
      successType: FETCH_SELLER_SUCCESS,
      failureType: FETCH_SELLER_FAIL
    }
  }
  apiCall(apiArgs)
}

export function getSharedProducts (queryObj) {
  const { shareId, page = 1, limit = 50 } = queryObj
  const url = `/dsf/sellers/${shareId}/products?page=${page}&limit=${limit}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SHARED_PRODUCTS_INIT,
      successType: FETCH_SHARED_PRODUCTS_SUCCESS,
      failureType: FETCH_SHARED_PRODUCTS_FAIL
    }
  }
  apiCall(apiArgs)
}

export function getPostalData (options) {
  const queryString = Object.keys(options)
    .map((key) => key + '=' + options[key].split(' ').join('+'))
    .join('&')

  const url = '/dsf/orders/postal-data-details?' + queryString

  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_POSTAL_INIT,
      successType: FETCH_POSTAL_SUCCESS,
      failureType: FETCH_POSTAL_FAIL
    }
  }
  apiCall(apiArgs)
}
