/* eslint-disable */
import { CircularProgress, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Field } from 'redux-form'
import GroupField from './components/GoupField/GroupField'
import Button from '../../components/Button'
import './style.scss'
import { useEffect, useRef, useState } from 'react'
import { addOrder, getLocationData, getProvinces } from './saga/saga'
import GroupSearchField from './components/GroupSearchField/GroupSearchField'
import { commonTranslations, orderTranslations } from '../../translation/commonTranslations'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Search from '@material-ui/icons/Search'
import { themeColor } from '../../utilities/colors'
import Label from '../../components/Label/Label'
import { Close } from '@material-ui/icons'
import { BottomStickyButton } from '../../components/BottomStickyButton/BottomStickyButton'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import store from '../../store'
import { CUSTOM_ORDER_SUCCESS } from '../../actions'
import { useFetchProduct, useNavigateTo } from '../../utilities/navigation'

export const CheckoutForm = injectIntl(({ handleSubmit, change, intl}) => {
  const { productId } = useParams()
  const { search } = useLocation()
  const { user } = useFetchProduct()
  const { navigateTo } = useNavigateTo()
  const history = useHistory()
  const [queryObj, updateQueryObj] = useState({})
  const { isLoading } = useSelector(state => state.customer)
  const { product } = useSelector(state => state.product)
  const { locationData } = useSelector(state => state.checkout)
  const { formatMessage } = intl
  const [isAddressSelection, setIsAddressSelection] = useState(false)
  const [currentLocationValue, setCurrentLocationValue] = useState('province')
  const [options, setOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [pinCode, setPinCode] = useState('')
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [queryObjectForPersist, setQueryObjectForPersist] = useState({})
  const hiddenButtonRef = useRef()

  const onSubmit = (values) => {
    addOrder({ ...values, resellerId: user?.id }).then((res) => {
      window.localStorage.setItem('user_details', JSON.stringify(values))
      window.localStorage.setItem('pdp_user_details', JSON.stringify(res.data?.data))
      EventEmitter.emit(Events.SUBMIT_ADDRESS, { ...values, resellerId: user?.id })  
      navigateTo('sub-order')
    })
   }

  const handleInputChange = (key, value) => {
    change(key, value)
    setSearchTerm('')

    if (key === 'province') {
      setCurrentLocationValue('city')
      change('city', '')
      change('district', '')
      change('subDistrict', '')
      change('pincode', '')
      const tempObj = {}
      tempObj[key] = value
      updateQueryObj(tempObj)
      EventEmitter.emit(Events.SELECT_PROVINCE, {
        province_name: value
      })
    }
    if (key === 'city') {
      setCurrentLocationValue('district')
      change('district', '')
      change('subDistrict', '')
      change('pincode', '')
      const tempObj = { province: queryObj.province }
      tempObj[key] = value
      updateQueryObj(tempObj)

      EventEmitter.emit(Events.SELECT_CITY, {
        city_name: value
      })
    }
    if (key === 'district') {
      setCurrentLocationValue('subDistrict')
      change('subDistrict', '')
      change('pincode', '')
      const tempObj = { province: queryObj.province, city: queryObj.city }
      tempObj[key] = value
      updateQueryObj(tempObj)
      EventEmitter.emit(Events.SELECT_DISTRICT, {
        district_name: value
      })
    }
    if (key === 'subDistrict') {
      const tempObj = { province: queryObj.province, city: queryObj.city, district: queryObj.district }
      tempObj[key] = value
      updateQueryObj(tempObj)
      setIsAddressSelection(false)
      EventEmitter.emit(Events.SELECT_SUB_DISTRICT, {
        sub_district_name: value
      })
    }
  }
  useEffect(() => {
    const user_details = window.localStorage.getItem('user_details')
    if (user_details) {
      const { city = '', contactNumber = '', district = '', email = '', fullName = '', house = '', pincode = '', province = '', street = '', subDistrict = '' } = JSON.parse(user_details)
      updateQueryObj(province && city && district && subDistrict ? { province, city, district, subDistrict } : {})
      setQueryObjectForPersist(province && city && district && subDistrict ? { province, city, district, subDistrict } : {})
      setPinCode(pincode)
      change('province', province)
      change('city', city)
      change('district', district)
      change('subDistrict', subDistrict)
      change('pincode', pincode)
      change('fullName', fullName)
      change('contactNumber', contactNumber)
      change('email', email)
      change('house', house)
      change('street', street)
    }
    getProvinces()
  }, [])

  useEffect(() => {
    setLocationsLoading(true)
    getLocationData(queryObj).then(res => {
      setLocationsLoading(false)
      const list = res.data.data
      if (Object.keys(list[0])[0] === 'pincode') {
        setPinCode(list[0]?.pincode)
        return
      }
      setOptions(list.map(item => ({ name: item[currentLocationValue] })))
    }).catch(e => {
      setLocationsLoading(false)
    })
  }, [queryObj])

  useEffect(() => {
    if (locationData?.pincode?.[0]) {
      change('pincode', locationData.pincode?.[0])
    }
  }, [locationData.pincode, change])

  useEffect(() => {
    change('product', product?.product?.id)
  }, [change, product])

  const openLocationSelection = () => {
    EventEmitter.emit(Events.LOAD_ADDRESS)
    setLocationsLoading(false)
    setIsAddressSelection(true)
    setCurrentLocationValue('province')
    setPinCode('')
    updateQueryObj({})
    setSearchTerm('')
    setOptions([])
    change('province', '')
    change('city', '')
    change('district', '')
    change('subDistrict', '')
    change('pincode', '')
  }

  const closeLocationSelection = () => {
    setLocationsLoading(false)
    setCurrentLocationValue('province')
    setPinCode('')
    updateQueryObj(queryObjectForPersist)
    setSearchTerm('')
    setOptions([])
    change('province', '')
    change('city', '')
    change('district', '')
    change('subDistrict', '')
    change('pincode', '')
    setIsAddressSelection(false)
  }

  const onResetSelection = () => {
    setLocationsLoading(false)
    setCurrentLocationValue('province')
    setPinCode('')
    updateQueryObj({})
    setSearchTerm('')
    setOptions([])
    change('province', '')
    change('city', '')
    change('district', '')
    change('subDistrict', '')
    change('pincode', '')
  }

  const handleClose = () => history.goBack()

  const compare = (firstName, secondName) => {
    if (firstName.name < secondName.name) {
      return -1
    }
    if (firstName.name > secondName.name) {
      return 1
    }
    return 0
  }

  return (
    <>
      <div className='form-wrapper'>
        {isAddressSelection
          ? <Grid container>
            <div className='searchBox mt-2'>
              <ArrowBackIos onClick={closeLocationSelection} style={{ width: 20, height: 20, cursor: 'pointer' }} />
              <TextField
                variant='outlined'
                InputProps={{
                  startAdornment: <InputAdornment position='start'><Search style={{ width: 20, height: 20, margin: 0, padding: 0, color: 'gray' }} /></InputAdornment>,
                  style: { height: 45, color: 'gray', backgroundColor: '#F9F9F9' },
                  classes: {
                    root: {
                      '&$cssFocused $notchedOutline': {
                        borderColor: 'red !important'
                      }
                    }
                  }
                }}
                fullWidth
                placeholder={formatMessage(commonTranslations.searchPlaceholder.props).replace('{name}', currentLocationValue?.toLowerCase())}
                style={{ marginLeft: 10 }}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            {
              Object.keys(queryObj).length > 0 &&
              <div className='w-full mt-2'>
                <div className='flex items-center justify-between'>
                  <p className='font-[16px] text-[#02020280]'>
                    {formatMessage(commonTranslations.selectedPlaceholder.props)
                      .replace('{name}', Object.keys(queryObj)[Object.keys(queryObj).length - 1])}
                  </p>

                  <p onClick={onResetSelection} className={`text-[16px] text-[${themeColor}] cursor-pointer font-bold`}>{commonTranslations.reset}</p>
                </div>

                <div className='mt-3'>
                  {
                    Object.values(queryObj).map(item =>
                      <div className='flex'>
                        <div className='flex flex-col items-center ml-4'>
                          <div className='w-[8px] h-[8px] bg-[#CCCCCC] rounded-full' />
                          <div className='w-[1px] h-[20px] bg-[#CCCCCC]' />
                        </div>

                        <p className='ml-4 mt-[-7px]'>{item}</p>
                      </div>
                    )
                  }
                  <div className='w-full border-1 border-[#CCCCCC] h-[40px] rounded flex items-center'>
                    <div className={`w-[15px] h-[15px] rounded-full border-1 border-[${themeColor}] flex items-center justify-center ml-3`}>
                      <div className={`w-[8px] h-[8px] rounded-full bg-[${themeColor}]`} />
                    </div>

                    <p className={`ml-3 text-[${themeColor}]`}>{formatMessage(commonTranslations.selectPlaceholder.props)
                      .replace('{name}', currentLocationValue?.toLowerCase())
                    }</p>
                  </div>
                </div>
              </div>
            }

            <div className='w-full p-[8px] bg-[#F5F5F5] mt-3'>
              <p className='font-[16px] text-[#02020280] m-0 p-0'>{formatMessage(commonTranslations.selectPlaceholder.props)
                .replace('{name}', currentLocationValue?.toLowerCase())}
              </p>
            </div>

            <div className='w-full p-[8px]'>
              {locationsLoading ?
                <div className='flex items-center justify-center mt-4'>
                  <CircularProgress color={'inherit'} style={{ color: themeColor }} />
                </div>
                : options.filter((item) => item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())).map((item, i) => {
                  const name = item?.name
                  const previousName = Array.from(options)?.sort(compare)[i - 1]?.name
                  return (
                    <div key={i} onClick={() => handleInputChange(currentLocationValue, name)} className='w-full flex mt-2 cursor-pointer'>
                      <p className='text-[16px] w-[30px]'>
                        {i === 0
                          ? name.charAt(0)
                          : previousName?.charAt(0) === name?.charAt(0)
                            ? ''
                            : name.charAt(0)}
                      </p>
                      <div className='flex flex-1 pb-2 border-b'>
                        {name}
                      </div>
                    </div>
                  )
                }
                )}
            </div>
          </Grid>
          : <>
            <div className='flex flex-row pt-2'>
              <Close className='mt-2' onClick={handleClose} style={{ width: 20, height: 20, cursor: 'pointer' }} />
              <Typography paragraph style={{ fontWeight: '600', marginLeft: 10 }} variant='h5'>
                Please Share Your Shipping Address
              </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name='fullName'
                    component={GroupField}
                    placeholder={formatMessage(commonTranslations.enterFullName.props)}
                    label={`${formatMessage(orderTranslations.fullName.props)}*`}
                    type='text'
                    border='1px solid #D0D0D0'
                    brRadius='6px'
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='contactNumber'
                    component={GroupField}
                    placeholder={formatMessage(commonTranslations.enterPhone.props)}
                    label={`${formatMessage(orderTranslations.contactNumber.props)}*`}
                    type='text'
                    border='1px solid #D0D0D0'
                    brRadius='6px'
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='email'
                    component={GroupField}
                    placeholder={formatMessage(commonTranslations.enterEmail.props)}
                    label={orderTranslations.contactEmail}
                    type='email'
                    border='1px solid #D0D0D0'
                    brRadius='6px'
                    height={48}
                  />
                </Grid>
                <Grid item xs={12}>
                  {locationsLoading && queryObj?.subDistrict ?
                    <div>
                      <Label title={`${formatMessage(commonTranslations.selectLocation.props)}*`} />
                      <div className=' mt-2'>
                        <CircularProgress color={'inherit'} style={{ color: themeColor }} />
                      </div>
                    </div>
                    : pinCode ?
                      <div>
                        <Label title={`${formatMessage(commonTranslations.selectLocation.props)}*`} />
                        <div className='flex mt-2 w-full'>
                          <div className='flex flex-1 items-center justify-between'>
                            <p className='text-[16px] flex-[0.8]'>
                              {Object.values(queryObj)
                                .map((item, i) => ` ${item}${i === Object.values(queryObj).length - 1 ? '' : ','}`
                                )}
                            </p>

                            <p onClick={openLocationSelection} className={`text-[16px] text-[${themeColor}] cursor-pointer font-bold`}>{commonTranslations.edit}</p>
                          </div>
                        </div>
                      </div>
                      :
                      <Field
                        name='pincode'
                        component={GroupField}
                        placeholder={formatMessage(commonTranslations.searchProvince.props)}
                        label={`${formatMessage(commonTranslations.selectLocation.props)}*`}
                        type='text'
                        brRadius='6px'
                        height={48}
                        isLocation
                        onClick={openLocationSelection}
                      />}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='house'
                    component={GroupField}
                    placeholder=''
                    label={`${formatMessage(orderTranslations.houseBuilding.props)}*`}
                    type='text'
                    border='1px solid #D0D0D0'
                    brRadius='6px'
                    height={48}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='street'
                    component={GroupField}
                    placeholder={formatMessage(commonTranslations.enterStreet.props)}
                    label={`${formatMessage(orderTranslations.streetName.props)}*`}
                    type='text'
                    brRadius='6px'
                    height={48}
                    maxLength={50}
                  />
                </Grid>
                <Grid style={{ display: 'none' }} item xs={12}>
                  <Field
                    name='product'
                    component={GroupSearchField}
                    placeholder='jakarta'
                    label={`${orderTranslations.subDistrict}*`}
                    type='text'
                    border='1px solid #D0D0D0'
                    brRadius='6px'
                    height={48}
                    handleInputChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <input type="submit" style={{ display: 'none' }} ref={hiddenButtonRef} />
            </form>
            <br />
          </>}
      </div>
      {!isAddressSelection && <BottomStickyButton isLoading={isLoading}
        label={commonTranslations.continue} onClick={() => hiddenButtonRef.current.click()} />}
    </>
  )
})
