import { useEffect } from 'react'
import Slide from '../../../components/slider/Slide'
import EventEmitter, { Events } from '../../../../../utilities/eventEmitter'
import QuantitySelector from '../../../components/quantity-selector/quantitySelector'
import Feature from '../feature/Feature'
import Rating from '../rating/Rating'
import SocialShare from '../social-share/SocialShare'
import './Details.scss'
import ProductVideos from '../product-videos/ProductVideos'
import MoreProducts from '../more-products/MoreProducts'
import ProductDetails from '../product-details/ProductDetails'
import CustomerRating from '../customer-rating/CustomerRating'
import PaymentMethods from '../payment-methods/PaymentMethods'
import { getProductRelatedVideos, getSimilarProducts } from './saga/saga'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { errorTranslations } from '../../../../../translation/commonTranslations'
import { clearFields } from '../../../../OrderDetail/saga/saga'
import { numberWithCommas } from '../../../../../utilities/currency'

const Details = ({
  user,
  quantity,
  onChangeQuantity,
  product = {},
  onChangeQuantityValue
}) => {
  const videos = useSelector((state) => state.relatedVideos)
  const similarProducts = useSelector((state) => state.similarProducts)
  const tabLabels = ['description', 'ingredients', 'howToUse']
  const route = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('sub-order')) return
    getSimilarProducts({ shareId: user.shareId })
    getProductRelatedVideos({ productId: product?.id })
  }, [product.id, user.shareId])

  const onClickProducts = (productId) => {
    clearFields()
    history.push(`/${route.url?.split('/')[1]}/${productId}`)
    EventEmitter.emit(Events.MORE_FROM_SELLER, {
      product_id: productId
    })
  }

  const isProductOutOfStock = (product.stockLimit - product.reserveStock) <= 0
  const isProductDelisted = product?.isDelisted
  const hideCheckout = isProductDelisted ? true : isProductOutOfStock || false

  useEffect(() => {
    if (location.pathname.includes('sub-order')) return
    if (Object.keys(product).length > 0) {
      EventEmitter.emit(Events.PRODUCT_DETAILS, {
        product_id: product.id
      })
    }

    if (isProductOutOfStock) {
      EventEmitter.emit(Events.OUT_OF_STOCK, {
        screen: 'detailsPage'
      })
    }
  }, [product])

  return (
    <div className='details'>

      <div className='slide-container'>
        <Slide imageList={product?.productImages} />
        <div className='rating-container'>
          {
            product.rating > 0 && <Rating rating={product.rating} reviewer={product.reviewCount} />
          }
        </div>
        <div className='social-container'><SocialShare /></div>
      </div>

      <div className='mb-3 product-description '>
        <h5 className='op-70 my-2'>{product.category?.name} | {product.brand?.name} | {product.class && product.class}</h5>
        <h3 className='op-70'>{product?.name}</h3>
        <div className='price-quantity-wrapper mt-1'>
          <div className='prices-wrapper'>
            <h2 className='final-price'>Rp. {numberWithCommas(product.finalPrice?.toFixed(0))}</h2>
            {
              product.retailPrice > product.finalPrice &&
                <div>
                  <h4 className='retail-price'>Rp. {numberWithCommas(product.retailPrice.toFixed(0))}</h4>
                </div>
            }
          </div>
          {hideCheckout ? <span className='text-red-500'>{errorTranslations.noStock}</span> : <QuantitySelector onChangeQuantityValue={onChangeQuantityValue} onChangeQuantity={onChangeQuantity} name='quantity' id='quantity' value={quantity} />}
        </div>
        {
          product.retailPrice > product.finalPrice &&
            <h3 className='discount-price'>{(Math.abs((((product?.finalPrice / product?.retailPrice) * 100) - 100)))?.toFixed(0)}% Off</h3>
        }
      </div>

      <div className='mb-4 feature-container'>
        <Feature />
      </div>
      {
        videos.videos?.length > 0 &&
          <div className='mb-4 product-videos-container'>
            <ProductVideos videos={videos.videos} />
          </div>
      }
      {
        tabLabels.filter(tab => product[tab]).length > 0 &&
          <div className='mb-4 product-details-container'>
            <ProductDetails product={product} />
          </div>
      }
      {
        product.rating > 0 &&
          <div className='customer-rating-container'>
            <CustomerRating rating={product.rating} reviewer={product.reviewCount} />
          </div>
      }
      <div className='mb-4 payment-methods-container'>
        <PaymentMethods />
      </div>
      {
        similarProducts.products?.filter(item => item?.product?.id !== product?.id).length > 0 &&
          <div className='mb-4 more-products-container'>
            <MoreProducts store={user?.store || user?.name} onClick={onClickProducts} products={similarProducts.products?.filter(item => item?.product?.id !== product?.id)} />
          </div>
      }
    </div>
  )
}

export default Details
