/* eslint-disable no-case-declarations */
import produce from 'immer'
import { FETCH_RELATED_VIDEOS_FAIL, FETCH_RELATED_VIDEOS_INIT, FETCH_RELATED_VIDEOS_SUCCESS, FETCH_SIMILAR_PRODUCTS_FAIL, FETCH_SIMILAR_PRODUCTS_INIT, FETCH_SIMILAR_PRODUCTS_SUCCESS } from '../../../../../../actions'

const similarProductsInit = {
  isLoading: false,
  products: [],
  isError: false
}

export const similarProducts = produce((state = similarProductsInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_SIMILAR_PRODUCTS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_SIMILAR_PRODUCTS_SUCCESS:
      state.products = data.data.products
      state.isLoading = false
      state.isError = false
      break

    case FETCH_SIMILAR_PRODUCTS_FAIL:
      state.products = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, similarProductsInit)

const relatedVideosInit = {
  isLoading: false,
  videos: [],
  isError: false
}

export const relatedVideos = produce((state = relatedVideosInit, action) => {
  const { type, data } = action
  switch (type) {
    case FETCH_RELATED_VIDEOS_INIT:
      state.isLoading = true
      state.isError = false
      break

    case FETCH_RELATED_VIDEOS_SUCCESS:
      state.videos = data.data
      state.isLoading = false
      state.isError = false
      break

    case FETCH_RELATED_VIDEOS_FAIL:
      state.videos = []
      state.isLoading = false
      state.isError = true
      break

    default:
      return state
  }
}, relatedVideosInit)
