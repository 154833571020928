/* eslint-disable */
/* eslint-disable react/no-multi-comp */
/* eslint-disable import/prefer-default-export */
/* eslint "jsx-quotes": ["error", "prefer-double"] */
import React from "react";
import { isEmpty, toLower, lowerCase } from "lodash";
import { FormattedMessage } from "react-intl";

export const orderDetailTranslations = {
  query: (
    <FormattedMessage
      id="product-details:query"
      description="Query"
      defaultMessage="Query"
    />
  ),
  success: (
    <FormattedMessage
      id="product-details:success"
      description="Success"
      defaultMessage="Success"
    />
  ),
  items: (
    <FormattedMessage
      id="order-details:items"
      description="Items"
      defaultMessage="Items"
    />
  ),
  itemCost: (
    <FormattedMessage
      id="order-details:item-cost"
      description="Item Cost"
      defaultMessage="Item Cost"
    />
  ),
};
export const paymentMethodTranslations = {
  choosePaymentMethod: (
    <FormattedMessage
      id="payment-method:choose-payment-method"
      description="Choose Payment Method"
      defaultMessage="Choose Payment Method"
    />
  ),
  transferBank: (
    <FormattedMessage
      id="payment-method:transfer-bank"
      description="Transfer Bank"
      defaultMessage="Transfer Bank"
    />
  ),
  transferVirtualAccount: (
    <FormattedMessage
      id="payment-method:transfer-virtual-account"
      description="Transfer Virtual Account"
      defaultMessage="Transfer Virtual Account"
    />
  ),
  creditCard: (
    <FormattedMessage
      id="payment-method:credit-card"
      description="Credit Card"
      defaultMessage="Credit Card"
    />
  ),
  creditDebitCard: (
    <FormattedMessage
      id="payment-method:credit-debit-card"
      description="Credit or Debit Card"
      defaultMessage="Credit or Debit Card"
    />
  ),
  eWallet: (
    <FormattedMessage
      id="payment-method:e-wallet"
      description="E-Wallet"
      defaultMessage="E-Wallet"
    />
  ),
  eWalletOVO: (
    <FormattedMessage
      id="payment-method:e-wallet-ovo"
      description="OVO"
      defaultMessage="OVO"
    />
  ),
  eWalletDANA: (
    <FormattedMessage
      id="payment-method:e-wallet-dana"
      description="DANA"
      defaultMessage="DANA"
    />
  ),
  eWalletLinkAja: (
    <FormattedMessage
      id="payment-method:e-wallet-link-aja"
      description="LinkAja"
      defaultMessage="LinkAja"
    />
  ),
};
export const orderTranslations = {
  eta: (
    <FormattedMessage
      id="shipment-details:eta"
      description="ETA"
      defaultMessage="ETA"
    />
  ),
  zipCode: (
    <FormattedMessage
      id="address-collector:shipping-details-pincode"
      description="Search Zipcode"
      defaultMessage="Search Zipcode"
    />
  ),
  subDistrict: (
    <FormattedMessage
      id="address-collector:shipping-details-sub-district"
      description="Sub District"
      defaultMessage="Sub District"
    />
  ),
  district: (
    <FormattedMessage
      id="address-collector:shipping-details-district"
      description="District"
      defaultMessage="District"
    />
  ),
  city: (
    <FormattedMessage
      id="address-collector:shipping-details-city"
      description="City"
      defaultMessage="City"
    />
  ),
  province: (
    <FormattedMessage
      id="address-collector:shipping-details-province"
      description="Province"
      defaultMessage="Province"
    />
  ),
  streetName: (
    <FormattedMessage
      id="address-collector:shipping-details-street-name"
      description="Street Name"
      defaultMessage="Street Name"
    />
  ),
  houseBuilding: (
    <FormattedMessage
      id="address-collector:shipping-details-house-building"
      description="House/Building"
      defaultMessage="House/Building"
    />
  ),
  contactEmail: (
    <FormattedMessage
      id="address-collector:contact-email"
      description="Contact Email"
      defaultMessage="Contact Email"
    />
  ),
  contactNumber: (
    <FormattedMessage
      id="address-collector:contact-number"
      description="Contact Number"
      defaultMessage="Contact Number"
    />
  ),
  fullName: (
    <FormattedMessage
      id="address-collector:full-name"
      description="Full Name"
      defaultMessage="Full Name"
    />
  ),
  orderId: (
    <FormattedMessage
      id="order.orderId"
      description="Order ID"
      defaultMessage="Order ID"
    />
  ),
  shipment: (
    <FormattedMessage
      id="order.shipment"
      description="Shipment"
      defaultMessage="Shipment"
    />
  ),
  orderedDate: (
    <FormattedMessage
      id="order.orderedDate"
      description="Ordered Date"
      defaultMessage="Ordered Date"
    />
  ),
  date: (
    <FormattedMessage
      id="order.date"
      description="Date"
      defaultMessage="Date"
    />
  ),
  stockType: (
    <FormattedMessage
      id="order.stockType"
      description="Stock Type"
      defaultMessage="Stock Type"
    />
  ),
  orderVia: (
    <FormattedMessage
      id="order.orderVia"
      description="Order Via"
      defaultMessage="OrderVia"
    />
  ),
  customer: (
    <FormattedMessage
      id="order.customer"
      description="Customer"
      defaultMessage="Customer"
    />
  ),
  customerName: (
    <FormattedMessage
      id="order.customerName"
      description="Customer name"
      defaultMessage="Customer name"
    />
  ),
  customerDetails: (
    <FormattedMessage
      id="order.customerDetails"
      description="Customer Details"
      defaultMessage="Customer Details"
    />
  ),
  store: (
    <FormattedMessage
      id="order.store"
      description="Store"
      defaultMessage="Store"
    />
  ),
  sender: (
    <FormattedMessage
      id="order.sender"
      description="Sender"
      defaultMessage="Sender"
    />
  ),
  receiver: (
    <FormattedMessage
      id="order.receiver"
      description="Receiver"
      defaultMessage="Receiver"
    />
  ),
  status: (
    <FormattedMessage
      id="order.status"
      description="Order Status"
      defaultMessage="Order Status"
    />
  ),
  mpOrderId: (
    <FormattedMessage
      id="order.mpOrderId"
      description="MP Order Id"
      defaultMessage="MP Order Id"
    />
  ),
  updateAddress: (
    <FormattedMessage
      id="order.updateAddress"
      description="Update Order Address"
      defaultMessage="Update Address"
    />
  ),
  xenditInvoice: (
    <FormattedMessage
      id="order.xenditInvoice"
      description="Xendit Invoice"
      defaultMessage="Xendit Invoice"
    />
  ),
  marketPlace: (
    <FormattedMessage
      id="order.marketPlace"
      description="Market Place"
      defaultMessage="Market Place"
    />
  ),
  amount: (
    <FormattedMessage
      id="order.amount"
      description="Amount"
      defaultMessage="Amount"
    />
  ),
  negotiatedAmount: (
    <FormattedMessage
      id="order.negotiatedAmount"
      description="Negotiated Amount"
      defaultMessage="Negotiated Amount"
      values={{ br: <br /> }}
    />
  ),
  orderType: (
    <FormattedMessage
      id="order.orderType"
      description="Order Type"
      defaultMessage="Order Type"
    />
  ),
  Preorders: (
    <FormattedMessage
      id="order.preorder"
      description="Preorders"
      defaultMessage="Preorders"
    />
  ),
  trackOrder: (
    <FormattedMessage
      id="order.trackOrder"
      description="Track Order"
      defaultMessage="Track Order"
    />
  ),
  downloadPdf: (
    <FormattedMessage
      id="order.downloadPdf"
      description="Download PDF"
      defaultMessage="Download PDF"
    />
  ),
  export: (
    <FormattedMessage
      id="order.export"
      description="Export"
      defaultMessage="Export"
    />
  ),
  stopOrderCreation: (
    <FormattedMessage
      id="order.stopOrderCreation"
      description="Stop Order Creation"
      defaultMessage="Stop Order Creation"
    />
  ),
  restartOrderCreation: (
    <FormattedMessage
      id="order.restartOrderCreation"
      description="Restart Order Creation"
      defaultMessage="Restart Order Creation"
    />
  ),
  orderItems: (
    <FormattedMessage
      id="order.orderItems"
      description="Order Items"
      defaultMessage="Order Items"
    />
  ),
  quantity: (
    <FormattedMessage
      id="order.quantity"
      description="Quantity"
      defaultMessage="Quantity"
    />
  ),
  negotiatedPrice: (
    <FormattedMessage
      id="order.negotiatedPrice"
      description="Negotiated Price"
      defaultMessage="Negotiated Price"
    />
  ),
  finalPrice: (
    <FormattedMessage
      id="order.finalPrice"
      description="Final Price"
      defaultMessage="Final Price"
    />
  ),
  price: (
    <FormattedMessage
      id="order.price"
      description="Price/unit"
      defaultMessage="Price/unit"
    />
  ),
  couponCode: (
    <FormattedMessage
      id="order.couponCode"
      description="Coupon Code"
      defaultMessage="Coupon Code"
    />
  ),
  negotiatedDiscount: (
    <FormattedMessage
      id="order.negotiatedDiscount"
      description="Negotiated Discount :&nbsp"
      defaultMessage="Negotiated Discount :&nbsp"
    />
  ),
  preDiscountGM: (
    <FormattedMessage
      id="order.preDiscountGM"
      description="Pre Discount GM:&nbsp"
      defaultMessage="Pre Discount GM:&nbsp"
    />
  ),
  postDiscountGM: (
    <FormattedMessage
      id="order.postDiscountGM"
      description="Post Discount GM:&nbsp"
      defaultMessage="Post Discount GM:&nbsp"
    />
  ),
  amountDiscounted: (
    <FormattedMessage
      id="order.amountDiscounted"
      description="Amount Discounted"
      defaultMessage="Amount Discounted"
    />
  ),
  discountAmount: (
    <FormattedMessage
      id="order.discountAmount"
      description="Discount Amount"
      defaultMessage="Discount Amount"
    />
  ),
  discountPercentage: (
    <FormattedMessage
      id="order.discountPercentage"
      description="Discount %"
      defaultMessage="Discount %"
    />
  ),
  cartDiscount: (
    <FormattedMessage
      id="order.cartDiscount"
      description="Cart Discount"
      defaultMessage="Cart Discount"
    />
  ),
  freeCampaignOrder: (
    <FormattedMessage
      id="order.freeCampaignOrder"
      description="The total amount shown is exclusive of discount. Campaign orders are for free."
      defaultMessage="The total amount shown is exclusive of discount. Campaign orders are for free."
    />
  ),
  totalAmount: (
    <FormattedMessage
      id="order.totalAmount"
      description="Total Amount"
      defaultMessage="Total Amount"
    />
  ),
  tierDiscount: (
    <FormattedMessage
      id="order.tierDiscount"
      description="Tier Discount"
      defaultMessage="Tier Discount"
    />
  ),
  discountedAmount: (
    <FormattedMessage
      id="order.discountedAmount"
      description="Discounted Amount"
      defaultMessage="Discounted Amount"
    />
  ),
  totalAmountMRP: (
    <FormattedMessage
      id="order.totalAmountMRP"
      description="Total Amount (MRP)"
      defaultMessage="Total Amount (MRP)"
    />
  ),
  mrp: (
    <FormattedMessage id="order.mrp" description="MRP" defaultMessage="MRP" />
  ),
  paymentAmount: (
    <FormattedMessage
      id="order.paymentAmount"
      description="Payment Amount"
      defaultMessage="Payment Amount"
    />
  ),
  createOrder: (
    <FormattedMessage
      id="order.createOrder"
      description="Create Order"
      defaultMessage="Create Order"
    />
  ),
  createChannel: (
    <FormattedMessage
      id="order.createChannel"
      description="Create Channel"
      defaultMessage="Create Channel"
    />
  ),
  channelSearch: (
    <FormattedMessage
      id="order.channelSearch"
      description="by Name/ID"
      defaultMessage="by Name/ID"
    />
  ),
  createResellerOrder: (
    <FormattedMessage
      id="order.createResellerOrder"
      description="Create Reseller Order"
      defaultMessage="Create Reseller Order"
    />
  ),
  customername: (
    <FormattedMessage
      id="order.customername"
      description="Customer Name"
      defaultMessage="Customer Name"
    />
  ),
  searchbyCustomerNameandPhone: (
    <FormattedMessage
      id="order.searchbyCustomerNameandPhone"
      description="Search by Customer Name / Phone Number"
      defaultMessage="Search by Customer Name / Phone Number"
    />
  ),
  searchbyResellerPhoneAndEmail: (
    <FormattedMessage
      id="order.searchbyResellerPhoneAndEmail"
      description="Search by Reseller Phone Number/ Email Id"
      defaultMessage="Search by Reseller Phone Number/ Email Id"
    />
  ),
  searchbyChannelName: (
    <FormattedMessage
      id="order.searchbyChannelName"
      description="Search by Channel Name/ID"
      defaultMessage="Search by Channel Name/ID"
    />
  ),
  addItems: (
    <FormattedMessage
      id="order.addItems"
      description="Add Items"
      defaultMessage="Add Items"
    />
  ),
  apply: (
    <FormattedMessage
      id="order.apply"
      description="Apply"
      defaultMessage="Apply"
    />
  ),
  viaMarketPlace: (
    <FormattedMessage
      id="order.viaMarketPlace"
      description="Via Marketplace?"
      defaultMessage="Via Marketplace?"
    />
  ),
  chooseSalesChannel: (
    <FormattedMessage
      id="order.chooseSalesChannel"
      description="Choose Store/Channel"
      defaultMessage="Choose Store/Channel"
    />
  ),
  salesChannel: (
    <FormattedMessage
      id="order.salesChannel"
      description="Sales Channel"
      defaultMessage="Sales Channel"
    />
  ),
  campaignOrder: (
    <FormattedMessage
      id="order.campaignOrder"
      description="Campaign Order"
      defaultMessage="Campaign Order"
    />
  ),
  bankTranseferOrNot: (
    <FormattedMessage
      id="order.bankTranseferOrNot"
      description="This is a bank transfer"
      defaultMessage="This is a bank transfer"
    />
  ),
  campaignOrNot: (
    <FormattedMessage
      id="order.campaignOrNot"
      description="Is it a campaign?"
      defaultMessage="Is it a campaign?"
    />
  ),
  uploadReceipt: (
    <FormattedMessage
      id="order.uploadReceipt"
      description="Upload receipt"
      defaultMessage="Upload receipt"
    />
  ),
  receipt: (
    <FormattedMessage
      id="order.Receipt"
      description="Receipt"
      defaultMessage="Receipt"
    />
  ),
  uploadShippingLabel: (
    <FormattedMessage
      id="order.uploadShippingLabel"
      description="Upload Shipping label"
      defaultMessage="Upload Shipping label"
    />
  ),
  total: (
    <FormattedMessage
      id="order.total"
      description="Total"
      defaultMessage="Total"
    />
  ),
  subTotal: (
    <FormattedMessage
      id="order.subTotal"
      description="Sub Total"
      defaultMessage="Sub Total"
    />
  ),
  stock: (
    <FormattedMessage
      id="order.stock"
      description="Stock"
      defaultMessage="Stock"
    />
  ),
  paymentLink: (
    <FormattedMessage
      id="order.paymentLink"
      description="Payment Link"
      defaultMessage="Payment Link"
    />
  ),
  regenerateLink: (
    <FormattedMessage
      id="order.regenerateLink"
      description="Regenerate Link"
      defaultMessage="Regenerate Link"
    />
  ),
  linkGenerationSuccess: (
    <FormattedMessage
      id="order.linkGenerationSuccess"
      description="The payment link has been regenerated."
      defaultMessage="The payment link has been regenerated."
    />
  ),
  orderCreationSuccessful: (
    <FormattedMessage
      id="order.orderCreationSuccessful"
      description="The order has been created successfully!"
      defaultMessage="The order has been created successfully!"
    />
  ),
  preorderCreationSuccessful: (
    <FormattedMessage
      id="order.preorderCreationSuccessful"
      description="The order (PO) has been created successfully!"
      defaultMessage="The order (PO) has been created successfully!"
    />
  ),
  channelCreationSuccessful: (
    <FormattedMessage
      id="order.channelCreationSuccessful"
      description="The channel has been created successfully!"
      defaultMessage="The channel has been created successfully!"
    />
  ),
  channelUpdateSuccessful: (
    <FormattedMessage
      id="order.channelUpdateSuccessful"
      description="The channel has been updated successfully!"
      defaultMessage="The channel has been updated successfully!"
    />
  ),
  hereIsPaymentLink: (
    <FormattedMessage
      id="order.hereIsPaymentLink"
      description="Here's the payment link."
      defaultMessage="Here's the payment link."
    />
  ),
  preorderExpectedArrival: (
    <FormattedMessage
      id="order.preorderExpectedArrival"
      description="Pre-order - Expected Arrival"
      defaultMessage="Pre-order - Expected Arrival"
    />
  ),
  action: (
    <FormattedMessage
      id="order.action"
      description="Action"
      defaultMessage="Action"
    />
  ),
  dateAndTime: (
    <FormattedMessage
      id="order.dateAndTime"
      description="Date & Time"
      defaultMessage="Date & Time"
    />
  ),
  exportingMightTakeLongTime: (
    <FormattedMessage
      id="order.exportingMightTakeLongTime"
      description="Exporting might take a long time."
      defaultMessage="Exporting might take a long time."
    />
  ),
  goToExportHistory: (
    <FormattedMessage
      id="order.goToExportHistory"
      description="Go to export history"
      defaultMessage="Go to export history"
    />
  ),
  exportedBy: (
    <FormattedMessage
      id="order.exportedBy"
      description="Exported By"
      defaultMessage="Exported By"
    />
  ),
  couponApplied: (
    <FormattedMessage
      id="order.couponApplied"
      description="Coupon applied"
      defaultMessage="Coupon applied"
    />
  ),
  promoPriceApplied: (
    <FormattedMessage
      id="order.promoPriceApplied"
      description="Promo price applied"
      defaultMessage="Promo price applied"
    />
  ),
  dynamicPriceApplied: (
    <FormattedMessage
      id="order.dynamicPriceApplied"
      description="Dynamic price applied"
      defaultMessage="Dynamic price applied"
    />
  ),
  shipping: (
    <FormattedMessage
      id="order.shipping"
      description="Shipping"
      defaultMessage="Shipping"
    />
  ),
  shippingFee: (
    <FormattedMessage
      id="order.shippingFee"
      description="Shipping fee"
      defaultMessage="Shipping fee"
    />
  ),
  shippingType: (
    <FormattedMessage
      id="order.shippingType"
      description="Shipping Type"
      defaultMessage="Shipping Type"
    />
  ),
  shippingPercent: (
    <FormattedMessage
      id="order.shippingPercent"
      description="% saving"
      defaultMessage="% saving"
    />
  ),
  shippingPaidBy: (
    <FormattedMessage
      id="order.shippingPaidBy"
      description="Shipping fee paid by"
      defaultMessage="Shipping fee paid by"
    />
  ),
  shippingPaidByRaena: (
    <FormattedMessage
      id="order.shippingPaidByRaena"
      description="Raena:"
      defaultMessage="Raena:"
    />
  ),
  shippingPaidByCustomer: (
    <FormattedMessage
      id="order.shippingPaidByCustomer"
      description="Customer:"
      defaultMessage="Customer:"
    />
  ),
  shippingTypeChange: (
    <FormattedMessage
      id="order.shippingTypeChange"
      description="Change"
      defaultMessage="Change"
    />
  ),
  raena: (
    <FormattedMessage
      id="order.raena"
      description="Raena"
      defaultMessage="Raena"
    />
  ),
  shippingPaid: (
    <FormattedMessage
      id="order.shippingPaid"
      description="Shipping fee paid by"
      defaultMessage="shipping fee paid by"
    />
  ),
  shipmentStatus: (
    <FormattedMessage
      id="order.shipmentStatus"
      description="Shipment Status"
      defaultMessage="Shipment Status"
    />
  ),
  estimatedShippingDate: (estimatedDate) => (
    <FormattedMessage
      id="order.estimatedShippingDate"
      description="Est. Arrival: {estimatedDate}"
      defaultMessage="Est. Arrival:  {estimatedDate}"
      values={{
        estimatedDate,
      }}
    />
  ),
};

export const transactionTranslations = {
  transactionId: (
    <FormattedMessage
      id="transaction.transactionId"
      description="Transaction ID"
      defaultMessage="Transaction ID"
    />
  ),
  orderIdOrTransactionId: (
    <FormattedMessage
      id="transaction.orderIdOrTransactionId"
      description="Order ID / Transaction ID"
      defaultMessage="Order ID / Transaction ID"
    />
  ),
  paymentDate: (
    <FormattedMessage
      id="transaction.paymentDate"
      description="Payment Date"
      defaultMessage="Payment Date"
    />
  ),
  paymentDetails: (
    <FormattedMessage
      id="transaction.paymentDetails"
      description="Payment Details"
      defaultMessage="Payment Details"
    />
  ),
  resellerName: (
    <FormattedMessage
      id="transaction.resellerName"
      description="Reseller name"
      defaultMessage="Reseller name"
    />
  ),
  paymentStatus: (
    <FormattedMessage
      id="transaction.paymentStatus"
      description="Payment Status"
      defaultMessage="Payment Status"
    />
  ),
  providerStatus: (
    <FormattedMessage
      id="transaction.providerStatus"
      description="Provider Status"
      defaultMessage="Provider Status"
    />
  ),
  paymentType: (
    <FormattedMessage
      id="transaction.paymentType"
      description="Payment Type"
      defaultMessage="Payment Type"
    />
  ),
  couponValidationFailed: (
    <FormattedMessage
      id="transaction.couponValidationFailed"
      description="Coupon Validation Failed!"
      defaultMessage="Coupon Validation Failed!"
    />
  ),
  couponValidationFailedBody: (
    <FormattedMessage
      id="transaction.couponValidationFailedBody"
      description="Changing payment status of this order requires
     overriding some {br} constraints of the coupon applied.
     Sometimes this may result in {br} the coupon being used more times
      than the configured limit.{br} Are you sure you want to continue?"
      defaultMessage="Changing payment status of this order requires
    overriding some {br} constraints of the coupon applied.
    Sometimes this may result in {br} the coupon being used more times
     than the configured limit.{br} Are you sure you want to continue?"
      values={{
        br: <br />,
      }}
    />
  ),
};

export const redeemTranslations = {
  priority: (
    <FormattedMessage
      id="redeem.priority"
      description="Priority"
      defaultMessage="Priority"
    />
  ),
  deleteGift: (
    <FormattedMessage
      id="redeem.deleteGift"
      description="Do you really want to delist the gift?"
      defaultMessage="Do you really want to delist the gift?"
    />
  ),
  description: (
    <FormattedMessage
      id="redeem.terms"
      description="Description"
      defaultMessage="Description"
    />
  ),
  terms: (
    <FormattedMessage
      id="redeem.terms"
      description="Terms"
      defaultMessage="Terms"
    />
  ),
  endDate: (
    <FormattedMessage
      id="redeem.endDate"
      description="Promotion End Date"
      defaultMessage="Promotion End Date"
    />
  ),
  startDate: (
    <FormattedMessage
      id="redeem.startDate"
      description="Promotion Start Date"
      defaultMessage="Promotion Start Date"
    />
  ),
  editGift: (
    <FormattedMessage
      id="redeem.editGift"
      description="Edit Gift"
      defaultMessage="Edit Gift"
    />
  ),
  giftImage: (
    <FormattedMessage
      id="redeem.giftImage"
      description="Gift Image"
      defaultMessage="Gift Image"
    />
  ),
  createdDate: (
    <FormattedMessage
      id="redeem.createdDate"
      description="Created Date"
      defaultMessage="Created Date"
    />
  ),
  giftName: (
    <FormattedMessage
      id="redeem.giftName"
      description="Gift Name"
      defaultMessage="Gift Name"
    />
  ),
  giftId: (
    <FormattedMessage
      id="redeem.giftId"
      description="Gift ID"
      defaultMessage="Gift ID"
    />
  ),
  points: (
    <FormattedMessage
      id="redeem.points"
      description="Points"
      defaultMessage="Points"
    />
  ),
};

export const brandTranslations = {
  brandName: (
    <FormattedMessage
      id="brand.brandName"
      description="Brand Name"
      defaultMessage="Brand Name"
    />
  ),
  addPromotionalRibbon: (
    <FormattedMessage
      id="brand.addPromotionalRibbon"
      description="Add Promotional Ribbon"
      defaultMessage="Add Promotional Ribbon"
    />
  ),
  logo: (
    <FormattedMessage
      id="brand.logo"
      description="Logo"
      defaultMessage="Logo"
    />
  ),
  thumbnail: (
    <FormattedMessage
      id="brand.thumbnail"
      description="Thumbnail"
      defaultMessage="Thumbnail"
    />
  ),
  banner: (
    <FormattedMessage
      id="brand.banner"
      description="Banner"
      defaultMessage="Banner"
    />
  ),
  action: (
    <FormattedMessage
      id="brand.action"
      description="Action"
      defaultMessage="Action"
    />
  ),
  enterTheNumber: (
    <FormattedMessage
      id="brand.enterTheNumber"
      description="Enter the number"
      defaultMessage="Enter the number"
    />
  ),
  maximumOrderCountWarning: (
    <FormattedMessage
      id="brand.maximumOrderCountWarning"
      description="Currently available maximum priority is"
      defaultMessage="Currently available maximum priority is"
    />
  ),
  updatedAt: (
    <FormattedMessage
      id="brand.updatedAt"
      description="Updated At"
      defaultMessage="Updated At"
    />
  ),
  priority: (
    <FormattedMessage
      id="brand.priority"
      description="Priority"
      defaultMessage="Priority"
    />
  ),
  country: (
    <FormattedMessage
      id="brand.country"
      description="Country"
      defaultMessage="Country"
    />
  ),
  brandId: (
    <FormattedMessage
      id="brand.brandId"
      description="Brand ID"
      defaultMessage="Brand ID"
    />
  ),
  noItemsPresent: (
    <FormattedMessage
      id="brand.noItemsPresent"
      description="No items present. Please Drag and drop items."
      defaultMessage="No items present. Please Drag and drop items."
    />
  ),
  productsUnderBrand: (
    <FormattedMessage
      id="brand.productsUnderBrand"
      description="Products Under Brand"
      defaultMessage="Products Under Brand"
    />
  ),
  productsUnderGivenBrand: (brandName) => (
    <FormattedMessage
      id="brand.productsUnderBrand"
      description="Products Under {brandName}"
      defaultMessage="Products Under {brandName}"
      values={{
        brandName: brandName || "",
      }}
    />
  ),
};

export const limitTranslation = (limit) => (
  <FormattedMessage
    id="error.exceededLimit"
    description="Maximum value allowed is {limitValue}"
    defaultMessage="Maximum value allowed is {limitValue}"
    values={{
      limitValue: limit,
    }}
  />
);

export const imageSpecificationTranslation = (type, dimension) => {
  switch (type) {
    case "Category_Carousel":
      return (
        <FormattedMessage
          id="dialog.categoryCarouselSpecification"
          description="(Specs: jpg, circular image, Size: {dimension})"
          defaultMessage="(Specs: jpg, circular image, Size: {dimension})"
          values={{
            dimension,
          }}
        />
      );
    default: {
      if (dimension) {
        return (
          <FormattedMessage
            id="dialog.bannerSpecification"
            description="(Specs: jpg, png, Size: {dimension})"
            defaultMessage="(Specs: jpg, png, Size: {dimension})"
            values={{
              dimension,
            }}
          />
        );
      }
      return "";
    }
  }
};

export const couponStatusChangeTranslation = (couponDetails) => {
  const ACTIVE = "Active";

  return (
    <FormattedMessage
      id="statusChangeDialogMessage"
      description="Do you want to {statusValue} {couponName} Coupon?"
      defaultMessage="Do you want to {statusValue} {couponName} <b>Coupon</b>?"
      values={{
        b: (chunks) => <b>{chunks}</b>,
        statusValue: `${
          !isEmpty(couponDetails) && couponDetails.status === ACTIVE
            ? "deactivate"
            : "activate"
        }`,
        couponName: (
          <b>{(!isEmpty(couponDetails) && couponDetails.title) || ""}</b>
        ),
      }}
    />
  );
};

export const errorTranslations = {
  invalidName: (
    <FormattedMessage
      id="manage-payments:invalid-name"
      description="Invalid name"
      defaultMessage="Invalid name"
    />
  ),
  noStock: (
    <FormattedMessage
      id="product-details:no-stock-available"
      description="Out of Stock"
      defaultMessage="Out of Stock"
    />
  ),
  invalidPhone: (
    <FormattedMessage
      id="error.invalidPhone"
      description="Invalid phone"
      defaultMessage="Invalid phone"
    />
  ),
  shouldBeGreaterThanCurrentTime: (
    <FormattedMessage
      id="error.shouldBeGreaterThanCurrentTime"
      description="Should be greater than current time"
      defaultMessage="Should be greater than current time"
    />
  ),
  excludedProductsCannotIncludeAllProducts: (
    <FormattedMessage
      id="error.excludedProductsCannotIncludeAllProducts"
      description="Excluded products cannot include all products under the selected brands"
      defaultMessage="Excluded products cannot include all products under the selected brands"
    />
  ),
  itemsCountShouldBeGreaterThanZero: (
    <FormattedMessage
      id="error.itemsCountShouldBeGreaterThanZero"
      description="Items count should be greater than zero"
      defaultMessage="Items count should be greater than zero"
    />
  ),
  XTasksMustBeAdded: (count) => (
    <FormattedMessage
      id="common.XTasksMustBeAdded"
      description="{count} tasks should be added"
      defaultMessage="{count} tasks should be added"
      values={{ count }}
    />
  ),
  required: (
    <FormattedMessage
      id="error.required"
      description="Required field"
      defaultMessage="Required field"
    />
  ),
  quantityCantBeFulfilled: (
    <FormattedMessage
      id="error.quantityCantBeFulfilled"
      description="Selected quantity can't be fulfilled"
      defaultMessage="Selected quantity can't be fulfilled"
    />
  ),
  priceSumError: () => (
    <FormattedMessage
      id="error.priceSumError"
      description="Bundle price should be lesser than the sum of individual {value} prices of the products"
      defaultMessage="Bundle price should be lesser than the sum of individual {value} prices of the products"
    />
  ),
  selectOne: (
    <FormattedMessage
      id="error.selectOne"
      description="Must select atleast one item."
      defaultMessage="Must select atleast one item."
    />
  ),
  passwordsNotMatch: (
    <FormattedMessage
      id="error.passwordsNotMatch"
      description="Passwords did not match"
      defaultMessage="Passwords did not match"
    />
  ),
  passwordError: (
    <FormattedMessage
      id="error.passwordError"
      description="Password doesn't meet the requirements."
      defaultMessage="Password doesn't meet the requirements."
    />
  ),
  yearShouldBeBetween2020And2030: (
    <FormattedMessage
      id="error.yearShouldBeBetween2020And2030"
      description="Please choose a year between 2020 and 2030"
      defaultMessage="Please choose a year between 2020 and 2030"
    />
  ),
  wholesalePriceGreater: (
    <FormattedMessage
      id="error.wholesalePriceGreater"
      description="Wholesale price cannot be greater than retail price"
      defaultMessage="Wholesale price cannot be greater than retail price"
    />
  ),
  validStockLimit: (
    <FormattedMessage
      id="error.validStockLimit"
      description="Cannot be less than reserved quantity"
      defaultMessage="Cannot be less than reserved quantity"
    />
  ),
  enterFourDigitYear: (
    <FormattedMessage
      id="error.enterFourDigitYear"
      description="Please enter a four digit year"
      defaultMessage="Please enter a four digit year"
    />
  ),
  commisionGreaterThan100: (
    <FormattedMessage
      id="error.commisionGreaterThan100"
      description="The margin can't be more than 100%."
      defaultMessage="The margin can't be more than 100%."
    />
  ),
  validSKU: (
    <FormattedMessage
      id="error.validSKU"
      description="Please enter valid SKU"
      defaultMessage="Please enter valid SKU"
    />
  ),
  choosePreorderOption: (
    <FormattedMessage
      id="error.choosePreorderOption"
      description="Please choose an option to proceed further"
      defaultMessage="Please choose an option to proceed further"
    />
  ),
  validNumber: (
    <FormattedMessage
      id="error.validNumber"
      description="Please choose a valid number"
      defaultMessage="Please choose a valid number"
    />
  ),
  greaterThanZero: (
    <FormattedMessage
      id="error.greaterThanZero"
      description="Value must be greater than zero"
      defaultMessage="Value must be greater than zero"
    />
  ),
  notLessThanMSP: (
    <FormattedMessage
      id="error.notLessThanMSP"
      description="Value must not be less than Floor Price"
      defaultMessage="Value must not be less than Floor Price"
    />
  ),
  previousDate: (
    <FormattedMessage
      id="error.previousDate"
      description="Previous date is not allowed"
      defaultMessage="Previous date is not allowed"
    />
  ),
  sameDate: (
    <FormattedMessage
      id="error.sameDate"
      description="Expiry Date can not be less than or same as Start Date"
      defaultMessage="Expiry Date can not be less than or same as Start Date"
    />
  ),
  insideSpecifiedDateRange: (
    <FormattedMessage
      id="error.insideSpecifiedDateRange"
      description="Date must fall in the specified date range"
      defaultMessage="Date must fall in the specified date range"
    />
  ),
  validCode: (
    <FormattedMessage
      id="error.validCode"
      description="Please enter valid coupon code"
      defaultMessage="Please enter valid coupon code"
    />
  ),
  couponCodeLength: (
    <FormattedMessage
      id="error.couponCodeLength"
      description="The length must be between 5 and 15"
      defaultMessage="The length must be between 5 and 15"
    />
  ),
  disountTitleLength: (
    <FormattedMessage
      id="error.disountTitleLength"
      description="The length must be between 5 and 50"
      defaultMessage="The length must be between 5 and 50"
    />
  ),
  validDiscountTitle: (
    <FormattedMessage
      id="error.validDiscountTitle"
      description="Please enter valid title"
      defaultMessage="Please enter valid title"
    />
  ),
  greaterThanValidFrom: (
    <FormattedMessage
      id="error.greaterThanValidFrom"
      description="Value must be greater than valid from date"
      defaultMessage="Value must be greater than valid from date"
    />
  ),
  greaterThanStartDateTime: (
    <FormattedMessage
      id="error.greaterThanValidFrom"
      description="Value must be greater than start date and time"
      defaultMessage="Value must be greater than start date and time"
    />
  ),
  minimumShouldbeLessThanMaximum: (
    <FormattedMessage
      id="error.minimumShouldbeLessThanMaximum"
      description="Value must be less than maximum amount"
      defaultMessage="Value must be less than maximum amount"
    />
  ),
  validUsageCount: (
    <FormattedMessage
      id="error.validUsageCount"
      description="Must be greater than or equal to no. of usage per person"
      defaultMessage="Must be greater than or equal to no. of usage per person"
    />
  ),
  imageNotUploaded: (
    <FormattedMessage
      id="error.imageNotUploaded"
      description="Upload an image"
      defaultMessage="Upload an image"
    />
  ),
  invalidId: (
    <FormattedMessage
      id="error.invalidId"
      description="Invalid Id"
      defaultMessage="Invalid Id"
    />
  ),
  mustContainOnlyCapitalLettersAndNumbers: (
    <FormattedMessage
      id="error.mustContainOnlyCapitalLettersAndNumbers"
      description="Coupon code must contain only capital letters and numbers."
      defaultMessage="Coupon code must contain only capital letters and numbers."
    />
  ),
  greaterthanPreviousValue: (value) => (
    <FormattedMessage
      id="error.greaterthanPreviousValue"
      description="Must be greater than or equal to {value}"
      defaultMessage="Must be greater than or equal to {value}"
      values={{ value }}
    />
  ),
  lesserthanPreviousValue: (value) => (
    <FormattedMessage
      id="error.lesserthanPreviousValue"
      description="Must be lesser than or equal to {value}"
      defaultMessage="Must be lesser than or equal to {value}"
      values={{ value }}
    />
  ),
  totalItemsAdded: (type, count) => (
    <FormattedMessage
      id="discount.totalItemsAdded"
      description="items count"
      defaultMessage="Total {type}'s added - {count}"
      values={{ type, count }}
    />
  ),
  promoPriceShouldBeLess: (
    <FormattedMessage
      id="error.promoPriceShouldBeLess"
      description="Promo price cannot be greater than the corresponding wholesale price"
      defaultMessage="Promo price cannot be greater than the corresponding wholesale price"
    />
  ),
  resellerAddressNotAvailable: (
    <FormattedMessage
      id="error.resellerAddressNotAvailable"
      description="Reseller address is not available"
      defaultMessage="Reseller address is not available"
    />
  ),
  customerAddressNotAvailable: (
    <FormattedMessage
      id="error.customerAddressNotAvailable"
      description="Customer address is not available"
      defaultMessage="Customer address is not available"
    />
  ),
  wholesalePriceError: () => (
    <FormattedMessage
      id="product.wholesalePriceError"
      description="Wholesale Price Error"
      defaultMessage="Wholesale price {currentTier} cannot be less than wholesale price {higherTier}"
    />
  ),
  promoPriceError: () => (
    <FormattedMessage
      id="product.promoPriceError"
      description="Promo Price Error"
      defaultMessage="Promo price {currentTier} cannot be less than promo price {higherTier}"
    />
  ),
  flashPriceError: (tierA, tierB) => (
    <FormattedMessage
      id="product.flashPriceError"
      description="Flash Price Error"
      defaultMessage="Flash sale price {tierA} cannot be less than flash sale price {tierB}"
      values={{ tierA: toLower(tierA), tierB: toLower(tierB) }}
    />
  ),
  negotiatedPriceError: (
    <FormattedMessage
      id="error.negotiatedPriceError"
      description="Low Negotiated Price"
      defaultMessage="Low Negotiated Price"
    />
  ),
  enterValidEmail: (
    <FormattedMessage
      id="error.enterValidEmail"
      description="Enter a valid email address"
      defaultMessage="Enter a valid email address"
    />
  ),
  enterValidUserName: (
    <FormattedMessage
      id="error.enterValidEmail"
      description="Username must be a valid email address"
      defaultMessage="Username must be a valid email address"
    />
  ),
  noAccessToBrandList: (
    <FormattedMessage
      id="error.noAccessToBrandsList"
      description="Brand list access required"
      defaultMessage="Brand list access required"
    />
  ),
  noAccessToTierList: (
    <FormattedMessage
      id="error.noAccessToTierList"
      description="Tier list access required"
      defaultMessage="Tier list access required"
    />
  ),
  noAccessToReseller: (
    <FormattedMessage
      id="error.noAccessToReseller"
      description="Reseller access required"
      defaultMessage="Reseller access required"
    />
  ),
  noAccessToProducts: (
    <FormattedMessage
      id="error.noAccessToProducts"
      description="Products access required"
      defaultMessage="Products access required"
    />
  ),
  noAccessToChannels: (
    <FormattedMessage
      id="error.noAccessToChannels"
      description="Channels access required"
      defaultMessage="Channels access required"
    />
  ),
  inValidField: (fieldName) => (
    <FormattedMessage
      id="error.inValidField"
      description="Please enter a valid value"
      defaultMessage="Please enter a valid {fieldName}"
      values={{ fieldName: lowerCase(fieldName) }}
    />
  ),
  characterLimit: (limit) => (
    <FormattedMessage
      id="error.characterLimit"
      description="Maximum allowed length is {limit}"
      defaultMessage="Maximum allowed length is {limit}"
      values={{
        limit,
      }}
    />
  ),
  cannotCreateFlashSale: (
    <FormattedMessage
      id="error.cannotCreateFlashSale"
      description="No more Flash sales can be created as flash sales already
    exists in either Created, Active, Running state"
      defaultMessage="No more Flash sales can be created as flash sales already
     exists in either Created, Active, Running state"
    />
  ),
  greaterThanStartDate: (
    <FormattedMessage
      id="error.greaterThanStartDate"
      description="Value must be greater than start date"
      defaultMessage="Value must be greater than start date"
    />
  ),
  timeRangeError: (
    <FormattedMessage
      id="error.timeRangeError"
      description="Atleast 5 minute time range required"
      defaultMessage="Atleast 5 minute time range required"
    />
  ),
  greaterThanCurrentTime: (
    <FormattedMessage
      id="error.timeRangeError"
      description="Atleast 5 minute greater than current time required"
      defaultMessage="Atleast 5 minute greater than current time required"
    />
  ),
  technicalError: (
    <FormattedMessage
      id="error.technicalError"
      description="We're experiencing some technical error {br}Please try again later"
      defaultMessage="We're experiencing some technical error {br}Please try again later"
      values={{
        br: <br />,
      }}
    />
  ),
  goToHomePage: (
    <FormattedMessage
      id="error.goToHomePage"
      description="Go to Home Page"
      defaultMessage="Go to Home Page"
    />
  ),
  instructionsRequired: (
    <FormattedMessage
      id="error.instructionsRequired"
      description="Instructions required"
      defaultMessage="Instructions required"
    />
  ),
  expiryTimeCannotBeZero: (
    <FormattedMessage
      id="error.expiryTimeCannotBeZero"
      description="Expiry time cannot be 0 Mins"
      defaultMessage="Expiry time cannot be 0 Mins"
    />
  ),
  paymentTimeCannotBeZero: (
    <FormattedMessage
      id="error.paymentTimeCannotBeZero"
      description="Payment time cannot be 0 Mins"
      defaultMessage="Payment time cannot be 0 Mins"
    />
  ),
  paymentTimeGreaterThanExpiryTime: (
    <FormattedMessage
      id="error.paymentTimeGreaterThanExpiryTime"
      description="Payment time must be greater than expiry time"
      defaultMessage="Payment time must be greater than expiry time"
    />
  ),
  notificationThresholdUpperLimit: (limit) => (
    <FormattedMessage
      id="error.notificationThresholdUpperLimit"
      description="No of days cannot be greater than {limit}"
      defaultMessage="No of days cannot be greater than {limit}"
      values={{
        limit,
      }}
    />
  ),
  notificationThresholdLowerLimit: (limit) => (
    <FormattedMessage
      id="error.notificationThresholdLowerLimit"
      description="No of days cannot be less than {limit}"
      defaultMessage="No of days cannot be less than {limit}"
      values={{
        limit,
      }}
    />
  ),
  greaterThanMinAmountLimit: (
    <FormattedMessage
      id="error.greaterThanMinAmountLimit"
      description="Max. amount limit must be greater than Min. amount limit"
      defaultMessage="Max. amount limit must be greater than Min. amount limit"
    />
  ),
  inValidLink: (
    <FormattedMessage
      id="error.inValidLink"
      description="Invalid link"
      defaultMessage="Invalid link"
    />
  ),
  requiredShippingType: (
    <FormattedMessage
      id="error.requiredShippingType"
      description="Select atleast one shipping type"
      defaultMessage="Select atleast one shipping type"
    />
  ),
  greaterthanPreviousField: (fieldName) => (
    <FormattedMessage
      id="error.greaterthanPreviousField"
      description="Must be greater than {fieldName}"
      defaultMessage="Must be greater than {fieldName}"
      values={{ fieldName }}
    />
  ),
  uploadCSVFile: (
    <FormattedMessage
      id="upload.uploadCSVFile"
      description="Upload CSV File"
      defaultMessage="Upload CSV File"
    />
  ),
  fileUploadError: (
    <FormattedMessage
      id="upload.fileUploadError"
      description="Failed to upload file"
      defaultMessage="Failed to upload file"
    />
  ),
};

export const notificationTranslations = {
  sectionsReorderedSuccessfully: (
    <FormattedMessage
      id="notification.sectionsReorderedSuccessfully"
      description="Sections reordered successfully"
      defaultMessage="Sections reordered successfully"
    />
  ),
  rulesReorderedSuccessfully: (
    <FormattedMessage
      id="notification.rulesReorderedSuccessfully"
      description="Rules reordered successfully"
      defaultMessage="Rules reordered successfully"
    />
  ),
  statusChangeSuccess: (
    <FormattedMessage
      id="notification.statusChangeSuccess"
      description="Status Changed Successfully"
      defaultMessage="Status Changed Successfully"
    />
  ),
  reInviteSuccess: (
    <FormattedMessage
      id="notification.reInviteSuccess"
      description="Re-invite link generated successfully"
      defaultMessage="Re-invite link generated successfully"
    />
  ),
  orderStopSuccess: (
    <FormattedMessage
      id="notification.orderStopSuccess"
      description="Stopped all order creations"
      defaultMessage="Stopped all order creations"
    />
  ),
  orderRestartSuccess: (
    <FormattedMessage
      id="notification.orderRestartSuccess"
      description="Restarted all order creations"
      defaultMessage="Restarted all order creations"
    />
  ),
  channelDeleteSuccess: (
    <FormattedMessage
      id="notification.channelDeleteSuccess"
      description="Channel Deleted Successfully"
      defaultMessage="Channel Deleted Successfully"
    />
  ),
  switchOnLegacySuccess: (
    <FormattedMessage
      id="notification.switchOnLegacySuccess"
      description="Successfully switched to legacy payment mode"
      defaultMessage="Successfully switched to legacy payment mode"
    />
  ),
  switchToAppPaymentSuccess: (
    <FormattedMessage
      id="notification.switchToAppPaymentSuccess"
      description="Successfully switched to App payment mode"
      defaultMessage="Successfully switched to App payment mode"
    />
  ),
  productSyncStarted: (
    <FormattedMessage
      id="notification.productSyncStarted"
      description="Catalog feed started.Please check progress in Unbxd console"
      defaultMessage="Catalog feed started.Please check progress in Unbxd console"
    />
  ),
  updatedDefaultShippingSuccess: (
    <FormattedMessage
      id="notification.updatedDefaultShippingSuccess"
      description="Successfully updated default shipping type"
      defaultMessage="Successfully updated default shipping type"
    />
  ),
};

export const uploadTranslations = {
  uploadThumbnailImage: (
    <FormattedMessage
      id="upload.uploadThumbnailImage"
      description="Upload thumbnail image"
      defaultMessage="Upload thumbnail image"
    />
  ),
  uploadProductImage: (
    <FormattedMessage
      id="upload.uploadProductImage"
      description="Upload product image"
      defaultMessage="Upload product image"
    />
  ),
  uploadLogoImage: (
    <FormattedMessage
      id="upload.uploadLogoImage"
      description="Upload brand logo"
      defaultMessage="Upload brand logo"
    />
  ),
  uploadRedeemImage: (
    <FormattedMessage
      id="upload.uploadRedeemImage"
      description="Upload Redeem Image"
      defaultMessage="Upload Redeem Image"
    />
  ),
  uploadBannerImage: (
    <FormattedMessage
      id="upload.uploadBannerImage"
      description="Upload banner image"
      defaultMessage="Upload banner image"
    />
  ),
  chooseFiles: (
    <FormattedMessage
      id="upload.chooseFiles"
      description="Choose files"
      defaultMessage="Choose files"
    />
  ),
  uploadCSVFile: (
    <FormattedMessage
      id="upload.uploadCSVFile"
      description="Upload CSV File"
      defaultMessage="Upload CSV File"
    />
  ),
  chooseCSVFile: (
    <FormattedMessage
      id="upload.chooseCSVFile"
      description="Choose CSV File"
      defaultMessage="Choose CSV File"
    />
  ),
  uploadXSLXFile: (
    <FormattedMessage
      id="upload.uploadXSLXFile"
      description="Upload XLSX File"
      defaultMessage="Upload XLSX File"
    />
  ),
  bulkUploadNote: (
    <FormattedMessage
      id="upload.bulkUploadNote"
      description="If Wholesale Prices in the sheet is lower then Floor Price,
    {br}The Prices set will be updated to Floor Price automatically"
      defaultMessage="If Wholesale Prices in the sheet is lower then Floor Price,
    {br}The Prices set will be updated to Floor Price automatically"
      values={{
        br: <br />,
      }}
    />
  ),
  chooseXSLXFile: (
    <FormattedMessage
      id="upload.chooseXSLXFile"
      description="Choose XLSX File"
      defaultMessage="Choose XLSX File"
    />
  ),
  uploadAndSaveCompleted: (
    <FormattedMessage
      id="upload.uploadAndSaveCompleted"
      description="Upload & Save completed"
      defaultMessage="Upload & Save completed"
    />
  ),
};

export const dialogTranslations = {
  unsavedChangedWillBeLost: (
    <FormattedMessage
      id="dialog.unsavedChangedWillBeLost"
      description="Please save the changes. Unsaved changes will be lost"
      defaultMessage="Please save the changes. Unsaved changes will be lost"
    />
  ),
  sectionsReArranged: (
    <FormattedMessage
      id="dialog.sectionsReArranged"
      description="Drag & re-arrange the sections. Kindly save once you're done with re-ordering."
      defaultMessage="Drag & re-arrange the sections. Kindly save once you're done with re-ordering."
    />
  ),
  rulesReArranged: (
    <FormattedMessage
      id="dialog.rulesReArranged"
      description="Drag & re-arrange the rules. Kindly save once you're done with re-ordering."
      defaultMessage="Drag & re-arrange the rules. Kindly save once you're done with re-ordering."
    />
  ),
  areYouSure: (
    <FormattedMessage
      id="dialog.areYouSure"
      description="Are you sure?"
      defaultMessage="Are you sure?"
    />
  ),
  productIsPartOfBundle: (
    <FormattedMessage
      id="dialog.productIsPartOfBundle"
      description="This product is a part of bundle. Bundle needs to be delisted to proceed further"
      defaultMessage="This product is a part of bundle. Bundle needs to be delisted to proceed further"
    />
  ),
  brandCantBeChanged: (
    <FormattedMessage
      id="dialog.brandCantBeChanged"
      description="Brand of the product can't be edited as it is a part of a bundle"
      defaultMessage="Brand of the product can't be edited as it is a part of a bundle"
    />
  ),
  dtpOffWarning: (stockType) => (
    <FormattedMessage
      id="dialog.dtpOffWarning"
      description="Changing to stock type as {stockType} will turn off {br}Dynamic Pricing. Do you want to continue?"
      defaultMessage="Changing to stock type as {stockType} will turn off {br}Dynamic Pricing. Do you want to continue?"
      values={{
        br: <br />,
        stockType,
      }}
    />
  ),
  editStatus: (
    <FormattedMessage
      id="dialog.editStatus"
      description="Edit Status"
      defaultMessage="Edit Status"
    />
  ),
  deleteWarning: (
    <FormattedMessage
      id="dialog.deleteWarning"
      description="Do you really want to delete these records ? This process cannot be undone"
      defaultMessage="Do you really want to delete these records ? This process cannot be undone"
    />
  ),
  statusChangeConfirm: (
    <FormattedMessage
      id="dialog.statusChangeConfirm"
      description="Do you really want to change status?"
      defaultMessage="Do you really want to change status?"
    />
  ),
  deleteConfirm: (name, emailId) => (
    <FormattedMessage
      id="dialog.deleteConfirm"
      description="Do you really want to delete {name} ( {emailId} ) ?"
      defaultMessage="Do you really want to delete {name}{br} ( {emailId} ) ?"
      values={{
        br: <br />,
        name,
        emailId,
      }}
    />
  ),
  reInviteConfirm: (emailId) => (
    <FormattedMessage
      id="dialog.deleteConfirm"
      description="Do you really want to re-generate the invitation
       link for {emailId} ? ( It revokes the current access )"
      defaultMessage="Do you really want to re-generate the {br} invitation
       link for {emailId} ?{br}  ( It revokes the current access )"
      values={{
        br: <br />,
        emailId,
      }}
    />
  ),
  orderStopConfirm: (action) => (
    <FormattedMessage
      id="dialog.orderStopConfirm"
      description="Do you really want to {action} all order{br} creation from the app & admin ?"
      defaultMessage="Do you really want to {action} all order{br} creation from the app & admin ?"
      values={{
        br: <br />,
        action,
      }}
    />
  ),
  unlistProductWarning: (
    <FormattedMessage
      id="dialog.unlistProductWarning"
      description="Do you really want to delist the product?"
      defaultMessage="Do you really want to delist the product?"
    />
  ),
  unlistProductSuccess: (
    <FormattedMessage
      id="dialog.unlistProductSuccess"
      description="The product has been delisted"
      defaultMessage="The product has been delisted"
    />
  ),
  listProductSuccess: (
    <FormattedMessage
      id="dialog.listProductSuccess"
      description="The product has been listed."
      defaultMessage="The product has been listed."
    />
  ),
  saveTheChanges: (
    <FormattedMessage
      id="dialog.saveTheChanges"
      description="Save the changes"
      defaultMessage="Save the changes?"
    />
  ),
  saveTheChangesDesc: (
    <FormattedMessage
      id="dialog.saveTheChangesDesc"
      description="The changes will be saved and the coupon will be activated."
      defaultMessage="The changes will be saved and the coupon will be activated."
    />
  ),
  discardChanges: (
    <FormattedMessage
      id="dialog.discardChanges"
      description="Discard the changes?"
      defaultMessage="Discard the changes?"
    />
  ),
  discardTheChangesDesc: (
    <FormattedMessage
      id="dialog.discardTheChangesDesc"
      description="The changes will be discarded."
      defaultMessage="The changes will be discarded."
    />
  ),
  discard: (
    <FormattedMessage
      id="dialog.discard"
      description="Discard"
      defaultMessage="Discard"
    />
  ),
  uploadImage: (
    <FormattedMessage
      id="dialog.uploadImage"
      description="Upload Image"
      defaultMessage="Upload Image"
    />
  ),
  addCategory: (
    <FormattedMessage
      id="dialog.addCategory"
      description="Add Category"
      defaultMessage="Add Category"
    />
  ),
  uploadXLSXFile: (
    <FormattedMessage
      id="dialog.uploadXLSXFile"
      description="Upload XLSX File"
      defaultMessage="Upload XLSX File"
    />
  ),
  noResults: (
    <FormattedMessage
      id="dialog.noResults"
      description=" no results"
      defaultMessage=" no results"
    />
  ),
  doYouWantToRemoveShippingLabel: (
    <FormattedMessage
      id="dialog.doYouWantToRemoveShippingLabel"
      description="Do you want to remove shipping label ?"
      defaultMessage="Do you want to remove shipping label ?"
    />
  ),
  doYouWantToRemoveBankReceipt: (
    <FormattedMessage
      id="dialog.doYouWantToRemoveBankReceipt"
      description="Do you want to remove bank receipt ?"
      defaultMessage="Do you want to remove bank receipt ?"
    />
  ),
  hideSectionSubText: (name, pageName) => (
    <FormattedMessage
      id="dialog.hideSectionSubText"
      description="Do you want to hide the {name} on {pageName}?"
      defaultMessage="Do you want to hide the {name} on {pageName}?"
      values={{ name, pageName }}
    />
  ),
  homePage: (
    <FormattedMessage
      id="dialog.homePage"
      description="Homepage"
      defaultMessage="Homepage"
    />
  ),
  brandPage: (
    <FormattedMessage
      id="dialog.brandPage"
      description="Brandpage"
      defaultMessage="Brandpage"
    />
  ),
  offersPage: (
    <FormattedMessage
      id="dialog.offersPage"
      description=" Offers Page"
      defaultMessage=" Offers Page"
    />
  ),
  paymentReceipt: (
    <FormattedMessage
      id="dialog.paymentReceipt"
      description="Payment Receipt"
      defaultMessage="Payment Receipt"
    />
  ),
  changeStatusTo: (
    <FormattedMessage
      id="dialog.changeStatusTo"
      description="Change status to"
      defaultMessage="Change status to"
    />
  ),
  paid: (
    <FormattedMessage
      id="dialog.paid"
      description="PAID"
      defaultMessage="PAID"
    />
  ),
  changeDropDownName: (name) => (
    <FormattedMessage
      id="dialog.changeDropDownName"
      description="The item-selection will be lost. Do you really want to change the section to {name}?"
      defaultMessage="The item-selection will be lost. Do you really want to change the section to {name}?"
      values={{ name }}
    />
  ),
  removeRibbon: (
    <FormattedMessage
      id="dialog.removeRibbon"
      description="Are you sure you want to delete the promotion banner?"
      defaultMessage="Are you sure you want to delete the promotion banner?"
    />
  ),
  activateFlashSale: (
    <FormattedMessage
      id="dialog.activateFlashSale"
      description="Flashsale gets activated and will{br} appear on app. Do you really want{br} to activate it?"
      defaultMessage="Flashsale gets activated and will{br} appear on app. Do you really want{br} to activate it?"
      values={{
        br: <br />,
      }}
    />
  ),
  activateFlashSaleWithWarning: (
    <FormattedMessage
      id="dialog.activateFlashSaleWithWarning"
      description="Flashsale gets activated and will appear on app. Current{br} stock is lower than the
     flashsale stock. Do you really{br} want to activate it?"
      defaultMessage="Flashsale gets activated and will appear on app. Current{br} stock is lower than the
     flashsale stock. Do you really{br} want to activate it?"
      values={{
        br: <br />,
      }}
    />
  ),
  deActivateFlashSale: (
    <FormattedMessage
      id="dialog.deActivateFlashSale"
      description="Flashsale gets deactivated and further{br} edit is not allowed.
     Do you really want to{br} change the status to Inactive?"
      defaultMessage="Flashsale gets deactivated and further{br} edit is not allowed.
     Do you really want to{br} change the status to Inactive?"
      values={{
        br: <br />,
      }}
    />
  ),
  cloneFlashSale: (
    <FormattedMessage
      id="dialog.cloneFlashSale"
      description="Are you sure you want to duplicate the Flashsale?{br} It requires a few changes to be done."
      defaultMessage="Are you sure you want to duplicate the Flashsale?{br} It requires a few changes to be done."
      values={{
        br: <br />,
      }}
    />
  ),
  hidePaymentMethodFromApp: (
    <FormattedMessage
      id="dialog.hidePaymentMethodFromApp"
      description="Do you want to hide the payment{br} method from App?"
      defaultMessage="Do you want to hide the payment{br} method from App?"
      values={{
        br: <br />,
      }}
    />
  ),
  showPaymentMethodInApp: (
    <FormattedMessage
      id="dialog.showPaymentMethodInApp"
      description="Do you want to show the payment{br} method in App?"
      defaultMessage="Do you want to show the payment{br} method in App?"
      values={{
        br: <br />,
      }}
    />
  ),
  switchOnLegacyPayment: (
    <FormattedMessage
      id="dialog.switchOnLegacyPayment"
      description="Switching on legacy would activate the Web{br} Payment flow in App. Do you want to proceed?"
      defaultMessage="Switching on legacy would activate the Web{br} Payment flow in App. Do you want to proceed?"
      values={{
        br: <br />,
      }}
    />
  ),
  switchOffLegacyPayment: (
    <FormattedMessage
      id="dialog.switchOffLegacyPayment"
      description="Switching off legacy would activate the App{br} Payment flow. Do you want to proceed?"
      defaultMessage="Switching off legacy would activate the App{br} Payment flow. Do you want to proceed?"
      values={{
        br: <br />,
      }}
    />
  ),
  hideScheduledSectionSubText: (name, pageName, time) => (
    <FormattedMessage
      id="dialog.hideScheduledSectionSubText"
      description="Do you want to hide the {name} on {pageName}{br}at {time}?"
      defaultMessage="Do you want to hide the {name} on {pageName}{br}at {time}?"
      values={{ name, pageName, time, br: <br /> }}
    />
  ),
  cloneCoupon: (
    <FormattedMessage
      id="dialog.cloneCoupon"
      description="Are you sure you want to duplicate the Coupon?{br} It requires a few changes to be done."
      defaultMessage="Are you sure you want to duplicate the Coupon?{br} It requires a few changes to be done."
      values={{
        br: <br />,
      }}
    />
  ),
  deleteCoupon: (couponCode) => (
    <FormattedMessage
      id="dialog.deleteCoupon"
      description="Do you really want to delete <b>{couponCode}</b>?"
      defaultMessage="Do you really want to delete <b>{couponCode}</b>?"
      values={{
        couponCode,
        b: (text) => <b>{text}</b>,
      }}
    />
  ),
  csvRemoveWarning: (
    <FormattedMessage
      id="dialog.csvRemoveWarning"
      description="The CSV file will be removed if you have already added any file earlier.
     Do you still want to proceed with adding the products?"
      defaultMessage="The CSV file will be removed if you have already added any file earlier.
     Do you still want to proceed with adding the products?"
    />
  ),
  productRemoveWarning: (
    <FormattedMessage
      id="dialog.productRemoveWarning"
      description="The product those have been  added already will be removed.
     Do you still want to proceed with the uploading?"
      defaultMessage="The product those have been  added already will be removed.
     Do you still want to proceed with the uploading?"
    />
  ),
  userBlockingWarning: (action, name) => (
    <FormattedMessage
      id="dialog.userBlockingWarning"
      description="Do you really want to {action}
      reseller with name {name} ?"
      defaultMessage="Do you really want to {action}
      reseller with name {name} ?"
      values={{
        name: <b>{`${name || ""}`}</b>,
        action: <b>{`${action || ""}`}</b>,
      }}
    />
  ),
};

export const tierTranslations = {
  minimumSale: (
    <FormattedMessage
      id="tier.minimumSale"
      description="Minimum Sale"
      defaultMessage="Minimum Sale"
    />
  ),
  tierDesc: (
    <FormattedMessage
      id="tier.tierDesc"
      description="Tier gets updated based on the sales of last 30days."
      defaultMessage="Tier gets updated based on the sales of last 30days."
    />
  ),
  hideSectionSubText: (
    <FormattedMessage
      id="tier.tierDesc"
      description="Tier gets updated based on the sales of last 30days."
      defaultMessage="Tier gets updated based on the sales of last 30days."
    />
  ),
  notification: (
    <FormattedMessage
      id="tier.notification"
      description="Notification"
      defaultMessage="Notification"
    />
  ),
  notificationLabel: (days) => (
    <FormattedMessage
      id="tier.notificationLabel"
      description="Notify user about tier downgrade before - <b>{days} Days</b>"
      defaultMessage="Notify user about tier downgrade before - <b>{days} Days</b>"
      values={{
        days,
        b: (text) => <b>{text}</b>,
      }}
    />
  ),
  notificationEditLabel: (
    <FormattedMessage
      id="tier.notificationEditLabel"
      description="Notify user about tier downgrade before"
      defaultMessage="Notify user about tier downgrade before"
    />
  ),
  enterDays: (
    <FormattedMessage
      id="tier.enterDays"
      description="Enter no. of days"
      defaultMessage="Enter no. of days"
    />
  ),
  days: (
    <FormattedMessage id="tier.days" description="Days" defaultMessage="Days" />
  ),
};

export const commonTranslations = {
  shopeeSeventhStep: (
    <FormattedMessage
      id="shopee:seventh-step"
      description="Payment is complete."
      defaultMessage="Payment is complete."
    />
  ),
  shopeeSixthStep: (
    <FormattedMessage
      id="shopee:sixth-step"
      description="Enter the ShopeePay pin that you created to make payments"
      defaultMessage="Enter the ShopeePay pin that you created to make payments"
    />
  ),
  shopeeFifthStep: (
    <FormattedMessage
      id="shopee:fifth-step"
      description="Click 'Pay Now'"
      defaultMessage="Click 'Pay Now'"
    />
  ),
  shopeeFourthStep: (
    <FormattedMessage
      id="shopee:third-step"
      description="After that, the nominal you have to pay will appear. Click 'Continue'"
      defaultMessage="After that, the nominal you have to pay will appear. Click 'Continue'"
    />
  ),
  shopeeThirdStep: (
    <FormattedMessage
      id="shopee:third-step"
      description="Click the QR scan icon as indicated in the blue box above and scan the QR code shown on this page"
      defaultMessage="Click the QR scan icon as indicated in the blue box above and scan the QR code shown on this page"
    />
  ),
  shopeeSecondStep: (
    <FormattedMessage
      id="shopee:second-step"
      description="Enter the Shopee app and log in"
      defaultMessage="Enter the Shopee app and log in"
    />
  ),
  shopeeFirstStep: (
    <FormattedMessage
      id="shopee:first-step"
      description="Make sure you have the Shopee application and sufficient Shopee Pay balance on your cellphone"
      defaultMessage="Make sure you have the Shopee application and sufficient Shopee Pay balance on your cellphone"
    />
  ),
  goBackToPayment: (
    <FormattedMessage
      id="order.goBackToPayment"
      description="Go back to payments"
      defaultMessage="Go back to payments"
    />
  ),
  youWillBeRedirectedIn: (
    <FormattedMessage
      id="order.youWillBeRedirectedIn"
      description="You will be redirected in"
      defaultMessage="You will be redirected in"
    />
  ),
  transactionVerifiedOvo: (
    <FormattedMessage
      id="payment-details:your-transaction-verified"
      description="Your transaction will be verified automatically"
      defaultMessage="Your transaction will be verified automatically"
    />
  ),
  confirmYourTransactionOvo: (
    <FormattedMessage
      id="payment-details:confirm-your-transaction"
      description="Confirm your transaction as per the payment amount"
      defaultMessage="Confirm your transaction as per the payment amount"
    />
  ),
  selectBellIconOvo: (
    <FormattedMessage
      id="payment-details:select-bell-icon"
      description="Select the bell icon at the bottom right"
      defaultMessage="Select the bell icon at the bottom right"
    />
  ),
  openOvoInApp: (
    <FormattedMessage
      id="payment-details:open-ovo-app"
      description="Open the OVO app on phone"
      defaultMessage="Open the OVO app on phone"
    />
  ),
  addPhoneNumber: (
    <FormattedMessage
      id="payment:add-phone"
      description="Add phone number"
      defaultMessage="Add phone number"
    />
  ),
  payWith: (
    <FormattedMessage
      id="payment:pay-with"
      description="Pay with OVO"
      defaultMessage="Pay with OVO"
    />
  ),
  insufficientBalance: (
    <FormattedMessage
      id="shipping:Insufficient-balance"
      description="Insufficient balance. Please try another method."
      defaultMessage="Insufficient balance. Please try another method."
    />
  ),
  addCreditDebitCard: (
    <FormattedMessage
      id="payment-method:credit-debit-card-add"
      description="Add Credit/Debit Card"
      defaultMessage="Add Credit/Debit Card"
    />
  ),
  moreFrom: (
    <FormattedMessage
      id="shipping:more-from"
      description="More from"
      defaultMessage="More from"
    />
  ),
  getDiscount: (
    <FormattedMessage
      id="shipping:get-discount"
      description="and get a discount"
      defaultMessage="and get a discount"
    />
  ),
  getFreeShipping: (
    <FormattedMessage
      id="shipping:get-free-shipping"
      description="and get free shipping"
      defaultMessage="and get free shipping"
    />
  ),
  addItemsWorth: (
    <FormattedMessage
      id="shipping:add-items-worth"
      description="Add items worth"
      defaultMessage="Add items worth"
    />
  ),
  no: (
    <FormattedMessage
      id="customers-page:marketplace-bottom-sheet-upload-shipping-label-no"
      description="No"
      defaultMessage="No"
    />
  ),
  yes: (
    <FormattedMessage
      id="customers-page:marketplace-bottom-sheet-upload-shipping-label-yes"
      description="Yes"
      defaultMessage="Yes"
    />
  ),
  cancelPayment: (
    <FormattedMessage
      id="cancel-payment"
      description="Cancel payment"
      defaultMessage="Cancel payment"
    />
  ),
  videos: (
    <FormattedMessage
      id="video-carousel:videos"
      description="Videos"
      defaultMessage="Videos"
    />
  ),
  downloadTheApp: (
    <FormattedMessage
      id="download-the-app"
      description="Downnload the app"
      defaultMessage="Downnload the app"
    />
  ),
  visitOurStoresOnline: (
    <FormattedMessage
      id="visit-our-stores-online"
      description="Visit our stores online"
      defaultMessage="Visit our stores online"
    />
  ),
  featuredProducts: (
    <FormattedMessage
      id="home-page:featured-products"
      description="Featured products"
      defaultMessage="Featured products"
    />
  ),
  needHelpWithOrder: (
    <FormattedMessage
      id="need-help-with-order"
      description="Need Help with the order?"
      defaultMessage="Need Help with the order?"
    />
  ),
  tryAgainToCompleteOrder: (
    <FormattedMessage
      id="try-again-to-complete-order"
      description="Please try again to complete the order"
      defaultMessage="Please try again to complete the order"
    />
  ),
  yourPaymentFailed: (
    <FormattedMessage
      id="your-payment-failed"
      description="Oops! Your Payment Failed"
      defaultMessage="Oops! Your Payment Failed"
    />
  ),
  tryAgain: (
    <FormattedMessage
      id="orders:try-again"
      description="Try again"
      defaultMessage="Try again"
    />
  ),
  paymentGuide: (
    <FormattedMessage
      id="payment-guide"
      description="Payment guide"
      defaultMessage="Payment guide"
    />
  ),
  paymentInProcess: (
    <FormattedMessage
      id="payment-in-process"
      description="Payment in process"
      defaultMessage="Payment in process"
    />
  ),
  pleaseWait: (
    <FormattedMessage
      id="please-wait"
      description="Please wait"
      defaultMessage="Please wait"
      />
  ),
  topSellingProdcuts: (
    <FormattedMessage
      id="header:top-selling-products"
      description="Top Selling Products"
      defaultMessage="Top Selling Products"
    />
  ),
  searchProductsBrands: (
    <FormattedMessage
      id="header:search-products"
      description="Search Products, Brand"
      defaultMessage="Search Products, Brand"
    />
  ),
  searchBrandsHere: (
    <FormattedMessage
      id="home-page:search-brand-name"
      description="Search brands here"
      defaultMessage="Search brands here"
    />
  ),
  brands: (
    <FormattedMessage
      id="navbar.brands"
      description="Brands"
      defaultMessage="Brands"
    />
  ),
  apply: (
    <FormattedMessage
      id="delivery-address:apply"
      description="Apply"
      defaultMessage="Apply"
    />
  ),
  close: (
    <FormattedMessage
      id="search-filter:close"
      description="Close"
      defaultMessage="Close"
    />
  ),
  clearAll: (
    <FormattedMessage
      id="orders:clear-all"
      description="Clear all"
      defaultMessage="Clear all"
    />
  ),
  noProductsFound: (
    <FormattedMessage
      id="product-list-page:no-products-found"
      description="No products found"
      defaultMessage="No products found"
    />
  ),
  viewAll: (
    <FormattedMessage
      id="dashboard.viewAll"
      description="View all"
      defaultMessage="view all"
    />
  ),
  allProducts: (
    <FormattedMessage
      id="search-products:all-products"
      description="All Products"
      defaultMessage="All Products"
      />
  ),
  downloadDetails: (
    <FormattedMessage
      id="payment:download-bank"
      description="Download Details"
      defaultMessage="Download Details"
    />
  ),
  virtualAccountName: (
    <FormattedMessage
      id="payment-details:virtual-account-name"
      description="Virtual Account Name"
      defaultMessage="Virtual Account Name"
    />
  ),
  virtualAccountNumber: (
    <FormattedMessage
      id="payment-details:virtual-account"
      description="Virtual Account"
      defaultMessage="Virtual Account"
    />
  ),
  amountToPay: (
    <FormattedMessage
      id="payment-details:amount-to-pay"
      description="Amount to pay"
      defaultMessage="Amount to pay"
    />
  ),
  codeAcceptedIn: (
    <FormattedMessage
      id="payment-details:qr-code-accept"
      description="QR code accpted in"
      defaultMessage="QR code accpted in"
    />
  ),
  pleaseCompletePaymentIn: (
    <FormattedMessage
      id="payment-details:please-complete-payment-in"
      description="Please Complete Payent in"
      defaultMessage="Please Complete Payent in"
    />
  ),
  completePaymentIn: (
    <FormattedMessage
      id="payment-details:please-complete-in"
      description="Please Complete Payent in"
      defaultMessage="Please Complete Payent in"
    />
  ),
  amountToPay: (
    <FormattedMessage
      id="payment-details:amount-to-pay"
      description="Amount to pay"
      defaultMessage="Amount to pay"
    />
  ),
  selectPaymentMethod: (
    <FormattedMessage
      id="select-payment:method"
      description="Select payment method"
      defaultMessage="Select payment method"
/>
  ),
  addedInCart: (
    <FormattedMessage
      id="cart-page:added-in-cart"
      description="Added in cart"
      defaultMessage="Added in cart"
    />
  ),
  alreadyInCart: (
    <FormattedMessage
      id="cart-page:already-in-cart"
      description="Already in cart"
      defaultMessage="Already in cart"
    />
  ),
  cartIsEmpty: (
    <FormattedMessage
      id="cart-page:your-cart-is-empty"
      description="Your cart is empty"
      defaultMessage="Your cart is empty"
    />
  ),
  howToUse: (
    <FormattedMessage
      id="product.howToUse"
      description="how to use"
      defaultMessage="how to use"
    />
  ),
  productDetails: (
    <FormattedMessage
      id="navbar.productDetails"
      description="Product Details"
      defaultMessage="Product Details"
    />
  ),
  addToCart: (
    <FormattedMessage
      id="product-details:add-to-cart"
      description="Add to cart"
      defaultMessage="Add to cart"
    />
  ),
  cart: (
    <FormattedMessage
      id="cart-page:cart"
      description="Cart"
      defaultMessage="Cart"
    />
  ),
  selectAll: (
    <FormattedMessage
      id="orders:select-all"
      description="Select All"
      defaultMessage="Select All"
    />
  ),
  shippingOptions: (
    <FormattedMessage
      id="shipping:shipping-options"
      description="Shipping Options"
      defaultMessage="Shipping Options"
    />
  ),
  orderSummary: (
    <FormattedMessage
      id="order-summary-page:order-summary"
      description="Order Summary"
      defaultMessage="Order Summary"
    />
  ),
  edit: (
    <FormattedMessage
      id="navbar.edit"
      description="Edit"
      defaultMessage="Edit"
    />
  ),
  searchPlaceholder: (
    <FormattedMessage
      id="province:search"
      description="Search"
      defaultMessage="Search"
    />
  ),
  selectedPlaceholder: (
    <FormattedMessage
      id="list:selected"
      description="Selected"
      defaultMessage="Selected"
    />
  ),
  selectPlaceholder: (
    <FormattedMessage
      id="province:select"
      description="Select"
      defaultMessage="Select"
    />
  ),
  reset: (
    <FormattedMessage
      id="address-collector:basic-details-reset"
      description="reset"
      defaultMessage="reset"
    />
  ),
  searchProvince: (
    <FormattedMessage
      id="address-collector:shipping-details-province-district-type"
      description="Search Province, City, District, Area"
      defaultMessage="Search Province, City, District, Area"
    />
  ),
  selectLocation: (
    <FormattedMessage
      id="address-collector:find-location-enter-location"
      description="Select Location"
      defaultMessage="Select Location"
    />
  ),
  selectDistrictFirst: (
    <FormattedMessage
      id="customer-placeholder:select-district"
      description="Select district first"
      defaultMessage="Select district first"
    />
  ),
  selectCityFirst: (
    <FormattedMessage
      id="customer-placeholder:select-city"
      description="Select city first"
      defaultMessage="Select city first"
    />
  ),
  selectProvinceFirst: (
    <FormattedMessage
      id="customer-placeholder:select-province"
      description="Select province first"
      defaultMessage="Select province first"
    />
  ),
  enterSubDistrict: (
    <FormattedMessage
      id="customer-placeholder:sub-district"
      description="Enter sub-district"
      defaultMessage="Enter sub-district"
    />
  ),
  enterDistrict: (
    <FormattedMessage
      id="customer-placeholder:district"
      description="Enter district"
      defaultMessage="Enter district"
    />
  ),
  enterCity: (
    <FormattedMessage
      id="customer-placeholder:city"
      description="Enter city"
      defaultMessage="Enter city"
    />
  ),
  enterProvince: (
    <FormattedMessage
      id="customer-placeholder:province"
      description="Enter province"
      defaultMessage="Enter province"
    />
  ),
  enterStreet: (
    <FormattedMessage
      id="customer-placeholder:street"
      description="Enter street"
      defaultMessage="Enter street"
    />
  ),
  enterHouse: (
    <FormattedMessage
      id="customer-placeholder:house"
      description="Enter house"
      defaultMessage="Enter house"
    />
  ),
  enterEmail: (
    <FormattedMessage
      id="customer-placeholder:email"
      description="Enter email"
      defaultMessage="Enter email"
    />
  ),
  enterPhone: (
    <FormattedMessage
      id="customer-placeholder:phone"
      description="Enter phone"
      defaultMessage="Enter phone"
    />
  ),
  enterFullName: (
    <FormattedMessage
      id="customer-placeholder:name"
      description="Enter full name"
      defaultMessage="Enter full name"
    />
  ),
  copyToClipboard: (
    <FormattedMessage
      id="product-details:copied-to-clipboard"
      description="Copied to clipboard sucessfully"
      defaultMessage="Copied to clipboard sucessfully"
    />
  ),
  id: (
    <FormattedMessage
      id="delivery-address:order-id"
      description="ID"
      defaultMessage="ID"
    />
  ),
  stockQuantity: (
    <FormattedMessage
      id="product-details:exceeded-max-quantity"
      description="stock quantity"
      defaultMessage="stock quantity"
    />
  ),
  ingredients: (
    <FormattedMessage
      id="product-details:ingredients"
      description="ingredients"
      defaultMessage="ingredients"
    />
  ),
  howToUse: (
    <FormattedMessage
      id="product-details:how-to-use"
      description="How to use"
      defaultMessage="How to use"
    />
  ),
  chatWithSeller: (
    <FormattedMessage
      id="order.chatWithSeller"
      description="Chat With Seller"
      defaultMessage="Chat With Seller"
    />
  ),
  description: (
    <FormattedMessage
      id="product.description"
      description="Description"
      defaultMessage="Description"
    />
  ),
  paymentMethods:(
    <FormattedMessage 
      id="payment-methods:payment-methods"
      description="Payment Methods"
      defaultMessage="Payment Methods"
    /> 
     ),
  chatSeller: (
    <FormattedMessage
      id="navbar.chatSeller"
      description="Chat with seller"
      defaultMessage="Chat with seller"
    />
  ),
  delivery: (
    <FormattedMessage
      id="shipment-details:delivery"
      description="Delivery"
      defaultMessage="Delivery"
    />
  ),
  buyNow: (
    <FormattedMessage
      id="product.buyNow"
      description="Buy now"
      defaultMessage="Buy now"
    />
  ),
  itemsAdded: (itemName) => (
    <FormattedMessage
      id="common.itemsAdded"
      description="{itemName} added"
      defaultMessage="{itemName} added"
      values={{ itemName: (itemName && lowerCase(itemName)) || "" }}
    />
  ),
  priority: (
    <FormattedMessage
      id="common.priority"
      description="Priority"
      defaultMessage="Priority"
    />
  ),
  noItemsFound: (
    <FormattedMessage
      id="common.noItemsFound"
      description="No items found."
      defaultMessage="No items found."
    />
  ),
  titleWithCharLimit: (
    <FormattedMessage
      id="common.titleWithCharLimit"
      description="Title (Character limit: 30 )"
      defaultMessage="Title (Character limit: 30 )"
    />
  ),
  congratulations: (
    <FormattedMessage
      id="common.congratulations"
      description="Congratulations!"
      defaultMessage="Congratulations!"
    />
  ),
  translation: (
    <FormattedMessage
      id="common.translation"
      description="Translation (Bahasa)"
      defaultMessage="Translation (Bahasa)"
    />
  ),
  descritpionWithCharLimit: (
    <FormattedMessage
      id="common.descritpionWithCharLimit"
      description="Short Description (English, Character limit: 30)"
      defaultMessage="Short Description (English, Character limit: 30)"
    />
  ),
  descriptionTranslation: (
    <FormattedMessage
      id="common.descriptionTranslation"
      description="Short Description (Bahasa, Recommended Character limit: 30)"
      defaultMessage="Short Description (Bahasa, Recommended Character limit: 30)"
    />
  ),
  promotionalRibbon: (
    <FormattedMessage
      id="common.promotionalRibbon"
      description="Promotional Ribbon"
      defaultMessage="Promotional Ribbon"
    />
  ),
  okay: (
    <FormattedMessage
      id="common.okay"
      description="Okay"
      defaultMessage="Okay"
    />
  ),
  save: (
    <FormattedMessage
      id="common.save"
      description="Save"
      defaultMessage="Save"
    />
  ),
  saveAndContinue: (
    <FormattedMessage
      id="common.saveAndContinue"
      description="Save and Continue"
      defaultMessage="Save and Continue"
    />
  ),
  cancel: (
    <FormattedMessage
      id="common.cancel"
      description="Cancel"
      defaultMessage="Cancel"
    />
  ),
  delete: (
    <FormattedMessage
      id="common.delete"
      description="Delete"
      defaultMessage="Delete"
    />
  ),
  admin: (
    <FormattedMessage
      id="common.admin"
      description="Admin"
      defaultMessage="Admin"
    />
  ),
  name: (
    <FormattedMessage
      id="common.name"
      description="Name"
      defaultMessage="Name"
    />
  ),
  points: (
    <FormattedMessage
      id="common.points"
      description="Points"
      defaultMessage="Points"
    />
  ),
  email: (
    <FormattedMessage
      id="common.email"
      description="Email"
      defaultMessage="Email"
    />
  ),
  emailId: (
    <FormattedMessage
      id="common.emailId"
      description="Email Id"
      defaultMessage="Email Id"
    />
  ),
  mobile: (
    <FormattedMessage
      id="common.mobile"
      description="Mobile"
      defaultMessage="Mobile"
    />
  ),
  phoneNo: (
    <FormattedMessage
      id="common.phoneNo"
      description="Ph. No."
      defaultMessage="Ph. No."
    />
  ),
  amount: (
    <FormattedMessage
      id="common.amount"
      description="Amount"
      defaultMessage="Amount"
    />
  ),
  address: (
    <FormattedMessage
      id="common.address"
      description="Address"
      defaultMessage="Address"
    />
  ),
  noOfOrders: (
    <FormattedMessage
      id="common.noOfOrders"
      description="No. Of Orders"
      defaultMessage="No. Of Orders"
    />
  ),
  search: (
    <FormattedMessage
      id="common.search"
      description="Search"
      defaultMessage="Search"
    />
  ),
  filter: (
    <FormattedMessage
      id="common.filter"
      description="Filter"
      defaultMessage="Filter"
    />
  ),
  from: (
    <FormattedMessage
      id="common.from"
      description="From"
      defaultMessage="From"
    />
  ),
  to: <FormattedMessage id="common.to" description="To" defaultMessage="To" />,
  dateRange: (
    <FormattedMessage
      id="common.dateRange"
      description="Date Range"
      defaultMessage="Date Range"
    />
  ),
  logout: (
    <FormattedMessage
      id="common.logout"
      description="Logout"
      defaultMessage="Logout"
    />
  ),
  asiaJakarta: (
    <FormattedMessage
      id="common.asiaJakarta"
      description="Asia/Jakarta"
      defaultMessage="Asia/Jakarta"
    />
  ),
  asiaSingapore: (
    <FormattedMessage
      id="common.asiaSingapore"
      description="Asia/Singapore"
      defaultMessage="Asia/Singapore"
    />
  ),
  asiaCalcutta: (
    <FormattedMessage
      id="common.asiaCalcutta"
      description="Asia/Calcutta"
      defaultMessage="Asia/Calcutta"
    />
  ),
  login: (
    <FormattedMessage
      id="common.login"
      description="Login"
      defaultMessage="LOGIN"
    />
  ),
  userName: (
    <FormattedMessage
      id="common.userName"
      description="User Name"
      defaultMessage="User Name"
    />
  ),
  password: (
    <FormattedMessage
      id="common.password"
      description="Password"
      defaultMessage="Password"
    />
  ),
  confirmPassword: (
    <FormattedMessage
      id="common.confirmPassword"
      description="Confirm Password"
      defaultMessage="Confirm Password"
    />
  ),
  passwordRequirements: (
    <FormattedMessage
      id="common.passwordRequirements"
      description="Password must contain atleast one upper case, lower case, digit and
     a special character. Minimum length is 8."
      defaultMessage="<span>Password must contain atleast one upper case, lower case, digit and
     a special character. Minimum length is 8.</span>"
      values={{
        span: (text) => (
          <span style={{ fontSize: "14px", lineHeight: "18px" }}>{text}</span>
        ),
      }}
    />
  ),
  invalidLink: (
    <FormattedMessage
      id="common.invalidLink"
      description="Invalid Link"
      defaultMessage="Invalid Link"
    />
  ),
  invalidLinkMessage: (
    <FormattedMessage
      id="common.invalidLinkMessage"
      description="IThere is an error with the link address.{br} Please try again"
      defaultMessage="There is an error with the link address.{br} Please try again"
      values={{
        br: <br />,
      }}
    />
  ),
  store: (
    <FormattedMessage
      id="common.store"
      description="Store"
      defaultMessage="Store"
    />
  ),
  confirm: (
    <FormattedMessage
      id="common.confirm"
      description="Confirm"
      defaultMessage="Confirm"
    />
  ),
  reject: (
    <FormattedMessage
      id="common.reject"
      description="Reject"
      defaultMessage="Reject"
    />
  ),
  rejected: (
    <FormattedMessage
      id="common.rejected"
      description="Rejected"
      defaultMessage="Rejected"
    />
  ),
  verified: (
    <FormattedMessage
      id="common.verified"
      description="Verified"
      defaultMessage="Verified"
    />
  ),
  reason: (
    <FormattedMessage
      id="common.reason"
      description="Reason"
      defaultMessage="Reason"
    />
  ),
  unverified: (
    <FormattedMessage
      id="common.unverified"
      description="Unverified"
      defaultMessage="Unverified"
    />
  ),
  verify: (
    <FormattedMessage
      id="common.verify"
      description="Verify"
      defaultMessage="Verify"
    />
  ),
  accept: (
    <FormattedMessage
      id="common.accept"
      description="Accept"
      defaultMessage="Accept"
    />
  ),
  addNew: (
    <FormattedMessage
      id="common.addNew"
      description="Add 1"
      defaultMessage="Add 1"
    />
  ),
  noResultsFound: (
    <FormattedMessage
      id="common.noResultsFound"
      description="No results found"
      defaultMessage="No results found"
    />
  ),
  status: (
    <FormattedMessage
      id="common.status"
      description="Status"
      defaultMessage="Status"
    />
  ),
  changeStatus: (
    <FormattedMessage
      id="common.changeStatus"
      description="Change Status"
      defaultMessage="Change Status"
    />
  ),
  clear: (
    <FormattedMessage
      id="common.clear"
      description="Clear"
      defaultMessage="Clear"
    />
  ),
  upload: (
    <FormattedMessage
      id="common.upload"
      description="Upload"
      defaultMessage="Upload"
    />
  ),
  browse: (
    <FormattedMessage
      id="common.browse"
      description="Browse"
      defaultMessage="Browse"
    />
  ),
  tier: (
    <FormattedMessage
      id="common.tier"
      description="Tier"
      defaultMessage="Tier"
    />
  ),
  uploadingMightTakeLongTime: (
    <FormattedMessage
      id="common.uploadingMightTakeLongTime"
      description="All details are successfully uploaded{br}Uploading might take a long time."
      defaultMessage="All details are successfully uploaded{br}Uploading might take a long time."
      values={{
        br: <br />,
      }}
    />
  ),
  goToUploadHistory: (
    <FormattedMessage
      id="common.goToUploadHistory"
      description="Go to Upload History"
      defaultMessage="Go to Upload History"
    />
  ),
  back: (
    <FormattedMessage
      id="common.back"
      description="Back"
      defaultMessage="Back"
    />
  ),
  mobileAndEmail: (
    <FormattedMessage
      id="common.mobileAndEmail"
      description="Mobile/Email ID"
      defaultMessage="Mobile/Email ID"
    />
  ),
  itemDetails: (
    <FormattedMessage
      id="common.itemDetails"
      description="Item Details"
      defaultMessage="Item Details"
    />
  ),
  price: (
    <FormattedMessage
      id="common.price"
      description="Price"
      defaultMessage="Price"
    />
  ),
  gmPercentage: (percent) => (
    <FormattedMessage
      id="common.gmPercentage"
      description="GM: {percent}%"
      defaultMessage="GM: {percent}%"
      values={{ percent }}
    />
  ),
  cogs: (
    <FormattedMessage
      id="common.cogs"
      description="COGS: "
      defaultMessage="COGS: "
    />
  ),
  wholesalePrice: (
    <FormattedMessage
      id="common.wholesalePrice"
      description="Wholesale Price"
      defaultMessage="Wholesale Price"
    />
  ),
  onlySinglePreorderItemAllowed: (
    <FormattedMessage
      id="common.onlySinglePreorderItemAllowed"
      description="Only single preorder item is allowed."
      defaultMessage="Pre-order item is added in the order. Only single item can be considered if it is a pre-order."
    />
  ),
  copyButtonText: (
    <FormattedMessage
      id="common.copyButtonText"
      description="Copy Payment Link"
      defaultMessage="Copy Payment Link"
    />
  ),
  addImage: (
    <FormattedMessage
      id="common.addImage"
      description="Add Image"
      defaultMessage="Add Image"
    />
  ),
  max5Nos: (
    <FormattedMessage
      id="common.max5Nos"
      description="(Max. 5 nos)"
      defaultMessage="(Max. 5 nos)"
    />
  ),
  targetUrl: (
    <FormattedMessage
      id="common.targetUrl"
      description="Target URL"
      defaultMessage="Target URL"
    />
  ),
  enterProductId: (
    <FormattedMessage
      id="common.enterProductId"
      description="Enter Product ID"
      defaultMessage="Enter Product ID"
    />
  ),
  done: (
    <FormattedMessage
      id="uploader.done"
      description="Done"
      defaultMessage="Done"
    />
  ),
  chooseCategory: (
    <FormattedMessage
      id="uploader.chooseCategory"
      description="Choose Category"
      defaultMessage="Choose Category"
    />
  ),
  targetItem: (
    <FormattedMessage
      id="uploader.targetItem"
      description="Target item"
      defaultMessage="Target item"
    />
  ),
  hide: (
    <FormattedMessage
      id="uploader.hide"
      description="Hide"
      defaultMessage="Hide"
    />
  ),
  proceed: (
    <FormattedMessage
      id="uploader.proceed"
      description="Proceed"
      defaultMessage="Proceed"
    />
  ),
  maxAllowedItems: (count) => (
    <FormattedMessage
      id="common.maxAllowedItems"
      description="(Max {count} nos)"
      defaultMessage="(Max {count} nos)"
      values={{ count }}
    />
  ),
  minMaxItemText: (min, max) => (
    <FormattedMessage
      id="common.minMaxItemText"
      description="(Min {min} nos, Max {max} nos)"
      defaultMessage="(Min {min} nos, Max {max} nos)"
      values={{ min, max }}
    />
  ),
  minAllowedItemsWarning: (count) => (
    <FormattedMessage
      id="common.minAllowedItemsWarning"
      description="Minimum allowed item count is {count}."
      defaultMessage="Minimum allowed item count is {count}."
      values={{ count }}
    />
  ),
  minAllowedInventory: (
    <FormattedMessage
      id="common.minAllowedInventory"
      description="Channel Order will be created only if CGK/Jakarta warehouse has inventory."
      defaultMessage="Channel Order will be created only if CGK/Jakarta warehouse has inventory."
    />
  ),
  quantityNotInstock: (
    <FormattedMessage
      id="common.quantityNotInstock"
      description="Quantity not in stock"
      defaultMessage="Quantity not in stock"
    />
  ),
  maxAllowedItemsWarning: (count) => (
    <FormattedMessage
      id="common.maxAllowedItemsWarning"
      description="Maximum allowed item count is {count}"
      defaultMessage="Maximum allowed item count is {count}"
      values={{ count }}
    />
  ),
  create: (
    <FormattedMessage
      id="common.create"
      description="Create"
      defaultMessage="Create"
    />
  ),
  selectedCount: (count) => (
    <FormattedMessage
      id="common.selectedCount"
      description="{count} Selected"
      defaultMessage="{count} Selected"
      values={{ count }}
    />
  ),
  copyLink: (
    <FormattedMessage
      id="common.copyLink"
      description="Copy Link"
      defaultMessage="Copy Link"
    />
  ),
  accessDenied: (
    <FormattedMessage
      id="common.accessDenied"
      description="Access Denied"
      defaultMessage="Access Denied"
    />
  ),
  accessDeniedWarningMessage: (
    <FormattedMessage
      id="common.accessDeniedWarningMessage"
      description="You don't have the access, Please contact admin team"
      defaultMessage="You don't have the access, Please contact admin team"
    />
  ),
  noAccess: (name) => (
    <FormattedMessage
      id="common.noAccess"
      description="{name} access required"
      defaultMessage="<b>{name} access required</b>"
      values={{
        name,
        b: (text) => <b>{text}</b>,
      }}
    />
  ),
  update: (
    <FormattedMessage
      id="common.update"
      description="Update"
      defaultMessage="Update"
    />
  ),
  copied: (
    <FormattedMessage
      id="common.copiedMessage"
      description="copied"
      defaultMessage="copied"
    />
  ),
  shippingAddress: (
    <FormattedMessage
      id="common.shippingAddress"
      description="Shipping Address"
      defaultMessage="Shipping Address"
    />
  ),
  maxQtyPerUser: (
    <FormattedMessage
      id="common.maxQtyPerUser"
      description="Max. Quantity/user"
      defaultMessage="Max. Quantity/user"
    />
  ),
  delisted: (
    <FormattedMessage
      id="common.delisted"
      description="Delisted"
      defaultMessage="Delisted"
    />
  ),
  listed: (
    <FormattedMessage
      id="common.listed"
      description="Listed"
      defaultMessage="Listed"
    />
  ),
  none: (
    <FormattedMessage
      id="common.none"
      description="None"
      defaultMessage="None"
    />
  ),
  next: (
    <FormattedMessage
      id="common.next"
      description="Next"
      defaultMessage="Next"
    />
  ),
  createdDateInCustomTimeZone: (timeZone) => (
    <FormattedMessage
      id="common.createdDateInCustomTimeZone"
      description="Created Date ({timeZone})"
      defaultMessage="Created Date ({timeZone})"
      values={{
        timeZone,
      }}
    />
  ),
  startDateInCustomTimeZone: (timeZone) => (
    <FormattedMessage
      id="challenges.startDate"
      description="Start Date ({timeZone})"
      defaultMessage="Start Date ({timeZone})"
      values={{
        timeZone,
      }}
    />
  ),
  endDateInCustomTimeZone: (timeZone) => (
    <FormattedMessage
      id="challenge.endDate"
      description="End Date ({timeZone})"
      defaultMessage="End Date ({timeZone})"
      values={{
        timeZone,
      }}
    />
  ),
  startTime: (
    <FormattedMessage
      id="common.startTime"
      description="Start Time"
      defaultMessage="Start Time"
    />
  ),
  noDataToDisplay: (
    <FormattedMessage
      id="common.noDataToDisplay"
      description="No Data to Display"
      defaultMessage="No Data to Display"
    />
  ),
  targetLink: (
    <FormattedMessage
      id="common.targetLink"
      description="Target Link"
      defaultMessage="Target Link"
    />
  ),
  resellerShortDescription: (
    <FormattedMessage
      id="common.resellerEducationShortDescription"
      description="Short Description"
      defaultMessage="Short Description"
    />
  ),
  buttonText: (
    <FormattedMessage
      id="common.buttonText"
      description="Button Text (English, Character limit: 30)"
      defaultMessage="Button Text (English, Character limit: 30)"
    />
  ),
  buttonTextTranslation: (
    <FormattedMessage
      id="common.buttonText"
      description="Button Text (Bahasa, Recommended Character limit: 30)"
      defaultMessage="Button Text (Bahasa, Recommended Character limit: 30)"
    />
  ),
  buttonTextPlaceholder: (
    <FormattedMessage
      id="common.buttonTextPlaceholder"
      description="Button Text"
      defaultMessage="Button Text"
    />
  ),
  instock: (
    <FormattedMessage
      id="common.instock"
      description="Instock"
      defaultMessage="Instock"
    />
  ),
  continue: (
    <FormattedMessage
      id="common.continue"
      description="Continue"
      defaultMessage="Continue"
    />
  ),
  unblock: (
    <FormattedMessage
      id="common.unblock"
      description="Unblock"
      defaultMessage="Unblock"
    />
  ),
  block: (
    <FormattedMessage
      id="common.block"
      description="Block"
      defaultMessage="Block"
    />
  ),
  successBtn: (title) => (
    <FormattedMessage
      id="common.ok"
      description="({title})"
      defaultMessage="{title}"
      values={{
        title,
      }}
    />
  ),
};

export const appSettingsTranslations = {
  key: (
    <FormattedMessage
      id="appSettings.key"
      description="Key"
      defaultMessage="Key"
    />
  ),
  value: (
    <FormattedMessage
      id="appSettings.value"
      description="Value"
      defaultMessage="Value"
    />
  ),
  category: (
    <FormattedMessage
      id="appSettings.category"
      description="Category"
      defaultMessage="Category"
    />
  ),
};

export const brandpageTranslations = {
  targetUrl: (count) => {
    const description = `Enter ${count} Id`;
    return (
      <FormattedMessage
        id="appbrandpage.targetUrl"
        description={description}
        defaultMessage={description}
      />
    );
  },
};

export const translationMapper = (status, message) => {
  switch (status) {
    case "Order_Placed":
      return (
        <FormattedMessage
          id="orderStatus.orderPlaced"
          description="Order placed in order status"
          defaultMessage="Order placed"
        />
      );
    case "Payment_Done":
      return (
        <FormattedMessage
          id="orderStatus.paymentDone"
          description="Payment done in order status"
          defaultMessage="Payment done"
        />
      );
    case "Pending_Confirmation":
      return (
        <FormattedMessage
          id="orderStatus.pendingConfirmation"
          description="Pending Confirmation"
          defaultMessage="Pending Confirmation"
        />
      );
    case "Confirmed":
      return (
        <FormattedMessage
          id="orderStatus.confirmed"
          description="Order confirmed in order status"
          defaultMessage="Confirmed"
        />
      );
    case "Dispatched":
      return (
        <FormattedMessage
          id="orderStatus.dispatch"
          description="Dispatched in order status"
          defaultMessage="Dispatched"
        />
      );
    case "Delivered":
      return (
        <FormattedMessage
          id="orderStatus.delivered"
          description="Delivered in order status"
          defaultMessage="Delivered"
        />
      );
    case "Cancelled":
      return (
        <FormattedMessage
          id="orderStatus.cancelled"
          description="Order cancelled in order status"
          defaultMessage="Cancelled"
        />
      );
    case "Returned":
      return (
        <FormattedMessage
          id="orderStatus.returned"
          description="Order returned in order status"
          defaultMessage="Returned"
        />
      );
    case "Reversed":
      return (
        <FormattedMessage
          id="orderStatus.reversed"
          description="Order reversed in order status"
          defaultMessage="Reversed"
        />
      );
    case "SHIPPING_FEE_COUPON":
      return (
        <FormattedMessage
          key="orderStatus.flatDiscount"
          id="orderStatus.flatDiscount"
          description="Shipping fee coupon"
          defaultMessage="Shipping fee coupon"
        />
      );
    case "FLAT_DISCOUNT":
      return (
        <FormattedMessage
          key="orderStatus.flatDiscount"
          id="orderStatus.flatDiscount"
          description="Flat Discount"
          defaultMessage="Flat Discount"
        />
      );
    case "FIRST_ORDER_DISCOUNT":
      return (
        <FormattedMessage
          key="orderStatus.firstOrderDiscount"
          id="orderStatus.firstOrderDiscount"
          description="First Order Discount"
          defaultMessage="First Order Discount"
        />
      );
    case "ORDER_AMOUNT":
      return (
        <FormattedMessage
          id="challenge.orderTarget"
          description="Order Target"
          defaultMessage="Order Target"
        />
      );
    case "CUSTOMER_ORDERS":
      return (
        <FormattedMessage
          id="challenge.noOfCustomerOrder"
          description="No of Customer Orders"
          defaultMessage="No of Customer Orders"
        />
      );
    case "ORDER":
      return (
        <FormattedMessage
          id="challenge.noOfOrders"
          description="No of Orders"
          defaultMessage="No of Orders"
        />
      );
    case "RECORD_NOTFOUND":
      return (
        <FormattedMessage
          id="addProduct.recordNotFound"
          description="The given condition does not match any existing records in the system"
          defaultMessage="The given condition does not match any existing records in the system"
        />
      );
    case "PRODUCT_WITH_ID_NOT_FOUND":
      return (
        <FormattedMessage
          id="addProduct.noMatchingProductFound"
          description="No product was found matching the id supplied"
          defaultMessage="No product was found matching the id supplied"
        />
      );
    default:
      return (
        <FormattedMessage
          id="general.defaultMessage"
          description="Default message"
          defaultMessage={`${message || "Error occured"}`}
        />
      );
  }
};
export const resellerTranslations = {
  tier: (
    <FormattedMessage
      id="reseller.tier"
      description="Tier"
      defaultMessage="Tier"
    />
  ),
  tierDetails: (
    <FormattedMessage
      id="reseller.tierDetails"
      description="Tier Details"
      defaultMessage="Tier Details"
    />
  ),
  manageTierManually: (
    <FormattedMessage
      id="reseller.manageTierManually"
      description="Auto check On"
      defaultMessage="Auto check On"
    />
  ),
  resellerName: (
    <FormattedMessage
      id="reseller.resellerName"
      description="Reseller Name"
      defaultMessage="Reseller Name"
    />
  ),
  period: (
    <FormattedMessage
      id="reseller.period"
      description="Period"
      defaultMessage="Period"
    />
  ),
  pauseAutoCheckMessage: (
    <FormattedMessage
      id="reseller.period"
      description="Tier auto-check will be paused for this specific period."
      defaultMessage="Tier auto-check will be paused for this specific period."
    />
  ),
  resellerDetails: (
    <FormattedMessage
      id="reseller.resellerDetails"
      description="Reseller Details"
      defaultMessage="Reseller Details"
    />
  ),
  action: (
    <FormattedMessage
      id="reseller.action"
      description="Action"
      defaultMessage="Action"
    />
  ),
  comment: (
    <FormattedMessage
      id="reseller.comment"
      description="Comment"
      defaultMessage="Comment"
    />
  ),
  mobileNoVerified: (
    <FormattedMessage
      id="reseller.mobileNoVerified"
      description="Mobile number is verified"
      defaultMessage="Mobile number is verified"
    />
  ),
  clickToVerifyMobile: (
    <FormattedMessage
      id="reseller.clickToVerifyMobile"
      description="Click here to verify mobile number"
      defaultMessage="Click here to verify mobile number"
    />
  ),
  emailIdVerified: (
    <FormattedMessage
      id="reseller.emailIdVerified"
      description="Email-Id is verified"
      defaultMessage="Email-Id is verified"
    />
  ),
  clickToVerifyEmail: (
    <FormattedMessage
      id="reseller.clickToVerifyEmail"
      description="Click here to verify email-id"
      defaultMessage="Click here to verify email-id"
    />
  ),
  deviceOS: (
    <FormattedMessage
      id="reseller.deviceOS"
      description="Device OS"
      defaultMessage="Device OS"
    />
  ),
  currentAppVersion: (
    <FormattedMessage
      id="reseller.currentAppVersion"
      description="App Version"
      defaultMessage="App Version"
    />
  ),
  deviceOsVersion: (
    <FormattedMessage
      id="reseller.deviceOsVersion"
      description="OS Version"
      defaultMessage="OS Version"
    />
  ),
  deviceModel: (
    <FormattedMessage
      id="reseller.deviceModel"
      description="Device Model"
      defaultMessage="Device Model"
    />
  ),
  lastLoggedIn: (
    <FormattedMessage
      id="reseller.lastLoggedIn"
      description="Last logged in"
      defaultMessage="Last logged in"
    />
  ),
  resellerEmailId: (
    <FormattedMessage
      id="reseller.resellerEmailId"
      description="Reseller Email Id"
      defaultMessage="Reseller Email Id"
    />
  ),
  mobileUnVerified: (
    <FormattedMessage
      id="reseller.mobileUnVerified"
      description="Unverified Mobile"
      defaultMessage="Mobile number is unverified "
    />
  ),
  emailUnVerified: (
    <FormattedMessage
      id="reseller.mobileUnVerified"
      description="Unverified Email"
      defaultMessage="Email-Id is unverified"
    />
  ),
  resellerExport: (
    <FormattedMessage
      id="navbar.resellerExport"
      description="Reseller Export"
      defaultMessage="Reseller Export"
    />
  ),
};

export const discountTranslations = {
  emailIds: (
    <FormattedMessage
      id="discount.emailIds"
      description="Email Id's"
      defaultMessage="Email Id's"
    />
  ),
  couponEditDisclaimerContent: (
    <FormattedMessage
      id="discount.couponEditDisclaimerContent"
      description="The coupon will be inactive while making changes on it."
      defaultMessage="The coupon will be inactive while making changes on it."
    />
  ),
  disclaimer: (
    <FormattedMessage
      id="discount.disclaimer"
      description="Disclaimer"
      defaultMessage="Disclaimer"
    />
  ),
  discount: (
    <FormattedMessage
      id="discount.discount"
      description="Discount"
      defaultMessage="Discount"
    />
  ),
  discountDetails: (
    <FormattedMessage
      id="discount.discountDetails"
      description="Discount Details"
      defaultMessage="Discount Details"
    />
  ),
  discountType: (
    <FormattedMessage
      id="discount.discountType"
      description="Discount Type"
      defaultMessage="Discount Type"
    />
  ),
  userSegment: (
    <FormattedMessage
      id="discount.userSegment"
      description="User Segment"
      defaultMessage="User Segment"
    />
  ),
  start: (
    <FormattedMessage
      id="discount.start"
      description="Start"
      defaultMessage="Start"
    />
  ),
  end: (
    <FormattedMessage
      id="discount.end"
      description="End"
      defaultMessage="End"
    />
  ),
  maxAmountRedeemable: (
    <FormattedMessage
      id="discount.maxAmountRedeemable"
      description="Max amount redeemable per order"
      defaultMessage="Max amount redeemable per order"
    />
  ),
  minOrderAmount: (
    <FormattedMessage
      id="discount.minOrderAmount"
      description="Minimum Order Amount"
      defaultMessage="Minimum Order Amount"
    />
  ),
  couponCode: (
    <FormattedMessage
      id="discount.couponCode"
      description="Coupon Code"
      defaultMessage="Coupon Code"
    />
  ),
  discountValue: (
    <FormattedMessage
      id="discount.discountValue"
      description="Discount Value"
      defaultMessage="Discount Value"
    />
  ),
  couponAppliedOn: (
    <FormattedMessage
      id="discount.couponAppliedOn"
      description="Coupon Applied On"
      defaultMessage="Coupon Applied On"
    />
  ),
  validFrom: (
    <FormattedMessage
      id="discount.validFrom"
      description="Valid From"
      defaultMessage="Valid From"
    />
  ),
  validTo: (
    <FormattedMessage
      id="discount.validTo"
      description="Valid To"
      defaultMessage="Valid To"
    />
  ),
  maximumAmount: (
    <FormattedMessage
      id="discount.maximumAmount"
      description="Maximum Amount"
      defaultMessage="Maximum Amount"
    />
  ),
  maximumRedeemedAmount: (
    <FormattedMessage
      id="discount.maximumRedeemedAmount"
      description="Maximum Amount that can be redeemed per order"
      defaultMessage="Maximum Amount that can be redeemed per order"
    />
  ),
  minimumOrderAmountToGetRedeemed: (
    <FormattedMessage
      id="discount.minimumOrderAmountToGetRedeemed"
      description="Minimum order amount required to redeem"
      defaultMessage="Minimum order amount required to redeem"
    />
  ),
  minimumAmount: (
    <FormattedMessage
      id="discount.minimumAmount"
      description="Minimum amount"
      defaultMessage="Minimum amount"
    />
  ),
  noOfUsage: (
    <FormattedMessage
      id="discount.noOfUsage"
      description="Total no of usage for the coupon code"
      defaultMessage="Total no of usage for the coupon code"
    />
  ),
  noOfUsagePerPerson: (
    <FormattedMessage
      id="discount.noOfUsagePerPerson"
      description="No. of usage per person"
      defaultMessage="No. of usage per person"
    />
  ),
  noOfUsageInDetailsCard: (
    <FormattedMessage
      id="discount.noOfUsageInDetailsCard"
      description="No. of usage"
      defaultMessage="No. of usage"
    />
  ),
  status: (
    <FormattedMessage
      id="discount.status"
      description="Status"
      defaultMessage="Status"
    />
  ),
  couponTitle: (
    <FormattedMessage
      id="discount.couponTitle"
      description="Coupon Title"
      defaultMessage="Coupon Title"
    />
  ),
  addItems: (
    <FormattedMessage
      id="discount.addItems"
      description="+ Items*"
      defaultMessage="+ Items*"
    />
  ),
  itemsAdded: (
    <FormattedMessage
      id="discount.itemsAdded"
      description="items added"
      defaultMessage="items added"
    />
  ),
  showing: (
    <FormattedMessage
      id="discount.showing"
      description="Showing"
      defaultMessage="Showing"
    />
  ),
  items: (
    <FormattedMessage
      id="discount.items"
      description="items"
      defaultMessage="items"
    />
  ),
  selectAll: (
    <FormattedMessage
      id="discount.selectAll"
      description="Select All"
      defaultMessage="Select All"
    />
  ),
  max15Characters: (
    <FormattedMessage
      id="discount.max15Characters"
      description="(Max.15 characters)"
      defaultMessage="(Max.15 characters)"
    />
  ),
  discountIsApplied: (
    <FormattedMessage
      id="discount.discountIsApplied"
      description="Discount is applied"
      defaultMessage="Discount is applied"
    />
  ),
  titleDiscountCode: (
    <FormattedMessage
      id="discount.titleDiscountCode"
      description="Title / Discount Code"
      defaultMessage="Title / Discount Code"
    />
  ),

  selectedListType: (value) => (
    <FormattedMessage
      id="discount.selectedCoupon"
      description="selected coupon type"
      defaultMessage="Selected {value}"
      values={{ value }}
    />
  ),
  itemAdded: (count, value) => (
    <FormattedMessage
      id="discount.itemAdded"
      description="number of items added"
      defaultMessage="{count} {value} added"
      values={{ count, value }}
    />
  ),
  addedType: (value) => (
    <FormattedMessage
      id="discount.addedType"
      description="added discount coupon type"
      defaultMessage="{value} added"
      values={{ value }}
    />
  ),
  title: (
    <FormattedMessage
      id="common.title"
      description="Title"
      defaultMessage="Title"
    />
  ),
  discountCode: (
    <FormattedMessage
      id="common.discountCode"
      description="Discount Code"
      defaultMessage="Discount Code"
    />
  ),
  noOfCouponsUsed: (
    <FormattedMessage
      id="common.noOfCouponsUsed"
      description="No of coupons used"
      defaultMessage="No of coupons used"
    />
  ),
  validityPeriod: (
    <FormattedMessage
      id="common.validityPeriod"
      description="Validity Period"
      defaultMessage="Validity Period"
    />
  ),
  generateCoupons: (
    <FormattedMessage
      id="common.generateCoupons"
      description="Generate Coupons"
      defaultMessage="Generate Coupons"
    />
  ),
  activate: (
    <FormattedMessage
      id="common.activate"
      description="Activate"
      defaultMessage="Activate"
    />
  ),
  deactivate: (
    <FormattedMessage
      id="common.deactivate"
      description="Deactivate"
      defaultMessage="Deactivate"
    />
  ),
  totalItemsAdded: (type, count) => (
    <FormattedMessage
      id="discount.totalItemsAdded"
      description="items count"
      defaultMessage="Total {type}s added - {count}"
      values={{ type, count }}
    />
  ),
  listOfItemsAdded: (type) => (
    <FormattedMessage
      id="discount.listOfItemsAdded"
      description="items list"
      defaultMessage="List of {type}s added"
      values={{ type }}
    />
  ),
  couponCodePlaceholder: (
    <FormattedMessage
      id="discount.couponCodePlaceholder"
      description="Coupon Code (Eg: RAMZANOFFER)"
      defaultMessage="Coupon Code (Eg: RAMZANOFFER)"
    />
  ),
  titleEnPlaceholder: (
    <FormattedMessage
      id="discount.titleEnPlaceholder"
      description="Title (Eg: Featured Promotions)"
      defaultMessage="Title (Eg: Featured Promotions)"
    />
  ),
  appListing: (
    <FormattedMessage
      id="discount.appListing"
      description="App Listing"
      defaultMessage="App Listing"
    />
  ),
  changeAppListing: (
    <FormattedMessage
      id="discount.changeAppListing"
      description="Change App Listing"
      defaultMessage="Change App Listing"
    />
  ),
  changeAppListingDialogMsg: (
    <FormattedMessage
      id="discount.changeAppListingDialogMsg"
      description="Do you really want to change app listing status?"
      defaultMessage="Do you really want to change app listing status?"
    />
  ),
  changeAppListingDialogMsgOfCoupon: (couponDetails) => (
    <FormattedMessage
      id="discount.changeAppListingDialogMsgOfCoupon"
      description="Do you really want to change app listing status{br}of coupon {couponName}?"
      defaultMessage="Do you really want to change app listing status{br}of coupon {couponName}?"
      values={{
        br: <br />,
        couponName: (
          <b>{(!isEmpty(couponDetails) && couponDetails.title) || ""}</b>
        ),
      }}
    />
  ),
  listingStatusChangeSuccessful: (
    <FormattedMessage
      id="discount.listingStatusChangeSuccessful"
      description="App listing status changed successfully"
      defaultMessage="App listing status changed successfully"
    />
  ),
  listingStatusUpdateFailed: (
    <FormattedMessage
      id="discount.listingStatusUpdateFailed"
      description="App listing status update failed"
      defaultMessage="App listing status update failed"
    />
  ),
  description: (
    <FormattedMessage
      id="discount.description"
      description="Description"
      defaultMessage="Description"
    />
  ),
  couponListingApp: (
    <FormattedMessage
      id="discount.couponListingApp"
      description="List this coupon in the app"
      defaultMessage="List this coupon in the app"
    />
  ),
  tierUpgrade: (
    <FormattedMessage
      id="challenges.tierUpgrade"
      description="Tier Upgrade"
      defaultMessage="Tier Upgrade"
    />
  ),
  addExcludedProduct: (
    <FormattedMessage
      id="discount.addExcludedProduct"
      description="+ Excluded Products"
      defaultMessage="+ Excluded Products"
    />
  ),
  excludedProductsWillBeLost: (
    <FormattedMessage
      id="discount.excludedProductsWillBeLost"
      description="Excluded products list will be reset on editing this list"
      defaultMessage="Excluded products list will be reset on editing this list"
    />
  ),
  listOfProductsExcluded: (
    <FormattedMessage
      id="discount.listOfProductsExcluded"
      description="List of Products excluded"
      defaultMessage="List of Products excluded"
    />
  ),
  totalProductsExcluded: (count) => (
    <FormattedMessage
      id="discount.totalProductsExcluded"
      description="items count"
      defaultMessage="Total Products excluded - {count}"
      values={{ count }}
    />
  ),
  excludeCSV: (
    <FormattedMessage
      id="discount.excludeCSV"
      description="+ Exclude CSV"
      defaultMessage="+ Exclude CSV"
    />
  ),
};

export const administrationTranslations = {
  userId: (
    <FormattedMessage
      id="administration.userId"
      description="User ID"
      defaultMessage="User ID"
    />
  ),
  name: (
    <FormattedMessage
      id="administration.name"
      description="Name"
      defaultMessage="Name"
    />
  ),
  emailAddress: (
    <FormattedMessage
      id="administration.emailAddress"
      description="Email Address"
      defaultMessage="Email Address"
    />
  ),
  createNewRole: (
    <FormattedMessage
      id="administration.createNewRole"
      description="Create New Role"
      defaultMessage="Create New Role"
    />
  ),
  editRole: (
    <FormattedMessage
      id="administration.editRole"
      description="Edit Role"
      defaultMessage="Edit Role"
    />
  ),
  userRole: (
    <FormattedMessage
      id="administration.userRole"
      description="User Role"
      defaultMessage="User Role"
    />
  ),
  noOfUsers: (
    <FormattedMessage
      id="administration.noOfUsers"
      description="No of Users"
      defaultMessage="No of Users"
    />
  ),
  action: (
    <FormattedMessage
      id="administration.action"
      description="Action"
      defaultMessage="Action"
    />
  ),
  createNewUser: (
    <FormattedMessage
      id="administration.createNewUser"
      description="Create New User"
      defaultMessage="Create New User"
    />
  ),
  userName: (
    <FormattedMessage
      id="administration.userName"
      description="User Name"
      defaultMessage="User Name"
    />
  ),
  email: (
    <FormattedMessage
      id="administration.email"
      description="Email ID"
      defaultMessage="Email ID"
    />
  ),
  designation: (
    <FormattedMessage
      id="administration.designation"
      description="Designation"
      defaultMessage="Designation"
    />
  ),
  roleAssignation: (
    <FormattedMessage
      id="administration.roleAssignation"
      description="Role Assignation"
      defaultMessage="Role Assignation"
    />
  ),
  userCreatedAndShareLink: (email) => (
    <FormattedMessage
      id="administration.userCreatedAndShareLink"
      description="The user has been created. Share the link with {email}"
      defaultMessage="The user has been created. Share the link with {email}"
      values={{ email }}
    />
  ),
  rolesAssigned: (
    <FormattedMessage
      id="administration.rolesAssigned"
      description="Roles Assigned"
      defaultMessage="Roles Assigned"
    />
  ),
  createdDate: (
    <FormattedMessage
      id="administration.createdDate"
      description="Created Date"
      defaultMessage="Created Date"
    />
  ),
  lastLogin: (
    <FormattedMessage
      id="administration.lastLogin"
      description="Last Login"
      defaultMessage="Last Login"
    />
  ),
  status: (
    <FormattedMessage
      id="administration.status"
      description="Status"
      defaultMessage="Status"
    />
  ),
  actions: (
    <FormattedMessage
      id="administration.actions"
      description="Actions"
      defaultMessage="Actions"
    />
  ),
  userRoleorEmailorID: (
    <FormattedMessage
      id="product.userRoleorEmailorID"
      description="user name or email ID or user ID"
      defaultMessage="user name or email ID or user ID"
    />
  ),
  allAccess: (
    <FormattedMessage
      id="administration.allAccess"
      description="All access"
      defaultMessage="All access"
    />
  ),
  view: (
    <FormattedMessage
      id="administration.view"
      description="View"
      defaultMessage="View"
    />
  ),
  accessControlList: (
    <FormattedMessage
      id="administration.accessControlList"
      description="Access Control List"
      defaultMessage="Access Control List"
    />
  ),
  features: (
    <FormattedMessage
      id="administration.features"
      description="Features"
      defaultMessage="Features"
    />
  ),
  createOrEdit: (
    <FormattedMessage
      id="administration.createOrEdit"
      description="Create/Edit"
      defaultMessage="Create/Edit"
    />
  ),
  aclDependency: (
    <FormattedMessage
      id="administration.aclDependency"
      description="ACL Dependency"
      defaultMessage="ACL Dependency"
    />
  ),
  roleTitle: (
    <FormattedMessage
      id="administration.roleTitle"
      description="Role Title"
      defaultMessage="Role Title"
    />
  ),
  roleDetails: (
    <FormattedMessage
      id="administration.roleDetails"
      description="Role Details"
      defaultMessage="Role Details"
    />
  ),
};

export const customerTranslations = {
  customerName: (
    <FormattedMessage
      id="customer.customerName"
      description="Customer Name"
      defaultMessage="Customer Name"
    />
  ),
  contactNumber: (
    <FormattedMessage
      id="customer.contactNumber"
      description="Contact Number"
      defaultMessage="Contact Number"
    />
  ),
  emailAddress: (
    <FormattedMessage
      id="customer.emailAddress"
      description="Email Address"
      defaultMessage="Email Address"
    />
  ),
  customerEmailAddress: (
    <FormattedMessage
      id="customer.customerEmailAddress"
      description="Customer Email Id"
      defaultMessage="Customer Email Id"
    />
  ),
  addressLine1: (
    <FormattedMessage
      id="customer.addressLine1"
      description="House No. / Building No."
      defaultMessage="House No. / Building No."
    />
  ),
  addressLine2: (
    <FormattedMessage
      id="customer.addressLine2"
      description="Street / Locality"
      defaultMessage="Street / Locality"
    />
  ),
  pincode: (
    <FormattedMessage
      id="customer.pincode"
      description="Zip Code"
      defaultMessage="Zip Code"
    />
  ),
  province: (
    <FormattedMessage
      id="customer.province"
      description="Province"
      defaultMessage="Province"
    />
  ),
  city: (
    <FormattedMessage
      id="customer.city"
      description="City"
      defaultMessage="City"
    />
  ),
  district: (
    <FormattedMessage
      id="customer.district"
      description="District"
      defaultMessage="District"
    />
  ),
  subDistrict: (
    <FormattedMessage
      id="customer.subDistrict"
      description="Sub District"
      defaultMessage="Sub District"
    />
  ),
  addDeliveryAddress: (
    <FormattedMessage
      id="customer.addDeliveryAddress"
      description="Add Delivery Address"
      defaultMessage="Add Delivery Address"
    />
  ),
  deliveryDetails: (
    <FormattedMessage
      id="customer.deliveryDetails"
      description="Delivery Details"
      defaultMessage="Delivery Details"
    />
  ),
  createCustomerConfirmation: (
    <FormattedMessage
      id="customer.createCustomerConfirmation"
      description="Do you really want to add the delivery details?"
      defaultMessage="Do you really want to add the delivery details?"
    />
  ),
  address: (
    <FormattedMessage
      id="customer.address"
      description="Address"
      defaultMessage="Address"
    />
  ),
  deliveryAddressAddedSuccessfully: (
    <FormattedMessage
      id="customer.deliveryAddressAddedSuccessfully"
      description="Delivery address has been added successfully"
      defaultMessage="Delivery address has been added successfully"
    />
  ),
  orderAddressUpdatedSuccessfully: (
    <FormattedMessage
      id="customer.orderAddressUpdatedSuccessfully"
      description="Order address has been updated successfully"
      defaultMessage="Order address has been updated successfully"
    />
  ),
};

export const channelTranslations = {
  addressLine1: (
    <FormattedMessage
      id="channel.addressLine1"
      description="Address Line 1"
      defaultMessage="Address Line 1"
    />
  ),
  addressLine2: (
    <FormattedMessage
      id="channel.addressLine2"
      description="Address Line 2"
      defaultMessage="Address Line 2"
    />
  ),
  name: (
    <FormattedMessage
      id="channel.name"
      description="Channel Name"
      defaultMessage="Channel Name"
    />
  ),
  channelId: (
    <FormattedMessage
      id="channel.id"
      description="Channel ID"
      defaultMessage="Channel ID"
    />
  ),
  createdDate: (
    <FormattedMessage
      id="channel.createdDate"
      description="Created Date"
      defaultMessage="Created Date"
    />
  ),
  address: (
    <FormattedMessage
      id="channel.address"
      description="Address"
      defaultMessage="Address"
    />
  ),
  actions: (
    <FormattedMessage
      id="channel.actions"
      description="Actions"
      defaultMessage="Actions"
    />
  ),
};

export const collectionTranslations = {
  createCollection: (
    <FormattedMessage
      id="customer.createCollection"
      description="Create Collection"
      defaultMessage="Create Collection"
    />
  ),
  collectionName: (
    <FormattedMessage
      id="collection.collectionName"
      description="Collection Name"
      defaultMessage="Collection Name"
    />
  ),
  collectionId: (
    <FormattedMessage
      id="collection.collectionId"
      description="Collection ID"
      defaultMessage="Collection ID"
    />
  ),
  createdDate: (
    <FormattedMessage
      id="collection.createdDate"
      description="Created Date"
      defaultMessage="Created Date"
    />
  ),
  listingStatus: (
    <FormattedMessage
      id="collection.listingStatus"
      description="Listing Status"
      defaultMessage="Listing Status"
    />
  ),
  actions: (
    <FormattedMessage
      id="collection.actions"
      description="Actions"
      defaultMessage="Actions"
    />
  ),
  deleteConfirmation: (name) => (
    <FormattedMessage
      id="collection.deleteConfirmation"
      description="Do you really want to remove the{br}Collection {name} ?"
      defaultMessage="Do you really want to remove the{br}Collection {name} ?"
      values={{ br: <br />, name }}
    />
  ),
  name: (
    <FormattedMessage
      id="collection.name"
      description="Name"
      defaultMessage="Name"
    />
  ),
  statusChangeConfirmation: (name) => (
    <FormattedMessage
      id="collection.statusChangeConfirmation"
      description="Do you really want to change the Listing Status{br}of collection {name} ?"
      defaultMessage="Do you really want to change the Listing Status{br}of collection {name} ?"
      values={{ br: <br />, name }}
    />
  ),
  collectionDeletedSuccessfully: (
    <FormattedMessage
      id="notification.collectionDeletedSuccessfully"
      description="Collection Deleted Successfully"
      defaultMessage="Collection Deleted Successfully"
    />
  ),
  addedItem: (count) => (
    <FormattedMessage
      id="collection.addedItem"
      description="Add Items ({count} Items added)"
      defaultMessage="Add Items ({count} Items added)"
      values={{ count }}
    />
  ),
};

export const reviewsTranslations = {
  reviewTitle: (
    <FormattedMessage
      id="reviews.reviewTitle"
      description="Review Title"
      defaultMessage="Review Title"
    />
  ),
  reviewDescription: (
    <FormattedMessage
      id="reviews.reviewDescription"
      description="Description"
      defaultMessage="Description"
    />
  ),
  rating: (
    <FormattedMessage
      id="reviews.rating"
      description="Rating"
      defaultMessage="Rating"
    />
  ),
  productName: (
    <FormattedMessage
      id="reviews.productName"
      description="Product Name"
      defaultMessage="Product Name"
    />
  ),
  userName: (
    <FormattedMessage
      id="reviews.userName"
      description="User Name"
      defaultMessage="User Name"
    />
  ),
  dummyUsername: (
    <FormattedMessage
      id="reviews.dummyUsername"
      description="Dummy User Name"
      defaultMessage="Dummy User Name"
    />
  ),
  publishStatus: (
    <FormattedMessage
      id="reviews.publishStatus"
      description="Publish Status"
      defaultMessage="Publish Status"
    />
  ),
  statusChangeConfirmation: (reviewTitle) => (
    <FormattedMessage
      id="reviews.statusChangeConfirmation"
      description="Do you really want to change the Publish Status{br}of review {reviewTitle} ?"
      defaultMessage="Do you really want to change the Publish Status{br}of review {reviewTitle} ?"
      values={{ br: <br />, reviewTitle }}
    />
  ),
  addProduct: (
    <FormattedMessage
      id="collection.addProduct"
      description="Add Product"
      defaultMessage="Add Product"
    />
  ),
  addedProduct: (productName) => (
    <FormattedMessage
      id="collection.addedProduct"
      description=" ({productName} added)"
      defaultMessage=" ({productName} added)"
      values={{ productName }}
    />
  ),
};

export const challengeTranslations = {
  challengeImage: (
    <FormattedMessage
      id="challenge.challengeImage"
      description="Challenge Image"
      defaultMessage="Challenge Image"
    />
  ),
  challengeName: (
    <FormattedMessage
      id="challenge.challengeName"
      description="Challenge Name"
      defaultMessage="Challenge Name"
    />
  ),
  shortDescriptionShownInApp: (
    <FormattedMessage
      id="challenge.shortDescription"
      description="Short Description (Shown in App)"
      defaultMessage="Short Description (Shown in App)"
    />
  ),
  shortDescription: (
    <FormattedMessage
      id="challenge.shortDescription"
      description="Short Description"
      defaultMessage="Short Description"
    />
  ),
  taskShortDescription: (
    <FormattedMessage
      id="challenge.taskShortDescription"
      description="Task Short Description"
      defaultMessage="Task Short Description"
    />
  ),
  uploadThumbnail: (
    <FormattedMessage
      id="challenge.uploadThumbnail"
      description="Upload Thumbnail"
      defaultMessage="Upload Thumbnail"
    />
  ),
  deliveryAddress: (
    <FormattedMessage
      id="challenge.deliveryAddress"
      description="Delivery Address"
      defaultMessage="Delivery Address"
    />
  ),
  longDescriptionShownInApp: (
    <FormattedMessage
      id="challenge.longDescription"
      description="Long Description (Shown in App)"
      defaultMessage="Long Description (Shown in App)"
    />
  ),
  longDescription: (
    <FormattedMessage
      id="challenge.longDescription"
      description="Long Description"
      defaultMessage="Long Description"
    />
  ),
  taskLongDescription: (
    <FormattedMessage
      id="challenge.taskLongDescription"
      description="Task Long Description"
      defaultMessage="Task Long Description"
    />
  ),
  endDate: (
    <FormattedMessage
      id="challenge.endDate"
      description="End Date"
      defaultMessage="End Date"
    />
  ),
  rewards: (
    <FormattedMessage
      id="challenge.rewards"
      description="Rewards"
      defaultMessage="Rewards"
    />
  ),
  uploadBannerImage: (
    <FormattedMessage
      id="challenge.uploadBannerImage"
      description="Upload Banner Image"
      defaultMessage="Upload Banner Image"
    />
  ),
  challengeTitle: (
    <FormattedMessage
      id="challenge.challengeTitle"
      description="Challenge Title"
      defaultMessage="Challenge Title"
    />
  ),
  challengeDescription: (
    <FormattedMessage
      id="challenge.challengeDescription"
      description="Challenge Description"
      defaultMessage="Challenge Description"
    />
  ),
  rewardDetails: (
    <FormattedMessage
      id="challenge.rewardDetails"
      description="Reward Details"
      defaultMessage="Reward Details"
    />
  ),
  bannerImage: (
    <FormattedMessage
      id="challenge.bannerImage"
      description="Banner Image"
      defaultMessage="Banner Image"
    />
  ),
  reward: (
    <FormattedMessage
      id="challenge.reward"
      description="Reward"
      defaultMessage="Reward"
    />
  ),
  couponDescription: (
    <FormattedMessage
      id="challenge.couponDescription"
      description="Coupon Description"
      defaultMessage="Coupon Description"
    />
  ),
  taskDetails: (
    <FormattedMessage
      key="taskDetails"
      id="challenge.taskDetails"
      description="Task Details"
      defaultMessage="Task Details"
    />
  ),
  userData: (
    <FormattedMessage
      key="userData"
      id="challenge.userData"
      description="User Data"
      defaultMessage="User Data"
    />
  ),
  taskType: (
    <FormattedMessage
      id="challenge.taskType"
      description="Task Type"
      defaultMessage="Task Type"
    />
  ),
  target: (
    <FormattedMessage
      id="challenge.target"
      description="Target"
      defaultMessage="Target"
    />
  ),
  task: (
    <FormattedMessage
      id="challenge.task"
      description="Task"
      defaultMessage="Task"
    />
  ),
  typeOfTask: (
    <FormattedMessage
      id="challenge.typeOfTask"
      description="Type of task"
      defaultMessage="Type of task"
    />
  ),
  orderAmount: (
    <FormattedMessage
      id="challenge.orderAmount"
      description="Order Amount (Single Order during the valid period)"
      defaultMessage="Order Amount (Single Order during the valid period)"
    />
  ),
  noOfOrders: (
    <FormattedMessage
      id="challenge.noOfOrders"
      description="No of Orders (During the valid period)"
      defaultMessage="No of Orders (During the valid period)"
    />
  ),
  noOfCustomerOrders: (
    <FormattedMessage
      id="challenge.noOfCustomerOrders"
      description="No of Customer Orders (During the valid period)"
      defaultMessage="No of Customer Orders (During the valid period)"
    />
  ),
  enterTheAmount: (
    <FormattedMessage
      id="challenge.enterTheAmount"
      description="Enter The Amount"
      defaultMessage="Enter The Amount"
    />
  ),
  addTask: (
    <FormattedMessage
      id="challenge.addTask"
      description="Add Task"
      defaultMessage="Add Task"
    />
  ),
  couponTitle: (
    <FormattedMessage
      id="challenge.couponTitle"
      description="Coupon Title"
      defaultMessage="Coupon Title"
    />
  ),
  enterNoOfOrders: (
    <FormattedMessage
      id="challenge.enterNoOfOrders"
      description="Enter No of Orders"
      defaultMessage="Enter No of Orders"
    />
  ),
  participationAnalytics: (
    <FormattedMessage
      id="challenge.participationAnalytics"
      description="Participation Analytics"
      defaultMessage="Participation Analytics"
    />
  ),
  completionRewardsAnalytics: (
    <FormattedMessage
      id="challenge.participationAnalytics"
      description="Completion & Rewards Analytics"
      defaultMessage="Completion & Rewards Analytics"
    />
  ),
  totalParticipants: (
    <FormattedMessage
      id="challenge.totalParticipants"
      description="Total Participants"
      defaultMessage="Total Participants"
    />
  ),
  totalNoOfParticipants: (
    <FormattedMessage
      id="challenge.totalNoOfParticipants"
      description="Total No of Participants"
      defaultMessage="Total No of Participants"
    />
  ),
  completedTaskOne: (taskType) => (
    <FormattedMessage
      id="challenge.completedTaskOne"
      description="Partcipants who completed Task 1 ({taskType})"
      defaultMessage="Partcipants who completed Task 1 ({taskType})"
      values={{ taskType }}
    />
  ),
  completedTaskTwo: (taskType) => (
    <FormattedMessage
      id="challenge.completedTaskTwo"
      description="Partcipants who completed Task 2 ({taskType})"
      defaultMessage="Partcipants who completed Task 2 ({taskType})"
      values={{ taskType }}
    />
  ),
  completedFirstTask: (
    <FormattedMessage
      id="challenge.completedFirstTask"
      description="Completed First Task"
      defaultMessage="Completed First Task"
    />
  ),
  notCompletedFirstTask: (
    <FormattedMessage
      id="challenge.notCompletedFirstTask"
      description="Not Completed First Task"
      defaultMessage="Not Completed First Task"
    />
  ),
  completedSecondTask: (
    <FormattedMessage
      id="challenge.completedSecondTask"
      description="Completed Second Task"
      defaultMessage="Completed Second Task"
    />
  ),
  notCompletedSecondTask: (
    <FormattedMessage
      id="challenge.notCompletedSecondTask"
      description="Not Completed Second Task"
      defaultMessage="Not Completed Second Task"
    />
  ),
  completedChallenge: (
    <FormattedMessage
      id="challenge.completedChallenge"
      description="Completed Challenge"
      defaultMessage="Completed Challenge"
    />
  ),
  notCompletedChallenge: (
    <FormattedMessage
      id="challenge.notCompletedChallenge"
      description="Not Completed Challenge"
      defaultMessage="Not Completed Challenge"
    />
  ),
  redeemedCoupon: (
    <FormattedMessage
      id="challenge.redeemedCoupon"
      description="Redeemed Coupon"
      defaultMessage="Redeemed Coupon"
    />
  ),
  notRedeemedCoupon: (
    <FormattedMessage
      id="challenge.notRedeemedCoupon"
      description="Not Redeemed Coupon"
      defaultMessage="Not Redeemed Coupon"
    />
  ),
  completedChallenges: (
    <FormattedMessage
      id="challenge.completedChallenges"
      description="Partcipants who completed the challenge"
      defaultMessage="Partcipants who completed the challenge"
    />
  ),
  redeemedCoupons: (
    <FormattedMessage
      id="challenge.redeemedCoupons"
      description="Partcipants who redeemed the coupon"
      defaultMessage="Partcipants who redeemed the coupon"
    />
  ),
  not: (
    <FormattedMessage
      id="challenge.not"
      description="Not"
      defaultMessage="Not"
    />
  ),
  noData: (
    <FormattedMessage
      id="challenge.noData"
      description="No data available"
      defaultMessage="No data available"
    />
  ),
  noOfTask: (
    <FormattedMessage
      id="challenges.noOfTask"
      description="No. of task"
      defaultMessage="No.of task"
    />
  ),
  rewardType: (
    <FormattedMessage
      id="challenges.rewardType"
      description="Reward Type"
      defaultMessage="Reward Type"
    />
  ),
  createChallenge: (
    <FormattedMessage
      id="challenges.createChallenge"
      description="Create Challenge"
      defaultMessage="Create Challenge"
    />
  ),
  upgradePeriod: (
    <FormattedMessage
      id="challenges.upgradePeriod"
      description="Upgrade Period (Days)"
      defaultMessage="Upgrade Period (Days)"
    />
  ),
  startDate: (
    <FormattedMessage
      id="challenges.startDate"
      description="Start Date"
      defaultMessage="Start Date"
    />
  ),
  duplicateChallengeConfirmMessage: (name) => (
    <FormattedMessage
      id="challenges.startDate"
      description="Do you want to duplicate the challenge {name} ?{br}It requires a few changes to be done."
      defaultMessage="Do you want to duplicate the challenge {name} ?{br}It requires a few changes to be done."
      values={{
        name,
        br: <br />,
      }}
    />
  ),
  tierUpgradeInfo: (
    <FormattedMessage
      id="challenges.tierUpgradeInfo"
      description="The period to which the user gets upgraded for"
      defaultMessage="The period to which the user gets upgraded for"
    />
  ),
  titleShownInApp: (
    <FormattedMessage
      id="challenges.titleShownInApp"
      description="Title (Shown In App)"
      defaultMessage="Title (Shown In App)"
    />
  ),
  challengeSearchLabel: (
    <FormattedMessage
      id="challenges.challengesSearchLabel"
      description="Challenge Name / Title"
      defaultMessage="Challenge Name / Title"
    />
  ),
  coupons: (
    <FormattedMessage
      id="challenges.coupons"
      description="Coupons"
      defaultMessage="Coupons"
    />
  ),
  replicate: (
    <FormattedMessage
      id="challenge.replicate"
      description="Replicate"
      defaultMessage="Replicate"
    />
  ),
  allowedValueRangeMessage: (
    <FormattedMessage
      id="challenge.allowedValueRangeMessage"
      description="Allowed values range from 1 to 999,999,999"
      defaultMessage="Allowed values range from 1 to 999,999,999"
    />
  ),
};

export const paymentTranslations = {
  switchOnLegacy: (
    <FormattedMessage
      id="payment.switchOnLegacy"
      description="Switch On Legacy"
      defaultMessage="Switch On Legacy"
    />
  ),
  switchOffLegacy: (
    <FormattedMessage
      id="payment.switchOffLegacy"
      description="Switch Off Legacy"
      defaultMessage="Switch Off Legacy"
    />
  ),
  paymentMethod: (
    <FormattedMessage
      id="payment.paymentMethod"
      description="Payment Method"
      defaultMessage="Payment Method"
    />
  ),
  type: (
    <FormattedMessage
      id="payment.type"
      description="Type"
      defaultMessage="Type"
    />
  ),
  expiryTime: (
    <FormattedMessage
      id="payment.expiryTime"
      description="Expiry Time"
      defaultMessage="Expiry Time"
    />
  ),
  paymentTime: (
    <FormattedMessage
      id="payment.paymentTime"
      description="Payment Time"
      defaultMessage="Payment Time"
    />
  ),
  active: (
    <FormattedMessage
      id="payment.active"
      key="payment_active"
      description="Active"
      defaultMessage="Active"
    />
  ),
  inActive: (
    <FormattedMessage
      id="payment.inActive"
      key="payment_inactive"
      description="Inactive"
      defaultMessage="Inactive"
    />
  ),
  uploadThumbnail: (
    <FormattedMessage
      id="payment.uploadThumbnail"
      description="Upload Thumbnail (50x24px)"
      defaultMessage="Upload Thumbnail (50x24px)"
    />
  ),
  addInstructions: (
    <FormattedMessage
      id="payment.addInstructions"
      description="Add Instructions"
      defaultMessage="Add Instructions"
    />
  ),
  generalInstructionsEn: (
    <FormattedMessage
      id="payment.generalInstructionsEn"
      description="General Instructions"
      defaultMessage="General Instructions"
    />
  ),
  generalInstructionsBahasa: (
    <FormattedMessage
      id="payment.generalInstructionsBahasa"
      description="General Instructions in Bahasa"
      defaultMessage="General Instructions in Bahasa"
    />
  ),
  sectionTitle: (
    <FormattedMessage
      id="payment.sectionTitle"
      description="Section Title"
      defaultMessage="Section Title"
    />
  ),
  sectionTitleEnglishWithCharLimit: (sectionNumber) => (
    <FormattedMessage
      id="payment.sectionTitleEnglishWithCharLimit"
      description="Section {sectionNumber} - Title (Recommended Character limit-15)"
      defaultMessage="Section {sectionNumber} - Title (Recommended Character limit-15)"
      values={{
        sectionNumber,
      }}
    />
  ),
  sectionTitleEnglish: (sectionNumber) => (
    <FormattedMessage
      id="payment.sectionTitleEnglish"
      description="Section {sectionNumber} - Title"
      defaultMessage="Section {sectionNumber} - Title"
      values={{
        sectionNumber,
      }}
    />
  ),
  sectionTitleBahasa: (sectionNumber) => (
    <FormattedMessage
      id="payment.sectionTitleBahasa"
      description="Section {sectionNumber} - Title(Bahasa)"
      defaultMessage="Section {sectionNumber} - Title(Bahasa)"
      values={{
        sectionNumber,
      }}
    />
  ),
  sectionTitleBahasaWithCharLimit: (sectionNumber) => (
    <FormattedMessage
      id="payment.sectionTitleBahasaWithCharLimit"
      description="Section {sectionNumber} - Title(Bahasa, Recommended Character limit-15)"
      defaultMessage="Section {sectionNumber} - Title(Bahasa, Recommended Character limit-15)"
      values={{
        sectionNumber,
      }}
    />
  ),
  addSection: (
    <FormattedMessage
      id="payment.addSection"
      description="Add Section"
      defaultMessage="Add Section"
    />
  ),
  instructions: (
    <FormattedMessage
      id="payment.instructions"
      description="Instructions"
      defaultMessage="Instructions"
    />
  ),
  stepTitleEnglish: (stepNumber) => (
    <FormattedMessage
      id="payment.stepTitleEnglish"
      description="Step {stepNumber}"
      defaultMessage="Step {stepNumber}"
      values={{
        stepNumber,
      }}
    />
  ),
  stepTitleBahasa: (stepNumber) => (
    <FormattedMessage
      id="payment.stepTitleBahasa"
      description="Step {stepNumber} (Bahasa)"
      defaultMessage="Step {stepNumber} (Bahasa)"
      values={{
        stepNumber,
      }}
    />
  ),
  titleInBahasa: (
    <FormattedMessage
      id="payment.titleInBahasa"
      description="Title in Bahasa"
      defaultMessage="Title in Bahasa"
    />
  ),
  sectionInstructions: (sectionNumber) => (
    <FormattedMessage
      id="payment.sectionInstructions"
      description="Section {sectionNumber} - Instructions"
      defaultMessage="Section {sectionNumber} - Instructions"
      values={{
        sectionNumber,
      }}
    />
  ),
  stepDescriptionEnglish: (stepNumber) => (
    <FormattedMessage
      id="payment.stepDescriptionEnglish"
      description="Step {stepNumber} - Description"
      defaultMessage="Step {stepNumber} - Description"
      values={{
        stepNumber,
      }}
    />
  ),
  stepDescriptionBahasa: (stepNumber) => (
    <FormattedMessage
      id="payment.stepDescriptionBahasa"
      description="Step {stepNumber} - Description (Bahasa)"
      defaultMessage="Step {stepNumber} - Description (Bahasa)"
      values={{
        stepNumber,
      }}
    />
  ),
  maxAmountLimit: (
    <FormattedMessage
      id="payment.maxAmountLimit"
      description="Max. Amount Limit"
      defaultMessage="Max. Amount Limit"
    />
  ),
  minAmountLimit: (
    <FormattedMessage
      id="payment.minAmountLimit"
      description="Min. Amount Limit"
      defaultMessage="Min. Amount Limit"
    />
  ),
  thumbnailImage: (
    <FormattedMessage
      id="payment.thumbnailImage"
      description="Thumbnail Image"
      defaultMessage="Thumbnail Image"
    />
  ),
  viewInstructions: (
    <FormattedMessage
      id="payment.viewInstructions"
      description="View Instructions"
      defaultMessage="View Instructions"
    />
  ),
  isTokenisabe: (
    <FormattedMessage
      id="payment.isTokenisabe"
      description="It is tokenisable"
      defaultMessage="It is tokenisable"
    />
  ),
  paymentChannel: (
    <FormattedMessage
      id="payment.paymentChannel"
      description="Payment Channel"
      defaultMessage="Payment Channel"
    />
  ),
  paymentProvider: (
    <FormattedMessage
      id="payment.paymentProvider"
      description="Payment Provider"
      defaultMessage="Payment Provider"
    />
  ),
  tokenisable: (
    <FormattedMessage
      id="payment.tokenisable"
      description="Tokenisable"
      defaultMessage="Tokenisable"
    />
  ),
};

export const shippingTranslations = {
  provinceGroup: (
    <FormattedMessage
      id="shipping.provinceGroup"
      description="Province Group"
      defaultMessage="Province Group"
    />
  ),
  weight: (
    <FormattedMessage
      id="shipping.weight"
      description="Weight (Max value included)"
      defaultMessage="Weight (Max value included)"
    />
  ),
  lmRev: (
    <FormattedMessage
      id="shipping.lmRev"
      description="LM/REV (Min value included)"
      defaultMessage="LM/REV (Min value included)"
    />
  ),
  minOrderValue: (
    <FormattedMessage
      id="shipping.minOrderValue"
      description="Minimum Order Value"
      defaultMessage="Minimum Order Value"
    />
  ),
  shippingDiscount: (
    <FormattedMessage
      id="shipping.shippingDiscount"
      description="Shipping Discount"
      defaultMessage="Shipping Discount"
    />
  ),
  minWeightInKg: (
    <FormattedMessage
      id="shipping.minWeightInKg"
      description="Min Weight (kg)"
      defaultMessage="Min Weight (kg)"
    />
  ),
  minWeight: (
    <FormattedMessage
      id="shipping.minWeight"
      description="Min Weight"
      defaultMessage="Min Weight"
    />
  ),
  maxWeightInKg: (
    <FormattedMessage
      id="shipping.maxWeightInKg"
      description="Max Weight (Value included) (kg)"
      defaultMessage="Max Weight (Value included) (kg)"
    />
  ),
  maxWeight: (
    <FormattedMessage
      id="shipping.maxWeight"
      description="Max Weight (Value included)"
      defaultMessage="Max Weight (Value included)"
    />
  ),
  minLMREVPercentage: (
    <FormattedMessage
      id="shipping.minLMREVPercentage"
      description="Min Last Mile/Revenue (%)"
      defaultMessage="Min Last Mile/Revenue (%)"
    />
  ),
  minLMREV: (
    <FormattedMessage
      id="shipping.minLMREV"
      description="Min Last Mile/Revenue"
      defaultMessage="Min Last Mile/Revenue"
    />
  ),
  maxLMREVPercentage: (
    <FormattedMessage
      id="shipping.maxLMREVPercentage"
      description="Max Last Mile/Revenue (Value included) (%)"
      defaultMessage="Max Last Mile/Revenue (Value included) (%)"
    />
  ),
  maxLMREV: (
    <FormattedMessage
      id="shipping.maxLMREV"
      description="Max Last Mile/Revenue (Value included)"
      defaultMessage="Max Last Mile/Revenue (Value included)"
    />
  ),
  regularDiscount: (
    <FormattedMessage
      id="shipping.regularDiscount"
      description="Regular Discount(%)"
      defaultMessage="Regular Discount(%)"
    />
  ),
  truckingDiscount: (
    <FormattedMessage
      id="shipping.truckingDiscount"
      description="Trucking Discount(%)"
      defaultMessage="Trucking Discount(%)"
    />
  ),
  chooseTier: (
    <FormattedMessage
      id="shipping.chooseTier"
      description="Choose Tier"
      defaultMessage="Choose Tier"
    />
  ),
  shippingType: (
    <FormattedMessage
      id="shipping.shippingType"
      description="Shipping Type"
      defaultMessage="Shipping Type"
    />
  ),
  reorderRule: (
    <FormattedMessage
      id="shipping.reorderRule"
      description="Reorder Rule"
      defaultMessage="Reorder Rule"
    />
  ),
  defaultShipping: (
    <FormattedMessage
      id="shipping.defaultShipping"
      description="Default Shipping"
      defaultMessage="Default Shipping"
    />
  ),
  chooseDefaultShippingType: (
    <FormattedMessage
      id="shipping.chooseDefaultShippingType"
      description="Choose Default Shipping Type"
      defaultMessage="Choose Default Shipping Type"
    />
  ),
};

export const provinceGroupTranslations = {
  groupName: (
    <FormattedMessage
      id="provinceGroup.groupName"
      description="Group Name"
      defaultMessage="Group Name"
    />
  ),
  provinces: (
    <FormattedMessage
      id="provinceGroup.provinces"
      description="Provinces"
      defaultMessage="Provinces"
    />
  ),
  minOrderValue: (
    <FormattedMessage
      id="provinceGroup.minOrderValue"
      description="Min. Order Value"
      defaultMessage="Min. Order Value"
    />
  ),
  addedprovinces: (count) => (
    <FormattedMessage
      id="provinceGroup.addedprovinces"
      description="Add Provinces ({count} added)"
      defaultMessage="Add Provinces ({count} added)"
      values={{ count }}
    />
  ),
  partOfOtherGroup: (
    <FormattedMessage
      id="provinceGroup.partOfOtherGroup"
      description="Already part of other group"
      defaultMessage="Already part of other group"
    />
  ),
  multipleProvinceGroup: (
    <FormattedMessage
      id="provinceGroup.multipleProvinceGroup"
      description="One or more provinces selected are already a part of
     other province groups. Creation of new group will remove those
     provinces from other groups. Do you still want to proceed ?"
      defaultMessage="One or more provinces selected are already a part of
    other province groups. Creation of new group will remove those
    provinces from other groups. Do you still want to proceed ?"
    />
  ),
};
