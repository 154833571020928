import './Loading.scss'

const Loading = ({ white, small }) => {
  if (small) {
    return <div style={{ borderTop: `5px solid ${white ? 'white' : 'black'}` }} className='loader' />
  }
  return (
    <div className='loading-container'>
      <div className='lds-ring'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loading
