/* eslint-disable */
import { Box, Checkbox } from '@material-ui/core'
import { noop} from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import placeHolderImage from '../../../assets/drawer_backdrop.png'
import { BottomStickyButton } from '../../../components/BottomStickyButton/BottomStickyButton'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { commonTranslations } from '../../../translation/commonTranslations'
import EventEmitter, { Events } from '../../../utilities/eventEmitter'
import { useNavigateTo } from '../../../utilities/navigation'
import '../../OrderDetail/styles.scss'
import { deleteCart, selectAllCart, selectSpecificProductCart, updateCart } from '../saga/saga'
import CartItem from './CartItem'
import './styles.scss'

const Cart = () => {
  const { search } = useLocation()
  const history = useHistory()
  const { navigateTo } = useNavigateTo()
  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
   if (!search) {
    navigateTo('')
    return
   }
   const params = search.includes('resellerId=')
   if (!params) {
    navigateTo('')
    return
   }
  }, [])
  
  const onContinueFromCart = () => {
    EventEmitter.emit(Events.LOAD_PERSONAL_DETAILS, {
      type: 'cart'
    })
    navigateTo('checkout')
    window.localStorage.setItem('from_cart', true)
  }
  const { list } = useSelector((state) => state.cart)
  const [eventData, updateEventData]=useState({})

  const onChangeQuantity = (action, product) => {
    if (product?.quantity === 1 && action === 'decrement') return
    if (product?.quantity >= (product.stockLimit - product.reserveStock) && action !== 'decrement') return
    const data = {...product, quantity: action === 'decrement' ? product?.quantity - 1 : product?.quantity + 1}
    updateCart(data)
  }

  const onChangeQuantityValue = (inputedValue, product) => {
    const intValue = parseInt(inputedValue)
    if (intValue <= 0) {
        const data = {...product, quantity: 1}
        updateCart(data)
      return
    }
    if (intValue >= (product.stockLimit - product.reserveStock)) {
        const data = {...product, quantity: (product.stockLimit - product.reserveStock) === 0 ? 1 : (product.stockLimit - product.reserveStock)}
        updateCart(data)
      return
    }

    const data = {...product, quantity: intValue}
    updateCart(data)
  }

  const onDeleteCart = (product) => {
      deleteCart(product)
  }

  const onSelectAll = () => {
      selectAllCart(list.every(item => item.selected) ? false : true)
  }

  const onSelectSpecificProduct = (product) => {
      selectSpecificProductCart(product)
  }

  useEffect(() => {
    EventEmitter.emit(Events.LOAD_CART)
  }, [])

  useEffect(() => {
    updateEventData({
      sku_code: list?.map(li => li.sku).toString(),
      inventory_count: list?.map(li => li.stockLimit).toString(),
      quantity: list?.map(li => li.quantity).toString()
    })
  }, [list])

  useEffect(() => {
    if (Object.keys(eventData).length > 0) {
      EventEmitter.emit(Events.VIEW_CART, eventData)
    }
  }, [eventData])

  return (
    <div style={{ minHeight: window.innerHeight }} className='w-full'>
      <Box style={{ minHeight: window.innerHeight }} className='order-details'>
        <MobileHeader
          onClick={goBack}
          heading={commonTranslations.cart}
        />
        <div className='w-full bg-[#F5F6FC] pb-[20px]'>
        {list.length > 0 &&
          <div className='w-full px-[5px] bg-white flex items-center mt-3'>
            <Checkbox onClick={onSelectAll} checked={list.every(item => item.selected)} color='primary' />
            <p className='text-[14px] text-[#000000]'>
              {commonTranslations.selectAll}
            </p>
          </div>}

          {list.length > 0 ? list.map((item, i) => (
            <CartItem
              key={i}
              image={item.productImages?.[0]?.url || placeHolderImage}
              name={item?.name}
              quantity={item?.quantity}
              price={item?.price}
              slashedPrice={item?.slashedPrice}
              discount={item?.discount}
              selected={item?.selected}
              onChangeQuantityValue={onChangeQuantityValue}
              onChangeQuantity={onChangeQuantity}
              onDeleteCart={onDeleteCart}
              onSelectSpecificProduct={onSelectSpecificProduct}
              {...item}
            />
          )) : <div className='flex items-center justify-center min-h-[400px]'>
            <p className='text-[18px]'>{commonTranslations.cartIsEmpty}</p>
            </div>}
        </div>
      </Box>
        <BottomStickyButton
          label={commonTranslations.continue}
          onClick={list.length > 0 && list.some(item => item.selected) ? onContinueFromCart : noop}
          disabled={list.length > 0 && list.some(item => item.selected) ? false : true}
        />
     </div>
  )
}

export default Cart
