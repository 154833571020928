import { FETCH_RELATED_VIDEOS_FAIL, FETCH_RELATED_VIDEOS_INIT, FETCH_RELATED_VIDEOS_SUCCESS, FETCH_SIMILAR_PRODUCTS_FAIL, FETCH_SIMILAR_PRODUCTS_INIT, FETCH_SIMILAR_PRODUCTS_SUCCESS } from '../../../../../../actions'
import apiCall from '../../../../../../saga/api'

export function getProductRelatedVideos (data) {
  const { productId } = data
  const url = `/dsf/products/video-sharing/?productId=${productId}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_RELATED_VIDEOS_INIT,
      successType: FETCH_RELATED_VIDEOS_SUCCESS,
      failureType: FETCH_RELATED_VIDEOS_FAIL
    }
  }
  // Accept response if necessary with await
  apiCall(apiArgs)
}

export function getSimilarProducts (data) {
  const { shareId } = data
  const url = `/dsf/sellers/${shareId}/products`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_SIMILAR_PRODUCTS_INIT,
      successType: FETCH_SIMILAR_PRODUCTS_SUCCESS,
      failureType: FETCH_SIMILAR_PRODUCTS_FAIL
    }
  }
  // Accept response if necessary with await
  apiCall(apiArgs)
}
