/* eslint-disable */
import produce from 'immer'
import { ADD_CART, UPDATE_CART, DELETE_CART, SET_CART, SELECT_ALL_CART, SELECT_SPECIFIC_CART } from '../../../actions'

const defaultState = {
  list: []
}

export default produce((state = defaultState, action) => {
  const { type, data } = action
  switch (type) {
    case SELECT_ALL_CART: {
      const list = [...state.list]
      state.list = list.map(item => ({...item, selected: data}))
      window.localStorage.setItem('cart', JSON.stringify(list))
      break
    }
    case ADD_CART: {
      const index = state.list.findIndex((item) => item.id === data.id)

      const list = [...state.list]
      if (index > -1) {
        list[index] = data
      }
      else {
        list.push(data)
      }
      state.list = list
      window.localStorage.setItem('cart', JSON.stringify(list))
      break
    }
    case UPDATE_CART: {
      const index = state.list.findIndex((item) => item.id === data.id)

      if (index > -1) {
        const list = [...state.list]
        list[index] = data
        state.list = list
        window.localStorage.setItem('cart', JSON.stringify(list))
      }
      break
    }
    case SELECT_SPECIFIC_CART: {
      const index = state.list.findIndex((item) => item.id === data.id)

      if (index > -1) {
        const list = [...state.list]
        list[index] = {...list[index], selected: !list[index].selected}
        state.list = list
        window.localStorage.setItem('cart', JSON.stringify(list))
      }
      break
    }
    case DELETE_CART: {
      const list = [...state.list]
      const updatedList = list.filter((item) => item.id !== data?.id) || []
      state.list = list.filter((item) => item.id !== data?.id) || []
      window.localStorage.setItem('cart', JSON.stringify(updatedList))
      break
    }
    case SET_CART: {
      state.list = data
      window.localStorage.setItem('cart', JSON.stringify(data))
      break
    }
    default:
      return state
  }
}, defaultState)
