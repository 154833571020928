/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import MobileHeader from '../../components/MobileHeader/MobileHeader'
import { commonTranslations, errorTranslations } from '../../translation/commonTranslations'
import { cancelPayment, eWalletPayment, getPaymentsList, virtualAccountPayment } from './saga/saga'
import othersImage from '../../assets/otherPaymentType.svg'
import paymenProgressIcon from '../../assets/paymenProgressIcon.svg'
import copyIcon from '../../assets/copyIcon.svg'
import moment from 'moment'
import './styles.scss'
import durianpaylogo from '../../assets/durianpaylogo.svg'
import bcaCopyIcon from '../../assets/bcaCopyIcon.svg'
import downloadDetailsIcon from '../../assets/downloadDetailsIcon.svg'
import grayCross from '../../assets/grayCross.svg'
import Button from '../../components/Button'
import { themeColor } from '../../utilities/colors'
import { createOrder, paymentInit } from '../OrderDetail/saga/saga'
import { Box, Grid, Modal, Typography } from '@material-ui/core'
import store from '../../store'
import { CLEAR_INITIATE_PAYMENTS, CLEAR_PAYMENT_FAILED_BY_USER, EWALLET_PAYMENT_FAIL, FETCH_PAYMENTS_LIST_INIT, NOTIFIER_SHOW } from '../../actions'
import PaymentFailed from '../../components/PaymentFailed/PaymentFailed'
import { createFileName, useScreenshot } from 'use-react-screenshot'
import EventEmitter, { Events } from '../../utilities/eventEmitter'
import { numberWithCommas } from '../../utilities/currency'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import WalletBlack from '../../assets/walletBlack.svg'
import BlackArrowDown from '../../assets/blackArrowDown.svg'
import BlackArrowUp from '../../assets/blackArrowUp.svg'
import BlackBank from '../../assets/blackBank.svg'
import BlackOthers from '../../assets/blackOthers.svg'
import ShopeepayQRInstructions from '../../assets/shopeepayQRInstructions.svg'
import ovoDetail from '../../assets/ovoDetail.svg'
import ovoNotification from '../../assets/ovoNotification.png'
import { PaymentsStickyBottombar } from '../../components/PaymentsStickyBottombar/PaymentsStickyBottombar'
import { noop } from 'lodash'
import validator from 'validator'
import { isMobilePhone, isValidIndonesianNumber } from '../../utilities/suborderUtils'
import { useFetchCustomer, useFetchProduct, useFetchShipments } from '../../utilities/navigation'
import PaymentModal from '../../components/PaymentModal/PaymentModal'
import SuccessModal from '../../components/SuccessModal/SuccessModal'

const shopeeInstructions = [
  commonTranslations.shopeeFirstStep,
  commonTranslations.shopeeSecondStep,
  commonTranslations.shopeeThirdStep,
  commonTranslations.shopeeFourthStep,
  commonTranslations.shopeeFifthStep,
  commonTranslations.shopeeSixthStep,
  commonTranslations.shopeeSeventhStep
]

export const PaymentPage = () => {
  const { shareId, sku } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { eta, shipments } = useFetchShipments()
  const { fromCart, product, user, dsf } = useFetchProduct()
  const { customer: orderData } = useFetchCustomer()
  const { list } = useSelector(state => state.payment.paymentsList)
  const { isLoading: paymentInitLoading } = useSelector(state => state.subOrder?.createOrder)
  const { isLoading, virtual, ewallet, paymentInProcess } = useSelector(state => state.payment)
  const { id: orderId } = useSelector(state => state.subOrder.createOrder.data)
  const isPaymentFailed = useSelector(state => state.subOrder.paymentFailed)
  const { isLoading: orderDetailLoading, createOrder: orderPayload } = useSelector(state => state.subOrder)
  const cartList = useSelector(state => state.cart.list)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({})
  const [choosePayment, setChoosePayment] = useState({})
  const [countDown, setCountDown] = useState(moment().hour(0).minute(2).second(60).format('mm : ss'))
  const [selectedInstruction, setSelectedInstruction] = useState(0)
  const timeRef = useRef()
  const bcaScreenRef = useRef()
  const shopeeScreenRef = useRef()
  const [cancelPaymentModal, setCancelPaymentModal] = useState(false)
  const [openedMethodType, setOpenedMethodType] = useState('')
  const [ovoMobileModal, setOvoMobileModal] = useState(false)
  const [ovoMobileNumber, setOvoMobileNumber] = useState()
  const [paymentGuideModal, setPaymentGuideModal] = useState(false)
  const [isOvoMobileValid, setIsOvoMobileValid] = useState(true)
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
  const [isBackbuttonPressed, setIsBackbuttonPressed] = useState(false)
  const goBack = () => {
    history.goBack()
  }
  const fetchOthersPayUrl = (url) => {
    window.windowReference.location = url
  }
  const closeFailModal = noop
  const openSuccessModal = () => {
    setIsOpenSuccessModal(true)
    setSelectedPaymentMethod({})
    setChoosePayment({})
  }

  const formattedNumberForOvo = () => {
    let number = ovoMobileNumber
    if (number.startsWith('0')) {
      number = `+62${number.substring(1, number.length)}`
    }
    if (number.startsWith('+62')) {
      number = number
    }
    else {
      number = `+62${number}`
    }
    return number
  }
  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  })
  let itemCost = 0
  let shippingAmount = 0
  shipments?.forEach(({ items = [], shippingFeeInfo = {} }, i) => {
    items.forEach(({ effectiveWholesalePrice = 0, quantity = 1 }) => {
      itemCost += parseFloat(effectiveWholesalePrice) * parseInt(quantity, 10)
    })
    shippingAmount += shippingFeeInfo.shippingPartners?.[eta[i]]?.slashedShippingAmount || 0
  })
  const paymentAmount = itemCost + shippingAmount
  const pickShippingAmount = (shippingFeeObj) => {
    // returns slashedShippingAmount if present, else shippingAmount
    if (shippingFeeObj.slashedShippingAmount === 0 || shippingFeeObj.slashedShippingAmount > 0) {
      return shippingFeeObj.slashedShippingAmount
    }
    return shippingFeeObj.shippingAmount
  }

  useEffect(() => {
    setOpenedMethodType(paymentAmount > 10000000 ? 'VIRTUAL_ACCOUNT' : 'EWALLET')
  }, [paymentAmount])

  useEffect(() => {
    setOvoMobileNumber(orderData?.contactNumber?.replace('+62', ''))
  }, [orderData])

  const createFinalOrder = (paymentCallback) => {
    const finalProduct = product
    const data = {
      resellerId: user.id,
      customerId: orderData.id,
      stockType: finalProduct.stockType,
      shippingCity: orderData?.city,
      shippingProvince: orderData?.province,
      shippingPincode: orderData?.pincode,
      items: fromCart ? cartList.filter(item => item.selected).map(item => ({
        stockType: item?.stockType,
        sku: item?.sku,
        retailPrice: item?.retailPrice,
        productId: item?.id,
        stockArrivalDate: null,
        isSlashPriceEnabled: item?.isSlashPrice,
        slashedWholesalePrice: item?.slashedTierPrice?.[user?.tierId] || null,
        wholesalePrice: item.wholesalePrice,
        effectiveWholesalePrice: item.effectiveWholesalePrice,
        quantity: item.quantity,
        totalEarnings: item.margin,
        class: item?.class
      })) : [{
        ...shipments?.[0].items[0],
        isSlashPriceEnabled: product?.isSlashPrice,
        slashedWholesalePrice: product?.slashedTierPrice?.[user?.tierId] || null,
        wholesalePrice: dsf?.wholesalePrice,
        effectiveWholesalePrice: dsf?.effectiveWholesalePrice,
        quantity: shipments.map(item => item.items[0].quantity).reduce((sum, x) => sum + x),
        totalEarnings: dsf.margin,
        class: product?.class
      }],
      shipments: shipments.map((item, index) => ({
        ...item,
        shippingFeeInfo: {
          ...item.shippingFeeInfo,
          selectedShippingPartner: item?.shippingFeeInfo?.shippingPartners[eta[index]]
        },
        items: item?.items?.map(data => ({
          ...data,
          isSlashPriceEnabled: product?.isSlashPriceEnabled,
          slashedWholesalePrice: product?.slashedTierPrice?.[user?.tierId] || null,
          wholesalePrice: fromCart ? cartList.find(prod => prod.id === data?.productId)?.wholesalePrice : dsf?.wholesalePrice,
          effectiveWholesalePrice: fromCart ? cartList.find(prod => prod.id === data?.productId)?.effectiveWholesalePrice : dsf?.effectiveWholesalePrice,
        }))
      })),
      appliedShippingAmount: shipments.map((item, i) =>
        pickShippingAmount({
          shippingAmount:
            shipments?.[i]?.shippingFeeInfo?.shippingPartners[eta[i]].shippingAmount,
          slashedShippingAmount:
            shipments?.[i]?.shippingFeeInfo?.shippingPartners[eta[i]]
              .slashedShippingAmount
        })
      ).reduce((sum, x, i) => sum + x),
      shippingRateId:
        shipments?.[0]?.shippingFeeInfo?.shippingPartners[eta[0]]?.rateId,
      shippingPartnerType:
        shipments?.[0]?.shippingFeeInfo?.shippingPartners[eta[0]]?.rateType,
      minDay: shipments?.[0]?.shippingFeeInfo?.shippingPartners[eta[0]]?.etaMin,
      maxDay: shipments?.[0]?.shippingFeeInfo?.shippingPartners[eta[0]]?.etaMax,
      city: orderData.city,
      name: orderData.fullName,
      email: orderData.email,
      phone: orderData.contactNumber?.trim(),
      pincode: orderData.zipcode,
      district: orderData.district,
      province: orderData.province,
      subDistrict: orderData.subDistrict,
      addressLine1: orderData.house,
      addressLine2: orderData.street
    }
    createOrder(data, paymentCallback, openSuccessModal, history, location, onTryAgainPayment)
    setOvoMobileModal(false)
    setPaymentGuideModal(false)
  }

  const closeCancelPaymentModal = () => { 
    if (isBackbuttonPressed) {
    window.history.pushState(null, "", window.location.href);
    }
    setCancelPaymentModal(false)
  }
  const onChoosePayment = (method) => setChoosePayment(method)

  const downloadImage = (image, { name = 'bank-details', extension = 'jpg' } = {}) => {
    const a = document.createElement('a')
    a.href = image
    a.target = '_blank'
    a.download = createFileName(extension, name)
    a.click()
    a.remove()
  }

  const downloadScreenshot = (ref, downloadImage) => takeScreenShot(ref).then(downloadImage)

  useEffect(() => {
    getPaymentsList(image)
    EventEmitter.emit(Events.LOAD_PAYMENT)
  }, [])

  useEffect(() => {
    if (!selectedPaymentMethod?.paymentChannel) {
      if (isOpenSuccessModal) {
        history.go(-2)
      }
      window.onpopstate = noop
      return
    }
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function ()
    {
        setCancelPaymentModal(true)
        setIsBackbuttonPressed(true)
    }
    return () => {
      window.removeEventListener('popstate', noop)
    }
  }, [selectedPaymentMethod])
  

  // useEffect(() => {
  //   if (countDown === '00 : 00') {
  //     store.dispatch({ type: CREATE_ORDER_INIT })
  //   }
  // }, [countDown])

  const goBackToPaymentsListPage = () => {
    cancelPayment({ payId: selectedPaymentMethod?.paymentCategory === 'VIRTUAL_ACCOUNT' ? virtual?.id : ewallet?.id, paymentCancelled: noop })
    setChoosePayment({})
    setSelectedPaymentMethod({})
    setCancelPaymentModal(false)
    stopTimer()
    setCountDown(moment().hour(0).minute(2).second(60).format('mm : ss'))

    if (!isBackbuttonPressed) {
      history.go(-1)
    }
    setIsBackbuttonPressed(false)
  }

  const startTimer = (method) => {
    let seconds = method?.gatewayExpiry * 60
    timeRef.current = setInterval(() => {
      if (seconds === 0) {
        stopTimer()
        return
      }
      setCountDown(moment().hours(0).minutes(0).seconds(seconds).subtract(1, 'second').format('mm : ss'))
      seconds -= 1
    }, 1000)
  }

  const stopTimer = () => clearInterval(timeRef.current)
  const selectEwalletPaymentMethod = (method, orderId) => {
    startTimer(method)
    setSelectedPaymentMethod(method)
    eWalletPayment({ orderId, paymentChannel: method?.paymentChannel, mobile: method?.paymentChannel === 'OVO_DSF' ? formattedNumberForOvo() : orderData.contactNumber })
    EventEmitter.emit(Events.PAYMENT_PAGE, {
      mode_of_payment: method?.displayName,
      amount: paymentAmount || 0
    })
  }

  const selectVirtualPaymentMethod = (method, orderId) => {
    startTimer(method)
    setSelectedPaymentMethod(method)
    virtualAccountPayment({ orderId, paymentChannel: method?.paymentChannel })
    EventEmitter.emit(Events.PAYMENT_PAGE, {
      mode_of_payment: method?.displayName,
      amount: paymentAmount || 0
    })
  }
  const onSelectOtherPaymentsMethod = (orderId, resellerId) => {
    paymentInit({ orderId, resellerId }, fetchOthersPayUrl, history, location)
    EventEmitter.emit(Events.PAYMENT_PAGE, {
      mode_of_payment: 'Other',
      amount: paymentAmount || 0
    })
  }

  const onCopyOrderId = () => {
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: commonTranslations.copyToClipboard, type: 'success' } })
    navigator.clipboard.writeText(orderId)
  }

  const onTryAgainPayment = () => {
    store.dispatch({ type: CLEAR_PAYMENT_FAILED_BY_USER })
    store.dispatch({ type: CLEAR_INITIATE_PAYMENTS })
    store.dispatch({ type: EWALLET_PAYMENT_FAIL })
  }

  const copyData = (copyValue) => {
    store.dispatch({ type: NOTIFIER_SHOW, notification: { message: commonTranslations.copyToClipboard, type: 'success' } })
    navigator.clipboard.writeText(copyValue)
  }

  const onClickMethodType = (methodType) => {
    setChoosePayment({})
    if (methodType === openedMethodType) {
      setOpenedMethodType('')
      return
    }
    setOpenedMethodType(methodType)
  }

  const onProceedToPayment = () => {
    if (choosePayment.paymentCategory === 'EWALLET' && choosePayment?.paymentChannel === 'OVO_DSF') {
      setOvoMobileModal(true)
      return
    }
    setChoosePayment({})
    if (openedMethodType === 'OTHERS') {
      setTimeout(() => {
        window.windowReference = window.open()
        window.windowReference.location = '/payment/process/loading'
        }, 800);
      createFinalOrder((orderId, resellerId) => onSelectOtherPaymentsMethod(orderId, resellerId))
      return
    }
    if (choosePayment.paymentCategory === 'VIRTUAL_ACCOUNT') {
      createFinalOrder((orderId) => selectVirtualPaymentMethod(choosePayment, orderId))
      return
    }
    if (choosePayment.paymentCategory === 'EWALLET' && (choosePayment?.paymentChannel === 'DANA_DSF' || choosePayment?.paymentChannel === 'LINKAJA_DSF')) {
      setTimeout(() => {
      window.windowReference = window.open()
      window.windowReference.location = '/payment/process/loading'
      }, 800);
      createFinalOrder((orderId) => selectEwalletPaymentMethod(choosePayment, orderId))
      return
    }
    if (choosePayment.paymentCategory === 'EWALLET' && choosePayment?.paymentChannel === 'SHOPEEPAY_DSF' && isMobilePhone()) {
      setTimeout(() => {
      window.windowReference = window.open()
      window.windowReference.location = '/payment/process/loading'
      }, 800);
      createFinalOrder((orderId) => selectEwalletPaymentMethod(choosePayment, orderId))
      return
    }
    if (choosePayment.paymentCategory === 'EWALLET') {
      createFinalOrder((orderId) => selectEwalletPaymentMethod(choosePayment, orderId))
      return
    }
  }

  const PaymentCard = ({ item, type, index, lowBalance }) => (
    <div onClick={lowBalance ? noop : () => onChoosePayment(item)} className={`py-[10px] px-[1rem] flex items-center justify-between ${(index + 1) < list.filter(item => item?.paymentCategory === type).length && 'border-b'}`}>
      <div className='flex flex-col items-start justify-start'>
        <div className={`flex items-center justify-center ${lowBalance && 'opacity-[0.4]'}`}>
          <img className='w-[30px] h-[30px] rounded-full object-contain bg-white p-1 border-1 border-[#DBDBDB]' src={item?.logo?.[0]?.url} />
          <p className='text-[16px] ml-[13px] capitalize'>{item?.displayName}</p>
        </div>
        {lowBalance && <p className='text-[10px] text-red-600 mt-1'>{commonTranslations.insufficientBalance}</p>}
      </div>

      <CustomRadio disabled={lowBalance} isSelected={choosePayment?.displayName === item?.displayName} />
    </div>
  )

  const goBackToHomePage = () => history.replace(`/${shareId}/${sku}`)

  return (
    <div style={{ minHeight: window.innerHeight }} className='flex items-center justify-center flex-1 w-full flex-col order-details'>
      {isLoading || paymentInitLoading || orderDetailLoading || orderPayload?.isLoading
        ? <Loading />
        : isPaymentFailed
          ? <PaymentFailed handleClose={closeFailModal} onTryAgain={() => {
            onTryAgainPayment()
            setChoosePayment({})
            setSelectedPaymentMethod({})
          }} />
          : isOpenSuccessModal ? 
          <SuccessModal orderId={orderId} open={isOpenSuccessModal} handleClose={goBackToHomePage} />
          : (paymentInProcess && selectedPaymentMethod?.paymentChannel === 'SHOPEEPAY_DSF') ? (
            <div className='w-full flex flex-col flex-1 bg-white'>
              <MobileHeader
                onClick={() => setCancelPaymentModal(true)}
                component={(
                  <div className='flex items-center justify-center flex-1'>
                    <p className='text-[#E22F53] text-[13px]'>{commonTranslations.completePaymentIn}</p>
                    <div className='px-[8px] py-[4px] bg-[#E22F53] flex items-center justify-center ml-2 rounded'>
                      <p className='text-[14px] text-white font-[700] font-sans'>
                        {countDown}
                      </p>
                    </div>
                  </div>)}
              />
              <div className='px-3'>
                <div className='w-full flex flex-col items-center justify-center'>
                  <img src={selectedPaymentMethod?.logo?.[0]?.url} className='w-[80px] h-[80px] mt-4 object-contain bg-white p-1 border-1 border-[#DBDBDB] rounded-full' />
                  <img ref={shopeeScreenRef} className='w-[250px] h-[250px] object-contain mt-[50px]' src={ewallet?.paymentDetails?.qrString} />
                  <div className='mt-[2rem]' />
                  <Button
                    isLoading={false}
                    label={commonTranslations.paymentGuide}
                    btnColor={themeColor}
                    bgColor='white'
                    btnWidth='250px'
                    btnHeight='40px'
                    btnBorder={`1px solid ${themeColor}`}
                    fontSize='16px'
                    borderRadius='6px'
                    onClick={() => { 
                      EventEmitter.emit(Events.PAYMENT_GUIDE, {
                        fitler_by: selectedPaymentMethod?.paymentChannel
                      })
                      setPaymentGuideModal(true)
                    }}
                  />
                  <div className='mt-[10px] w-[250px]'>
                    <Button
                      isLoading={false}
                      btnWidth='100%'
                      btnHeight='40px'
                      fontSize='16px'
                      borderRadius='6px'
                      onClick={() => { 
                        downloadScreenshot(shopeeScreenRef.current,
                        (image) => downloadImage(image, { name: 'qrcode-details', extension: 'png' })
                      )
                      EventEmitter.emit(Events.DOWNLOAD_DETAILS, {
                        fitler_by: selectedPaymentMethod?.paymentChannel
                      })
                      }}
                      bgColor={themeColor}
                      btnColor='white'
                    >
                      <div className='flex items-center justify-center'>
                        <img src={downloadDetailsIcon} />
                        <p className='text-[14px] text-white ml-3'>{commonTranslations.downloadDetails}</p>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : 
          (paymentInProcess && selectedPaymentMethod?.paymentChannel === 'OVO_DSF') ? (
            <div className='w-full flex flex-col flex-1 bg-white'>
              <MobileHeader
                onClick={() => setCancelPaymentModal(true)}
              />
              <div className='flex flex-col items-center justify-center py-[20px] bg-[#F5F6FC]'>
                <p className='text-[16px] font-sans font-[600]'>{commonTranslations.pleaseCompletePaymentIn}</p>
                <div className='ovoTimeBox w-[200px] h-[40px] border-1 border-[#16D156] bg-white rounded-[10px] mt-[15px] flex items-center justify-center'>
                    <p className='text-[18px] font-sans font-[600] text-[#16D156]'>
                      {countDown.split(":")[0]}&nbsp;
                      MIN&nbsp;
                      {countDown.split(":")[1]}&nbsp;
                      SEC
                    </p>
                  </div>
                </div>
                <div className='p-[30px] flex items-center justify-center'>
                <div className='max-w-[300px] w-full'>
                  <OvoSteps text={commonTranslations.openOvoInApp} count={1} image={ovoDetail} imageText={"OVO"} />
                  <OvoSteps text={commonTranslations.selectBellIconOvo} count={2} image={ovoNotification} imageText={"Inbox"} />
                  <OvoSteps text={commonTranslations.confirmYourTransactionOvo} count={3} />
                  <OvoSteps lastStep text={commonTranslations.transactionVerifiedOvo} count={4} />
                  </div>
                  </div>
            </div>
          ) : paymentInProcess
            ? (
              <div className='w-full flex flex-col flex-1 bg-white items-center'>
                <div className='flex flex-col flex-1 items-center pt-[80px]'>
                  <Box>
                    <img src={paymenProgressIcon} alt='' />
                  </Box>

                  <Loading small />
                  <br />
                  <p className='m-0 font-[700] font-sans text-center text-[16px]'>
                    {commonTranslations.pleaseWait}
                  </p>
                  <p className='m-0 font-[700] font-sans text-center text-[16px]'>
                    {commonTranslations.paymentInProcess}
                  </p>
                  <Box className='orderId-wrapper mt-[40px]'>
                    <p className='font-bold font-sans text-[13px]'>
                      {commonTranslations.id}:
                    </p>
                    <Typography noWrap variant='subtitle2' className='flex'>
                      <span className='font-bold font-sans text-[13px]' style={{ margin: '0px 6px' }}>{orderId}</span>
                      <span onClick={onCopyOrderId} className='order-id-copy text-[13px] cursor-pointer'>
                        <img src={copyIcon} alt='' />
                      </span>
                    </Typography>
                  </Box>
                  <div className='mt-[1.5rem]'>
                    <p onClick={() => setCancelPaymentModal(true)} className='text-[13px] underline underline-offset-4 cursor-pointer'>{commonTranslations.goBackToPayment}</p>
                    </div>
                </div>
              </div>
            )
            : selectedPaymentMethod?.paymentCategory === 'VIRTUAL_ACCOUNT'
              ? (
                <div className='w-full flex flex-col flex-1 bg-[#F5F5F5] overflow-y-auto'>
                  <MobileHeader
                    onClick={() => setCancelPaymentModal(true)}
                    component={(
                      <div className='flex items-center justify-center flex-1 min-h-[50px]'>
                        <p className='text-[#E22F53] text-[13px]'>{commonTranslations.completePaymentIn}</p>
                        <div className='px-[8px] py-[4px] bg-[#E22F53] flex items-center justify-center ml-2 rounded'>
                          <p className='text-[14px] text-white font-[700] font-sans'>
                            {countDown}
                          </p>
                        </div>
                      </div>)}
                  />
                  <div className='w-full'>
                    <div className='w-full bg-white bcaBox py-4 rounded-b-2xl'>
                      <div className='p-4' ref={bcaScreenRef}>
                        <img className='w-[80px] h-[50px] object-contain' src={selectedPaymentMethod?.logo?.[0]?.url} />
                        <div className='mt-[1rem]'>
                          <p className='text-[12px]'>{commonTranslations.virtualAccountNumber}</p>
                          <div className='flex items-center'>
                            <p className='text-[20px]'>{virtual?.paymentDetails?.virtualAccountNumber}</p>
                            <img onClick={() => { 
                              copyData(virtual?.paymentDetails?.virtualAccountNumber)
                              EventEmitter.emit(Events.COPY_VA, {
                                fitler_by: selectedPaymentMethod?.paymentChannel
                              })
                             }} className='cursor-pointer ml-6' src={bcaCopyIcon} />
                          </div>
                        </div>
                        <div className='mt-[1rem]'>
                          <p className='text-[12px]'>{commonTranslations.virtualAccountName}</p>
                          <div className='flex items-center'>
                            <p className='text-[20px]'>{virtual?.paymentDetails?.payeeName}</p>
                          </div>
                        </div>
                        <div className='mt-[1rem]'>
                          <p className='text-[12px]'>{commonTranslations.amountToPay}</p>
                          <div className='flex items-center'>
                            <p className='text-[20px]'>IDR {numberWithCommas(virtual?.paymentDetails?.chargeAmount)}</p>
                            <img onClick={() => {
                              copyData(virtual?.paymentDetails?.chargeAmount)
                              EventEmitter.emit(Events.COPY_AMOUNT, {
                                fitler_by: selectedPaymentMethod?.paymentChannel
                              })
                            }} className='cursor-pointer ml-6' src={bcaCopyIcon} />
                          </div>
                        </div>
                      </div>
                      <div className='mt-[1.5rem] px-4'>
                        <Button
                          isLoading={false}
                          btnWidth='100%'
                          btnHeight='40px'
                          fontSize='16px'
                          borderRadius='6px'
                          onClick={() => { 
                            downloadScreenshot(bcaScreenRef.current, downloadImage)
                            EventEmitter.emit(Events.DOWNLOAD_DETAILS, {
                              fitler_by: selectedPaymentMethod?.paymentChannel
                            })
                          }}
                          bgColor={themeColor}
                          btnColor='white'
                        >
                          <div className='flex items-center justify-center'>
                            <img src={downloadDetailsIcon} />
                            <p className='text-[14px] text-white ml-3'>{commonTranslations.downloadDetails}</p>
                          </div>
                        </Button>
                      </div>
                    </div>
                    {
                      selectedPaymentMethod?.instructions?.sections?.length > 0 && (
                        <div className='mt-[30px] bg-white'>
                          <div className='w-full overflow-x-auto flex'>
                            {selectedPaymentMethod?.instructions?.sections?.map((item, i) => (
                              <div key={i} onClick={() => setSelectedInstruction(i)} className={`py-[15px] px-[60px] flex items-center justify-center border-b-2 border-b-[${selectedInstruction === i ? 'black' : 'white'}] cursor-pointer`}>
                                <p className={`text-[16px] font-sans font-bold m-0 text-[${selectedInstruction === i ? 'black' : '#757575'}]`}>{item?.title?.en}</p>
                              </div>))}
                          </div>
                          <div className='w-full px-[18px] flex flex-col flex-1 bg-white pb-[20px]'>
                            {selectedPaymentMethod?.instructions?.sections[selectedInstruction]?.steps?.map((item, i) => (
                              <div key={i} className='w-full py-[12px] px-[10px] rounded-[10px] bg-[#F7F2F4] mt-[20px] flex'>
                                <p className='text-[16px] text-[#181717]'>{i + 1}</p>
                                <p className='text-[16px] text-[#181717] ml-4'>{item?.title?.en}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
              : (
                <div className='w-full flex flex-col flex-1 bg-[#F5F6FC]'>
                  <MobileHeader
                    onClick={goBack}
                    heading={commonTranslations.selectPaymentMethod}
                  />
                  <div className='px-3 flex flex-col flex-1 pt-3'>
                    <div className='w-full bg-white rounded-[10px] paymentMethodListBox overflow-hidden'>
                      {list?.find(item => item?.paymentCategory === 'EWALLET')?.id &&
                        <div className='w-full bg-white rounded-[10px] overflow-hidden'>
                          <PaymentMethodContainer onClick={() => onClickMethodType('EWALLET')} name={'Wallet'} image={WalletBlack} isOpen={openedMethodType === 'EWALLET'} isBottomBorder>
                            <div className='border rounded-[10px] mb-2'>
                              {
                                list?.filter(item => item?.paymentCategory === 'EWALLET')?.map((item, i) => (
                                  <PaymentCard lowBalance={paymentAmount < item?.minAmountLimit || paymentAmount > item?.maxAmountLimit} key={i} index={i} item={item} type={'EWALLET'} />
                                ))}
                            </div>
                          </PaymentMethodContainer>
                        </div>}
                      {list?.find(item => item?.paymentCategory === 'VIRTUAL_ACCOUNT')?.id &&
                        <div className='w-full bg-white rounded-[10px] overflow-hidden'>
                          <PaymentMethodContainer onClick={() => onClickMethodType('VIRTUAL_ACCOUNT')} name={'Bank'} image={BlackBank} isOpen={openedMethodType === 'VIRTUAL_ACCOUNT'} isBottomBorder>
                            <div className='border rounded-[10px] mb-2'>
                              {
                                list?.filter(item => item?.paymentCategory === 'VIRTUAL_ACCOUNT')?.map((item, i) => (
                                  <PaymentCard lowBalance={paymentAmount < item?.minAmountLimit || paymentAmount > item?.maxAmountLimit} key={i} index={i} item={item} type={'VIRTUAL_ACCOUNT'} />
                                ))}
                            </div>
                          </PaymentMethodContainer>
                        </div>}
                      {/* <div className='w-full bg-white rounded-[10px] overflow-hidden'>
                        <PaymentMethodContainer onClick={() => onClickMethodType('CREDIT')} name={commonTranslations.addCreditDebitCard} image={BlackCreditcard} isOpen={openedMethodType === 'CREDIT'} isBottomBorder>
                          <div className='border rounded-[10px] mb-2'>
                            <div className={`py-[10px] px-[1rem] flex items-center justify-between`}>
                              <div className='flex items-center justify-center'>
                                <img className='w-[30px] h-[30px] rounded-full object-contain bg-white p-1 border-1 border-[#DBDBDB]' src={othersImage} />
                                <p className='text-[16px] ml-[13px] capitalize'>Others</p>
                              </div>
                              <div className={`w-[20px] h-[20px] rounded-full border-1 border-[${themeColor}] flex items-center justify-center ml-3`}>
                                <div className={`w-[10px] h-[10px] rounded-full bg-[${themeColor}]`} />
                              </div>
                            </div>
                          </div>
                        </PaymentMethodContainer>
                      </div> */}
                      <div className='w-full bg-white rounded-[10px] overflow-hidden'>
                        <PaymentMethodContainer onClick={() => onClickMethodType('OTHERS')} name={'Others'} image={BlackOthers} isOpen={openedMethodType === 'OTHERS'} isBottomBorder>
                          <div className='border rounded-[10px] mb-2'>
                            <div className={`py-[10px] px-[1rem] flex items-center justify-between`}>
                              <div className='flex items-center justify-center'>
                                <img className='w-[30px] h-[30px] rounded-full object-contain bg-white p-1 border-1 border-[#DBDBDB]' src={othersImage} />
                                <p className='text-[16px] ml-[13px] capitalize'>Others</p>
                              </div>
                              <CustomRadio isSelected={openedMethodType === 'OTHERS'} />
                            </div>
                          </div>
                        </PaymentMethodContainer>
                      </div>
                    </div>
                    <div className='flex flex-col flex-1 bg-red mt-4 items-center justify-end mb-[30px]'>
                      <div className='flex items-center justify-center'>
                        <p className='text-[#000000] text-[12px] opacity-[0.5]'>Trusted by</p>
                        <img className='ml-2' src={durianpaylogo} />
                      </div>
                      <p className='text-[#000000] text-[12px] mt-2 opacity-[0.5]'>Credit card payment are PCI/DSS Compliant</p>
                    </div>
                  </div>
                  <PaymentsStickyBottombar
                    label={commonTranslations.confirm}
                    disabled={openedMethodType === 'OTHERS' ? false : !choosePayment.displayName}
                    paymentAmount={paymentAmount}
                    onClick={onProceedToPayment}
                  />
                </div>
              )}
      <Modal open={cancelPaymentModal}>
        <div className='cancelPaymentModalbox order-details'>
          <div className='w-[95%] max-w-[400px] bg-white rounded p-4'>
            <p className='text-[18px] font-sans font-bold text-black'>{commonTranslations.cancelPayment}</p>
            <div className='w-full flex items-center justify-between mt-4 gap-[2rem]'>
              <Button
                isLoading={false}
                btnWidth='40%'
                btnHeight='40px'
                borderRadius='6px'
                onClick={goBackToPaymentsListPage}
                bgColor={'white'}
                btnBorder={`1px solid ${themeColor}`}
                btnColor='white'
              >
                <p className={`text-[14px] text-[${themeColor}]`}>{commonTranslations.yes}</p>
              </Button>
              <Button
                isLoading={false}
                btnWidth='40%'
                btnHeight='40px'
                borderRadius='6px'
                bgColor={themeColor}
                btnColor='white'
                onClick={closeCancelPaymentModal}
              >
                <p className='text-[14px] text-white'>{commonTranslations.no}</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={ovoMobileModal}>
        <div className='ovoMobileModalbox order-details'>
          <div className='w-[100%] bg-white rounded'>
            <div className='p-[15px] flex items-center justify-between border-b'>
              <div className='flex items-center'>
                <img className='w-[40px] h-[40px] rounded-full object-contain bg-white p-1 border-1 border-[#DBDBDB]' src={choosePayment?.logo?.[0]?.url} />
                <p className='text-[18px] text-black ml-[15px]'>{commonTranslations.payWith}</p>
              </div>
              <img className='cursor-pointer' onClick={() => setOvoMobileModal(false)} src={grayCross} />
            </div>
            <div className='w-full flex items-center justify-between p-[1rem]'>
              <div className='w-full'>
                <p className='text-[14px]'>{commonTranslations.addPhoneNumber}</p>
                <div className='flex mt-[6px] border border-[#D0D0D0] rounded h-[44px] w-full px-[14px] items-center justify-center'>
                <p>+62</p>
                <input 
                className='flex flex-1 ml-2 border-l pl-1'
                value={ovoMobileNumber} 
                onChange={e => { 
                  setOvoMobileNumber(e.target.value)
                  setIsOvoMobileValid(true)
                }}  />
                </div>
                <Grid style={{ color: '#ff000099' }} item xs={12}>
                    {!isOvoMobileValid && errorTranslations.invalidPhone}
                </Grid>
                <div className='mt-[20px]' />
                <Button
                  isLoading={false}
                  btnHeight='40px'
                  borderRadius='6px'
                  bgColor={themeColor}
                  btnColor='white'
                  onClick={() => { 
                    if (!validator.isMobilePhone(isValidIndonesianNumber(ovoMobileNumber), 'id-ID') || ovoMobileNumber.startsWith('+62' || '62') || (ovoMobileNumber.startsWith('0') ? ovoMobileNumber.length > 13 : ovoMobileNumber.length > 12)) {
                     setIsOvoMobileValid(false)
                      return
                    }
                    createFinalOrder((orderId) => selectEwalletPaymentMethod(choosePayment, orderId))
                    setIsOvoMobileValid(true)
                  }}
                >
                  <p className='text-[14px] text-white'>{commonTranslations.continue}</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={paymentGuideModal}>
        <div className='ovoMobileModalbox order-details'>
          <div className='w-[100%] bg-white rounded p-[1rem]'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <p className='text-[18px] text-black capitalize font-sans font-bold'>{commonTranslations.paymentGuide}</p>
              </div>
              <img className='cursor-pointer' onClick={() => { 
                EventEmitter.emit(Events.CLOSE_PAYMENT_GUIDE, {
                  fitler_by: selectedPaymentMethod?.paymentChannel
                })
                setPaymentGuideModal(false)
              }} src={grayCross} />
            </div>
            <div className='w-full flex flex-col p-[10px]'>
               {shopeeInstructions.map((item,i) => (
                <div key={i} className='flex mt-3'>
                  <p className='text-[14px]'>{i + 1}.</p> 
                  <div className='ml-4'>
                    <p className='text-[14px]'>{item}</p>
                    {
                      i === 1 && <img src={ShopeepayQRInstructions} />
                    }
                  </div>
                  </div>
               ))}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const PaymentMethodContainer = ({ image, name, isOpen, onClick, extendedComponent, isBottomBorder, ...props }) => (
  <div className='w-full'>
    <div onClick={onClick} className={`w-full cursor-pointer bg-white px-[12px] py-[15px] flex items-center justify-between ${isBottomBorder && !isOpen && 'border-b border-b-[#D0D0D080]'}`}>
      <div className='flex items-center justify-between'>
        <img src={image} />
        <p className='text-[16px] ml-[13px]'>{name}</p>
      </div>
      <img src={isOpen ? BlackArrowUp : BlackArrowDown} />
    </div>
    {isOpen && <div className='w-full px-[12px]'>
      {props.children}
    </div>
    }
  </div>
)

const CustomRadio = ({ isSelected, disabled }) => (
  <div className={`w-[20px] h-[20px] rounded-full border-1 border-[${isSelected ? themeColor : '#6D6E7782'}] flex items-center justify-center ml-3 ${disabled && 'opacity-[0.4]'}`}>
    <div className={`w-[10px] h-[10px] rounded-full bg-[${isSelected ? themeColor : '#F8FAFB'}]`} />
  </div>
)

const GreenCount = ({ count }) => (
  <div className='w-[26px] h-[26px] rounded-full bg-[#CDFFCC] flex items-center justify-center'>
    <div className='w-[20px] h-[20px] rounded-full bg-[#16D156] flex items-center justify-center'>
      <p className='text-[12px] text-white'>{count}</p>
    </div>
  </div>
)

const OvoSteps = ({ count, text, image, imageText, lastStep }) => (
  <div className='flex'>
    <div className='flex flex-col items-center justify-center'>
      <GreenCount count={count} />
      <div className={`w-[1px] bg-[${lastStep ? 'white' : '#16D156'}] h-full`} />
    </div>
    <div className='ml-[1rem] pb-[16px]'>
      <p className='text-[14px] font-sans font-[500]'>{text}</p>
      {
        image && (
          <div className='flex flex-col items-center justify-center w-[50px]'>
            <img className='mt-[10px  ml-[-20px]' src={image} />
            <p className='text-[12px]  ml-[-20px] text-[#00000080]'>{imageText}</p>
          </div>
        )
      }
    </div>
  </div>
)