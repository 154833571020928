/* eslint-disable no-param-reassign */
import { updateIntl } from 'react-intl-redux'

import { store } from '../store'
import { getLocaleMessages } from './index'
import * as ACTIONS from './actions'

export default function fetchTranslation (locale) {
  if (!locale) {
    locale = window.localStorage.getItem('locale')
    if (!locale) {
      locale = 'id'
    }
  }
  window.localStorage.setItem('locale', locale)

  store.dispatch({ type: ACTIONS.TRANSLATION_FETCH })

  try {
    store.dispatch(
      updateIntl({
        locale,
        messages: getLocaleMessages(locale)
      })
    )

    store.dispatch({ type: ACTIONS.TRANSLATION_FETCH_SUCCESS, locale })
  } catch (err) {
    store.dispatch({
      type: ACTIONS.TRANSLATION_FETCH_FAIL,
      error: err.message
    })
  }
}
