import {
  ADD_ORDER_FAIL,
  ADD_ORDER_INIT,
  ADD_ORDER_SUCCESS,
  FETCH_PROVINCES_FAIL,
  FETCH_PROVINCES_INIT,
  FETCH_PROVINCES_SUCCESS,
  GET_LOCATION_DATA_FAIL,
  GET_LOCATION_DATA_INIT,
  GET_LOCATION_DATA_SUCCESS
} from '../../../actions'
import apiCall from '../../../saga/api'

export function getProvinces (options) {
  const url = '/dsf/orders/shipping-provinces?limit=50'
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: FETCH_PROVINCES_INIT,
      successType: FETCH_PROVINCES_SUCCESS,
      failureType: FETCH_PROVINCES_FAIL
    }
  }
  // Accept response if necessary with await
  apiCall(apiArgs)
}

export async function getLocationData (options) {
  const queryString = Object.keys(options)
    .map((key) => key + '=' + options[key].split(' ').join('+'))
    .join('&')
  // const url = '/dsf/orders/shipping-provinces'
  const url = '/dsf/orders/postal-data?limit=999&' + queryString
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: GET_LOCATION_DATA_INIT,
      successType: GET_LOCATION_DATA_SUCCESS,
      failureType: GET_LOCATION_DATA_FAIL
    }
  }
  // Accept response if necessary with await
  const data = await apiCall(apiArgs)

  return data
}

export async function addOrder (data) {
  const url = `/dsf/orders/postal-data-details?city=${data.city}&province=${data.province}&district=${data.district}&subDistrict=${data.subDistrict}&zipcode=${data.pincode}`
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    TYPES: {
      requestType: ADD_ORDER_INIT,
      successType: ADD_ORDER_SUCCESS,
      failureType: ADD_ORDER_FAIL
    }
  }

  const response = await apiCall(apiArgs)

  return response
}
